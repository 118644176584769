import { ManageQuestionsEditAnswersAddPage } from './../manage-questions-edit-answers-add/manage-questions-edit-answers-add.page';
import { OrderableAnswer } from '../../models/orderables';
import { SelectOption } from '../../models/_core/select-option';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BhAnalyticsService } from '../../services/_core/bhanalytics/bhanalytics.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';

@Component({
  selector: 'app-manage-questions-edit-answers',
  templateUrl: './manage-questions-edit-answers.page.html',
  styleUrls: ['./manage-questions-edit-answers.page.scss'],
})
export class ManageQuestionsEditAnswersPage implements OnInit {
  @Input() selectOptions: SelectOption[] = [];
  initSelectOptions: SelectOption[] = [];
  unsavedReorder = false;
  orderedItems: number[] = [];
  listDirty = false;
  breadcrumbs: Breadcrumb[] = [
    { label: 'Manage', url: '/tabs/manage', direction: 'back' },
    { label: 'Manage Employees', url: null, direction: null },
  ];

  constructor(
    public formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public alertCtrl: AlertController,
    public analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.initSelectOptions = this.selectOptions;
    this.orderedItems = [];
    console.log('Received answers: ', this.selectOptions);
    if (this.selectOptions) {
      this.selectOptions.forEach((option: SelectOption) => {
        this.orderedItems.push(option.qoSeq);
      });
    }
  }

  async addAnswer() {
    const modal = await this.modalCtrl.create({
      component: ManageQuestionsEditAnswersAddPage,
      cssClass: 'ad-modal',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((newOptions: any) => {
      this.selectOptions.push(newOptions.data);
    });

    return await modal.present();

  }

  doReorder(ev: any) {
    // console.log('$event from reorder', ev);
    const itemMove = this.orderedItems.splice(ev.detail.from, 1)[0];
    this.orderedItems.splice(ev.detail.to, 0, itemMove);
    ev.detail.complete(true);
    this.orderedItems = ev.detail.complete(this.orderedItems);
    this.unsavedReorder = true;
    this.saveOrder();
    // console.log('orderedItems from doReorder', this.orderedItems);
  }

  saveOrder() {
    // console.log('orderedItems', this.orderedItems);
    // console.log('Before saving: ', this.selectOptions);
    const orderedCollection: OrderableAnswer[] = [];
    for (let i = 0; i < this.orderedItems.length; i++) {
      const orderableAnswer: OrderableAnswer = {};
      orderableAnswer.order = i + 1;
      orderableAnswer.qoSeq = this.orderedItems[i];
      orderedCollection.push(orderableAnswer);
    }
    // console.log('orderedCollection', orderedCollection);
    // Update order property
    orderedCollection.forEach((ord: OrderableAnswer) => {
      this.selectOptions.forEach((opt: SelectOption) => {
        if (opt.qoSeq === ord.qoSeq) {
          opt.order = ord.order;
        }
      });
    });
    // console.log('After saving: ', this.selectOptions);
    this.unsavedReorder = false;
    this.listDirty = true;
  }

  resetOrder() {
    this.selectOptions = [];
    setTimeout(() => {
      this.selectOptions = this.initSelectOptions;
    }, 250);
    this.unsavedReorder = false;
  }

  async delete(idx) {
    const alert = await this.alertCtrl.create({
      header: 'Remove this answer?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Delete',
          cssClass: 'font-color-danger',
          handler: () => {
            // this.selectOptions.splice(idx, 1);
            idx.active = false;
            this.listDirty = true;
          }
        }
      ]
    });

    await alert.present();
  }

  dismiss(save) {
    if (this.listDirty && !save) {
      if (save) {
        // User is saving
        this.saveOrder();
        this.modalCtrl.dismiss(this.selectOptions);
      } else {
        // User is trying to leave without saving
        this.promptLeavingWithoutSaving();
      }
    } else {
      // User is leaving without saving
      this.selectOptions = this.initSelectOptions;
      this.modalCtrl.dismiss(this.selectOptions);
    }
  }

  async promptLeavingWithoutSaving() {
    const alert = await this.alertCtrl.create({
      message: 'You are about to leave without saving?',
      buttons: [
        {
          text: 'Stay',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Leave without saving',
          cssClass: 'font-color-danger',
          handler: () => {
            this.selectOptions = this.initSelectOptions;
            this.modalCtrl.dismiss(this.selectOptions);
          }
        }
      ]
    });

    await alert.present();

  }

}

import { Employee } from 'src/app/models/employee';
import { AppParamsService } from './../app-params/app-params.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { PpeServiceResponse } from 'src/app/models/ppe-service-response';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  env = environment;
  selectedEmployee: BehaviorSubject<Employee> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
  ) { }

  setSelectedEmployee(emp: Employee) {
    this.selectedEmployee.next(emp);
  }

  getSelectedEmployee(): Employee {
    return this.selectedEmployee.getValue();
  }

  searchByLastName(lastName): Observable<any> {
    const url = `${this.env.apiUrl}/employees?lastName=${lastName}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Employees/searchByLastName');
          }
          return res;
        })
      );
  }

  searchById(id): Observable<any> {
    const url = `${this.env.apiUrl}/employees/${id}?activeOnly=0`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Employees/searchById');
          }
          return res;
        })
      );
  }


  getMyEmployees(managerUserId): Observable<any> {
    const url = `${this.env.apiUrl}/results?impersonationUserId=${managerUserId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching getMyEmployees');
          }
          return res;
        })
      );
  }

  recordPpeDistribution(id, comment): Observable<any> {
    const body = {
      comment
    };
    const url = `${this.env.apiUrl}/employees/${id}/ppe`;
    return this.http.post(url, body)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Employees/recordPpeDistribution');
          }
          return res;
        })
      );
  }

  getPpeHistory(id): Observable<PpeServiceResponse> {
    const url = `${this.env.apiUrl}/employees/${id}/ppe`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Employees/getPpeHistory');
          }
          return res;
        })
      );
  }

}

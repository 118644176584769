import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { Profile } from './../../models/profile';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ModalController, NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { AuthService } from 'src/app/services/_core/auth/auth.service';

@Component({
  selector: 'app-welcome-whats-new',
  templateUrl: './welcome-whats-new.page.html',
  styleUrls: ['./welcome-whats-new.page.scss'],
})
export class WelcomeWhatsNewPage implements OnInit {
  env = environment;
  profile: Profile;
  profileSub: Subscription = null;
  authUser: User;
  breadcrumbs: Breadcrumb [] = [
    {label: 'What\'s new?', url: null, direction: null},
  ];

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private authService: AuthService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.subscribeToProfile();
  }

  ionViewWillLeave() {
    if (this.profileSub) {
      this.profileSub.unsubscribe();
      this.profileSub = null;
    }
  }

  subscribeToProfile() {
    if (!this.profileSub) {
      this.profileSub = this.profileService.profileSubject.subscribe((data) => {
        if (data && data.checklist) {
          this.profile = data;
          this.authUser = this.authService.getAuthUser();
        }
      });
    }
  }

  dismiss() {
    this.modalCtrl.dismiss().then(() => {
      // do nothing
    }).catch((e) => {
      // Not modal, dismiss using navCtrl
      this.navCtrl.back();
    });
  }

}

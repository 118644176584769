import { BhAdPopupComponentModule } from './../../components/bh-ad-popup/bh-ad-popup.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CallbackPhoneAdPage } from './callback-phone-ad.page';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhValidationErrorComponentModule } from 'src/app/components/_core/bh-validation-error/bh-validation-error.component.module';
import { CallbackPhoneAdPageRoutingModule } from './callback-phone-ad-routing.module';

@NgModule({
  imports: [
    BhAdPopupComponentModule,
    BhInputComponentModule,
    BhValidationErrorComponentModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    CallbackPhoneAdPageRoutingModule
  ],
  declarations: [CallbackPhoneAdPage]
})
export class CallbackPhoneAdPageModule {}

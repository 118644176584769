import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Message } from 'src/app/models/message';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'app-manage-help-docs',
  templateUrl: './manage-help-docs.page.html',
  styleUrls: ['./manage-help-docs.page.scss'],
})
export class ManageHelpDocsPage implements OnInit {
  env = environment;
  msgId = 'WWADMINGUIDES';
  content: Message = {};

  constructor(
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private notifications: NotificationsService,
    private messagingService: MessagingService,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loadMessaging();
  }

  async loadMessaging() {
    try {
      const msgRes = await this.messagingService.getByIdAndLanguage(this.env.covid19Vaccine.applicationId, this.msgId).toPromise();
      // console.log('Message received', msgRes);
      this.content = msgRes.msg;
      this.analytics.screenView('AdminHelpGuides: ' + this.content.id);
      // this.message.active = 1;
    } catch (err) {
      this.notifications.handleError(err, 'loadMessaging', null, 'An error occurred while loading your message.');
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}

import { Employee } from 'src/app/models/employee';
import { GuestsService } from 'src/app/services/guests/guests.service';
import { environment } from 'src/environments/environment';
import { MessagingService } from '../../services/messaging/messaging.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { SchConsent } from 'src/app/models/sch-consent';
import * as moment from 'moment';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { SelectOption } from 'src/app/models/_core/select-option';

@Component({
  selector: 'app-manage-people-appts-consent',
  templateUrl: './manage-people-appts-consent.page.html',
  styleUrls: ['./manage-people-appts-consent.page.scss'],
})
export class ManagePeopleApptsConsentPage implements OnInit {
  @Input() visit;
  @Input() schApplicationId;
  @Input() vaccine;
  @Input() userId;
  @Input() ecSeq = null;
  @Input() employee: Employee;
  @Input() isGuest = false;
  env = environment;
  currentView: 'loading' | 'minor' | 'consent' | 'error' | 'no-consent' = 'loading';
  consentText = '';
  consentTimestamp = '';
  guardianConsentText = null;
  guardianConsentTimestamp = null;
  hasAgreed = false;
  consents: SchConsent[] = [];
  guardianConsents: SchConsent[] = [];
  minorOptions: SelectOption[] = [];
  selectedMinorOption = null;
  matureMinorDefinition = '';
  buttonLabel = 'Person agrees';

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private messagingService: MessagingService,
    private schedulerService: SchedulerService,
    private notificationsService: NotificationsService,
    private guestsService: GuestsService
  ) { }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    // console.log('vaccineConsentPage: ', this.visit, this.schApplicationId, this.vaccine);
    if (this.visit && this.schApplicationId && this.vaccine) {
      this.vaccine = this.vaccine;
      if (this.visit.ecSeq && this.visit.ecSeq > 1) {
        await this.loadConsents();
        // console.log('Consents', this.consents);
        if (this.consents && this.consents.length > 0) {
          this.consentText = this.consents[0].detail;
          this.consentTimestamp = this.consents[0].ecTimeStamp;

          if (this.guardianConsents && this.guardianConsents.length > 0) {
            this.guardianConsentText = this.guardianConsents[0].detail;
            this.guardianConsentTimestamp = this.guardianConsents[0].ecTimeStamp;
          }

          this.hasAgreed = true;
          this.currentView = 'consent';
        } else {
          console.log('Consent data missing');
          this.currentView = 'no-consent';
        }
      } else {
        this.consentText = await this.getVaccineCosent();
        if (this.consentText) {
          // Check if registrant user has age less than 18
          if (this.employee.age && this.employee.age < 18) {
            this.buttonLabel = 'Continue';
            this.matureMinorDefinition = await this.getMatureMinorDefinition();
            this.minorOptions = this.schedulerService.getMinorRegistrationRoleOptions(true);
            this.currentView = 'minor';
          } else {
            this.currentView = 'consent';
          }
        } else {
          console.log('Consent text missing');
          this.currentView = 'no-consent';
        }
      }
    } else {
      console.log('Appointment data missing');
      this.currentView = 'error';
    }
  }

  async loadConsents(): Promise<boolean> {
    try {
      const userId = this.userId.toLowerCase();
      const consentRes = await this.schedulerService.getConsentsByAppTypeAndUserId(
        this.schApplicationId,
        userId
      ).toPromise();
      const consents: SchConsent[] = consentRes.consents;
      // Determine if COVID-19 vaccination is eligible for this employee
      this.consents = consents.filter(c => c.consentType === 'VACCONSENT' && c.active === 1);
      this.guardianConsents = consents.filter(c => c.consentType === 'GUARDCONSENT' && c.active === 1);
      return Promise.resolve(true);
    } catch (err) {
      console.error('Error while loading consents', err);
      this.currentView = 'error';
    }
  }

  async getVaccineCosent(): Promise<string> {
    const messageId = (this.isGuest) ? 'VACCONSENTEXT' : 'VACCONSENTEMP';
    const appRes = await this.messagingService.getByIdAndSubIdAndLanguage(
      this.schApplicationId,
      messageId,
      this.vaccine.vacSeq
    ).toPromise();
    // console.log('appRes for getMessaging()', appRes);
    const msgText = (appRes.msg) ? appRes.msg.text : ((appRes.msgs && appRes.msgs.length > 0) ? appRes.msgs[0].text : null);
    return Promise.resolve(msgText);
  }

  async getGuardianConsent(): Promise<string> {
    const messageId = 'GUARDCONSENTEXT';
    const appRes = await this.messagingService.getByIdAndLanguage(
      this.schApplicationId,
      messageId
    ).toPromise();
    // console.log('appRes for getMessaging()', appRes);
    let msgText = (appRes.msg) ? appRes.msg.text : null;
    if (msgText.indexOf('[minor_name]') > -1) {
      msgText = msgText.replace('[minor_name]', '<strong>' + this.employee.firstname + ' ' + this.employee.lastname + '</strong>');
    }
    return Promise.resolve(msgText);
  }

  async getMatureMinorDefinition(): Promise<string> {
    const messageId = 'MATUREMINORDEF';
    const appRes = await this.messagingService.getByIdAndLanguage(
      this.schApplicationId,
      messageId
    ).toPromise();
    // console.log('appRes for getMessaging()', appRes);
    const msgText = (appRes.msg) ? appRes.msg.text : null;
    return Promise.resolve(msgText);
  }

  async agree() {
    if (this.currentView === 'minor' && this.selectedMinorOption) {
      if (this.selectedMinorOption === 'GUARDIAN') {
        this.currentView = 'loading';
        this.guardianConsentText = await this.getGuardianConsent();
      }
      this.currentView = 'consent';
      this.buttonLabel = 'Person agrees';
    } else if (this.currentView === 'consent' || this.currentView === 'no-consent') {
      this.currentView = 'loading';
      this.hasAgreed = true;
      this.saveConsent();
    }
  }

  async saveConsent() {
    // Capture Guardian Consent
    if (this.selectedMinorOption === 'MATUREMINOR') {
      const guardConsent: SchConsent = {
        application: this.schApplicationId,
        consentType: 'GUARDCONSENT',
        userId: this.userId,
        response: 'Individual identified themselves as an Emancipated Minor: - ' + moment().format('M/D/YYYY HH:mm:ss'),
        detail: 'Emancipated Minor Consent agreed: ' + this.matureMinorDefinition,
        active: 1
      };
      try {
        const savedConsent = await this.schedulerService.saveConsent(guardConsent).toPromise();
        if (savedConsent.x_status !== 'S') {
          this.currentView = 'error';
        }
      } catch (err) {
        this.currentView = 'error';
      }
    } else if (this.selectedMinorOption === 'GUARDIAN') {
      const minorConsent: SchConsent = {
        application: this.schApplicationId,
        consentType: 'GUARDCONSENT',
        userId: this.userId,
        response: 'Guardian agreed to Consent: ' + moment().format('M/D/YYYY HH:mm:ss'),
        detail: 'Guardian Consent agreed: ' + this.guardianConsentText,
        active: 1
      };
      try {
        const savedConsent = await this.schedulerService.saveConsent(minorConsent).toPromise();
        if (savedConsent.x_status !== 'S') {
          this.currentView = 'error';
        }
      } catch (err) {
        this.currentView = 'error';
      }
    }

    // Capture Vaccine Consent
    const vaccConsent: SchConsent = {
      application: this.schApplicationId,
      consentType: 'VACCONSENT',
      userId: this.userId,
      response: 'Agreed to ' + this.schApplicationId + ' Consent: ' + moment().format('M/D/YYYY HH:mm:ss'),
      detail: 'Consent agreed: ' + this.vaccine.consentText + ' ' + this.guardianConsentText,
      active: 1,
    };
    try {
      const savedConsent = await this.schedulerService.saveConsent(vaccConsent).toPromise();
      if (savedConsent.x_status === 'S') {
        vaccConsent.ecSeq = savedConsent.ecSeq;
        this.visit.ecSeq = vaccConsent.ecSeq;
        this.visit.vaccineConsent = vaccConsent;
        // this.schedulerService.setAppointment(this.schedulerService.appointment);
        this.analytics.clickEvent('AdminConsents: ', 'Agreed - ' + this.schApplicationId);
        this.modalCtrl.dismiss(this.visit);
      } else {
        this.notificationsService.handleError('There was an unexpected problem with saving your consent', 'Saving vaccine consent');
      }
    } catch (err) {
      this.notificationsService.handleError(err, 'Saving vaccine consent');
    }
  }

  continueWithoutConsent() {
    console.log('No consent, continue');
    this.analytics.clickEvent('AdminConsents: NoConsentContinue', '');
    this.hasAgreed = true;
    this.agree();
  }

  dismiss(visit = null) {
    this.analytics.clickEvent('AdminConsents: Dismissed', '');
    this.modalCtrl.dismiss(visit);
  }


}

import { BhInputType } from './../../models/_core/input-type';
import { Submission } from './../../models/submission';
import { Question } from './../../models/question';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  env = environment;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
  ) { }

  getAll(formId: string): Observable<any> {
    const url = `${this.env.apiUrl}/questions?formId=${formId}`;
    return this.http.get(url)
      .pipe(
        map((res: any) => {
          if (res.x_result && Array.isArray(res.x_result)) {
            for (const q of res.x_result) {
              q.inputType = this.translateInputType(q.type);
            }
          }
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getAll');
          }
          return res;
        })
      );
  }

  getById(id): Observable<any> {
    const url = `${this.env.apiUrl}/questions/${id}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getById');
          }
          return res;
        })
      );
  }

  getFormInfo(formId): Observable<any> {
    const url = `${this.env.apiUrl}/forms/${formId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getFormInfo');
          }
          return res;
        })
      );
  }

  getHistory(formId, userId, latestEntry = false): Observable<any> {
    const latestParam = latestEntry ? '&latest=1' : '';
    const url = `${this.env.apiUrl}/forms/${formId}/history?userId=${userId}${latestParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getHistory');
          }
          return res;
        })
      );
  }

  getHistoryBySubSeq(formId, subSeq): Observable<any> {
    const url = `${this.env.apiUrl}/forms/${formId}/history?subSeq=${subSeq}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getHistory by SubSeq');
          }
          return res;
        })
      );
  }

  // Note: When adding questions/options, order numbers must be set to 0
  create(question: Question) {
    const url = `${this.env.apiUrl}/questions`;
    return this.http.post(url, question)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Questions/create');
          }
          return res.x_status;
        })
      );
  }

  update(question: Question) {
    const url = `${this.env.apiUrl}/questions/${question.qsSeq}`;
    return this.http.put(url, question)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Questions/update');
          }
          return res.x_status;
        })
      );
  }

  saveAnswers(formId, formData): Observable<Submission> {
    const url = `${this.env.apiUrl}/forms/${formId}/submit`;
    return this.http.post(url, formData)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Questions/saveAnswers');
          }
          return res.x_status;
        })
      );
  }

  // qIdOrderPair = an array of question Sequence and order pair
  updateOrderValues(formId, qIdOrderPair) {
    const url = `${this.env.apiUrl}/forms/${formId}`;
    return this.http.put(url, qIdOrderPair)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Questions/update');
          }
          return res.x_status;
        })
      );
  }

  translateInputType(type: string): BhInputType {
    switch (type.toLowerCase()) {
      case 'text':
        return 'ion-text';

      case 'select':
      case 'select-native':
        return 'ion-select';

      case 'radio':
        return 'ion-radio';

      case 'textarea':
        return 'ion-textarea';

      case 'check':
        return 'ion-checkbox';

      case 'date':
      case 'datepos':
        return 'date';

      default:
        return 'ion-text';
    }
  }
}

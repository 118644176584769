import { BhMessageBannerComponentModule } from 'src/app/components/_core/bh-message-banner/bh-message-banner.component.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhValidationErrorComponentModule } from 'src/app/components/_core/bh-validation-error/bh-validation-error.component.module';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManagePeopleApptsEditPage } from './manage-people-appts-edit.page';
import { ManagePeopleApptsEditPageRoutingModule } from './manage-people-appts-edit-routing.module';

@NgModule({
  imports: [
    BhSpinnerComponentModule,
    BhMessageBannerComponentModule,
    BhInputComponentModule,
    BhValidationErrorComponentModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ManagePeopleApptsEditPageRoutingModule
  ],
  declarations: [ManagePeopleApptsEditPage]
})
export class ManagePeopleApptsEditPageModule {}

import { ScreeningForm } from '../../models/screening';
import { SchApplication } from '../../models/sch-application';
import { ModalController, AlertController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Question } from 'src/app/models/question';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { environment } from 'src/environments/environment';
import { QuestionsService } from 'src/app/services/questions/questions.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { first } from 'rxjs/operators';
import { Submission } from 'src/app/models/submission';
import { SchConsent } from 'src/app/models/sch-consent';
import * as moment from 'moment';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';

@Component({
  selector: 'app-manage-people-appts-screening',
  templateUrl: './manage-people-appts-screening.page.html',
  styleUrls: ['./manage-people-appts-screening.page.scss'],
})
export class ManagePeopleApptsScreeningPage implements OnInit {
  @Input() appointment: SchAppointment;
  @Input() schApplication: SchApplication;
  @Input() userId;
  @Input() subSeq;
  env = environment;
  currentView: 'loading' | 'form' | 'eligible' | 'ineligible' | 'no-screening' | 'error' = 'loading';
  // schApplicationId: string;
  // schApplication: SchApplication;
  submitAttempted = false;
  form1: FormGroup = this.formBuilder.group({});
  response: any = {};
  questions: Question[] = [];
  validationMessages = {};
  submissionType = 'user';
  isLoaded = false;
  isGuest = false;
  urlGuestPrefix = '';
  heading = '';
  instructions = '';
  status = null;
  ineligibleConsents = [];
  isIneligible = false;

  constructor(
    public formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public questionsService: QuestionsService,
    public analytics: BhAnalyticsService,
    public notificationsService: NotificationsService,
    private schedulerService: SchedulerService,

  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    if (this.appointment) {
      // console.log('Received appt for screening', this.appointment);
      if (this.appointment.screening && this.subSeq !== null && this.subSeq > 0) {
        if (this.appointment.screening.alertComment === 'R') {
          this.showIneligibleView();
        } else {
          this.currentView = 'eligible';
        }
      } else {
        this.loadData();
      }
    } else {
      console.error('Missing appointment data');
      this.currentView = 'error';
    }
  }

  retakeScreening() {
    this.currentView = 'loading';
    this.loadData();
  }

  async loadData() {
    // Load questions
    try {
      await this.loadConsents();
      const formId = this.schApplication.screeningFormId;
      // console.log('schApplication', this.appointment.schApplication);
      const screenRes = await this.questionsService.getAll(formId).toPromise();
      this.response = screenRes;
      this.response.application = this.env.covid19Testing.symptomatic.applicationId;
      this.response.testAsProd = this.env.production ? 1 : 0;
      this.questions = screenRes.x_result.filter(q => q.active);
      if (this.questions.length > 0) {
        this.buildForm();
        this.currentView = 'form';
      } else {
        this.currentView = 'no-screening';
      }
    } catch (err) {
      this.currentView = 'error';
    }
  }

  async loadConsents(): Promise<boolean> {
    try {
      const consentRes = await this.schedulerService.getConsentsByAppTypeAndUserId(
        this.schApplication.application,
        this.userId
      ).toPromise();
      const consents = consentRes.consents;
      // Determine if COVID-19 vaccination is eligible for this employee
      this.ineligibleConsents = consents.filter(c => c.consentType === 'VACINELIGIBLE' && c.active === 1);
      this.isIneligible = (this.ineligibleConsents.length > 0);
      return Promise.resolve(true);
    } catch (err) {
      this.notificationsService.handleError(err, 'Loading consents');
    }

  }

  clearIneligibleConsents(): Promise<boolean> {
    this.ineligibleConsents.forEach(async (c: SchConsent) => {
      c.active = 0;
      await this.schedulerService.saveConsent(c).toPromise();
    });
    this.isIneligible = false;
    return Promise.resolve(true);
  }


  buildForm() {
    this.questions.forEach((question: Question) => {
      const validators: ValidatorFn[] = [];
      let abstractControlOptions: AbstractControlOptions;
      if (question.required) {
        validators.push(Validators.required);
        abstractControlOptions = { validators };
        this.validationMessages['control' + question.qsSeq] = [{ type: 'required', message: 'This is required.' }];
      }
      this.form1.addControl('control' + question.qsSeq, new FormControl(question.value, abstractControlOptions));
      this.form1.get('control' + question.qsSeq).markAsPristine();
    });
  }

  async submit() {
    this.submitAttempted = true;
    if (this.form1.valid) {
      let screeningReady = true;
      let failureAnswers = '';
      this.questions.forEach((question: Question, idx) => {
        const value = this.form1.get('control' + question.qsSeq).value;
        this.questions[idx].value = value;
        if (value === 'Y') {
          failureAnswers += '<br> - ' + this.questions[idx].label;
          screeningReady = false;
        }
      });

      if (!screeningReady) {
        const alert = await this.alertCtrl.create({
          header: 'Please confirm',
          message: '<span class="font-color-dark"><strong>Person has answered Yes to the following questions:</strong> <br>' +
            failureAnswers + '</span>',
          cssClass: 'wide-alert',
          buttons: [
            {
              text: 'Go back',
              cssClass: 'font-color-primary',
              role: 'cancel'
            },
            {
              text: 'Continue',
              cssClass: 'font-color-primary',
              handler: () => {
                this.processScreening();
              }
            },
          ]
        });
        await alert.present();
      } else {
        this.processScreening();
      }
    } else {
      this.analytics.clickEvent('Sch-Screening: Form Validation Falure', '');
      console.log('Form validation failed');
    }

  }

  processScreening() {
    // const authUser = this.authService.getAuthUser();
    this.response.x_result = this.questions;
    this.response.userId = this.userId;
    // this.notificationsService.startLoading();
    this.currentView = 'loading';
    this.questionsService.saveAnswers(this.schApplication.screeningFormId, this.response)
      .pipe(first())
      .subscribe(async (res: Submission) => {
        this.notificationsService.stopLoading();
        this.analytics.clickEvent('Sch-Screening: Next Page', this.userId + ' ' + res.alertText);
        this.submitAttempted = false;
        this.appointment.screening = res;
        // this.schedulerService.setAppointment(this.appointment);

        // Check for a valid response
        if (res.alertComment === 'G') {
          this.clearIneligibleConsents();
          this.currentView = 'eligible';
        } else if (res.alertComment === 'Y') {
          this.currentView = 'form';
          const alert = await this.alertCtrl.create({
            header: 'Important',
            message: '<span class="font-color-dark">' + res.alertText + '</span>',
            cssClass: 'wide-alert',
            buttons: [
              {
                text: 'Go back',
                cssClass: 'font-color-primary',
                role: 'cancel'
              },
              {
                text: 'Continue',
                cssClass: 'font-color-primary',
                handler: () => {
                  this.modalCtrl.dismiss(this.appointment);
                }
              },
            ]
          });
          await alert.present();

        } else {
          // Ineligible: COVID-19 Vaccine Sched
          this.markUserAsIneligible();
        }
      });

  }

  async markUserAsIneligible() {
    const consent: SchConsent = {
      application: this.schApplication.application,
      consentType: 'VACINELIGIBLE',
      userId: this.userId,
      response: 'COVID-19 Vaccine: Failed screening- ineligible: ' + moment().format('M/D/YYYY HH:mm:ss'),
      detail: 'Failed Vaccine Screening',
      active: 1,
    };
    try {
      const savedConsent = await this.schedulerService.saveConsent(consent).toPromise();
      this.showIneligibleView();
    } catch (err) {
      this.notificationsService.handleError(err, 'Setting vaccine ineligible');
      this.currentView = 'error';
    }
  }

  showIneligibleView() {
    this.currentView = 'ineligible';
    this.instructions = this.appointment.screening.alertText;
    switch (this.status) {
      case 'not-well':
        this.heading = 'Not feeling well?';
        break;

      case 'vaccine-ineligible':
        this.heading = 'You cannot get the vaccine at this time.';
        break;

      default:
        break;
    }
  }

  continueWithoutScreening() {
    this.analytics.clickEvent('AdminScreening: NoScreeningContinue', '');
    const submission: Submission = { alertComment: 'G' };
    this.appointment.screening = submission;
    this.dismiss();
  }

  dismiss() {
    this.analytics.clickEvent('AdminScreening: Dismissed', '');
    this.modalCtrl.dismiss(this.appointment);
  }

}

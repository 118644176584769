import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AdPopUp } from 'src/app/models/ad-popup';

@Injectable({
  providedIn: 'root'
})
export class AdPopupService {
  adsSubject: BehaviorSubject<AdPopUp[]> = new BehaviorSubject([]);

  constructor(
    public modalCtrl: ModalController
  ) { }


  setAds(ads: AdPopUp[]) {
    this.adsSubject.next(ads);
  }

  getAds(): AdPopUp[] {
    return this.adsSubject.getValue();
  }

  addAd(ad: AdPopUp) {
    const ads = this.getAds();
    const existingAds = ads.filter(a => a.adName === ad.adName);
    if (existingAds.length === 0) {
      ads.push(ad);
      ads.sort((a, b) => (a.order < b.order ? -1 : (a.order > b.order ? 1 : 0)));
      this.setAds(ads);
    }
  }

  hideAds() {
    const ads = this.getAds();
    ads.forEach((ad) => {
      if (ad.status === 'show') {
        ad.status = 'hide';
      }
    });
    this.setAds(ads);
  }

  getNextAd(): AdPopUp {
    const ads = this.getAds();
    return ads.filter(ad => ad.status === 'show')[0];
  }

  async updateStatus(adName: string, status = 'read') {
    const ads = this.getAds();
    ads.forEach((ad) => {
      if (ad.adName === adName) {
        ad.status = status;
      }
    });
    this.setAds(ads);
  }

}

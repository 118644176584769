import { BhAppHeaderMobileComponentModule } from '../../components/_core/bh-app-header-mobile/bh-app-header-mobile.component.module';
import { BhValidationErrorComponentModule } from '../../components/_core/bh-validation-error/bh-validation-error.component.module';
import { BhInputComponentModule } from '../../components/_core/bh-input/bh-input.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManageQuestionsEditAnswersPage } from './manage-questions-edit-answers.page';
import { ManageQuestionsEditAnswersPageRoutingModule } from './manage-questions-edit-answers-routing.module';

@NgModule({
  imports: [
    BhAppHeaderMobileComponentModule,
    BhInputComponentModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ManageQuestionsEditAnswersPageRoutingModule
  ],
  declarations: [ManageQuestionsEditAnswersPage]
})
export class ManageQuestionsEditAnswersPageModule {}

import { ProfileService } from 'src/app/services/profile/profile.service';
import { OhmVaccineHistory } from '../../models/ohm-vaccine-history';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { GuestsService } from 'src/app/services/guests/guests.service';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';

@Component({
  selector: 'app-manage-people-appts-vaccine-history',
  templateUrl: './manage-people-appts-vaccine-history.page.html',
  styleUrls: ['./manage-people-appts-vaccine-history.page.scss'],
})
export class ManagePeopleApptsVaccineHistoryPage implements OnInit {
  @Input() userId;
  ohmVaccineHistoryEntries: OhmVaccineHistory[] = [];
  currentView: 'loading' | 'history';

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notificationsService: NotificationsService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loadOhmVaccineHistory();
  }

  async loadOhmVaccineHistory() {
    try {
      const ohmDataRes = await this.profileService.getOhmVaccineHistory(this.userId).toPromise();
      this.ohmVaccineHistoryEntries = ohmDataRes.ohmData;
      // Reorder vaccines newest to oldest by vaccine date
      // const ohmVaccineHistoryOrdered = this.ohmVaccineHistoryEntries.sort((a, b) => (a.vaccineDate < b.vaccineDate) ? 1 : -1);
      // this.ohmVaccineHistoryEntries = ohmVaccineHistoryOrdered;
      this.currentView = 'history';
    } catch (err) {
      this.notificationsService.handleError(err, 'loadOhmVaccineHistory()');
      console.error(err);
    }
  }

  dismiss() {
    this.analytics.clickEvent('AdminEmployeeVaccineHistory: Dismissed', '');
    this.modalCtrl.dismiss();
  }

}

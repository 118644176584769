import { NavigationService } from './../../../services/navigation/navigation.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

/**
 * ID: bh-app-footer
 * Name: BH App Footer
 * Description: Displays standardized footer that presents help links, app version number, and more.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'bh-app-footer',
  templateUrl: './bh-app-footer.component.html',
  styleUrls: ['./bh-app-footer.component.scss'],
})
export class BhAppFooterComponent implements OnInit {
  @Input() marginTop = '48px';
  env = environment;
  year = moment().year();
  constructor(
    private navService: NavigationService,
    private analytics: BhAnalyticsService
  ) { }

  ngOnInit() {}

  open(page) {
    this.analytics.clickEvent('app-footer: navigate', page);
    this.navService.navigateRoot(page);
  }

}

import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { GuestsService } from 'src/app/services/guests/guests.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';

@Component({
  selector: 'app-guest-covid-vaccine-resend-link',
  templateUrl: './guest-covid-vaccine-resend-link.page.html',
  styleUrls: ['./guest-covid-vaccine-resend-link.page.scss'],
})
export class GuestCovidVaccineResendLinkPage implements OnInit {
  isLoading = false;
  currentView: 'form' | 'invalid-id' | 'email-resent' = 'form';
  errorType: 'invalidDate' | 'invalidInfo' = null;
  birthdateForm: FormGroup = this.formBuilder.group({
    month: ['Month', Validators.required],
    day: ['Day', Validators.required],
    year: ['Year', Validators.required],
  });
  birthdate = null;
  invalidDate = null;
  dobFormatted = null;
  months = [
    { num: 1, name: 'Jan' },
    { num: 2, name: 'Feb' },
    { num: 3, name: 'Mar' },
    { num: 4, name: 'Apr' },
    { num: 5, name: 'May' },
    { num: 6, name: 'Jun' },
    { num: 7, name: 'Jul' },
    { num: 8, name: 'Aug' },
    { num: 9, name: 'Sep' },
    { num: 10, name: 'Oct' },
    { num: 11, name: 'Nov' },
    { num: 12, name: 'Dec' },
  ];
  days = [];
  years = [];
  email = null;

  constructor(
    private formBuilder: FormBuilder,
    private analytics: BhAnalyticsService,
    private schedulerService: SchedulerService,
    private notifications: NotificationsService,
    private guestsService: GuestsService,
    private modalCtrl: ModalController
  ) {
    this.populateDaysYears();
  }

  ngOnInit() {
  }

  populateDaysYears() {
    this.days = [];
    this.years = [];

    // Populate days
    for (let i = 1; i < 32; i++) {
      this.days.push(i);
    }

    // Populate years
    const today = moment();
    const year = Number(today.format('YYYY'));
    for (let y = year; y > 1900; y--) {
      this.years.push(y);
    }
  }

  async resendLink() {
    if (this.validateBirthDate()) {
      // Validate birthdate and genKey
      this.isLoading = true;
      const emailRes = await this.guestsService.resendAccessKeyEmail(this.email, this.dobFormatted).toPromise();
      // console.log('verifyIdentity: guest/login', response);
      if (emailRes && emailRes.error && emailRes.error.message && emailRes.error.message.indexOf('Invalid') > -1) {
        // Invalid genKey or birthdate detected
        this.errorType = 'invalidInfo';
        this.analytics.clickEvent('GuestResendLink: verify identity', 'Invalid genKey or birthdate');
      } else {
        this.currentView = 'email-resent';
      }
    } else {
      this.analytics.clickEvent('GuestResendLink: verify identity', 'Invalid date selected');
      this.invalidDate = true;
    }
  }

  validateBirthDate(): boolean {
    const month = this.birthdateForm.controls.month.value;
    const day = this.birthdateForm.controls.day.value;
    const year = this.birthdateForm.controls.year.value;
    const dobMoment = moment(year + '-' + month + '-' + day, 'YYYY-M-D', true);
    this.dobFormatted = dobMoment.format('MM/DD/YYYY');
    return (this.dobFormatted !== 'Invalid date');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}

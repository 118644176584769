import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManageGuestWaitlistEditPage } from './manage-guest-waitlist-edit.page';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhValidationErrorComponentModule } from 'src/app/components/_core/bh-validation-error/bh-validation-error.component.module';
import { ManageGuestWaitlistEditPageRoutingModule } from './manage-guest-waitlist-edit-routing.module';

@NgModule({
  imports: [
    BhInputComponentModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ManageGuestWaitlistEditPageRoutingModule
  ],
  declarations: [ManageGuestWaitlistEditPage]
})
export class ManageGuestWaitlistEditPageModule {}

import { BhAdPopupComponentModule } from './../../components/bh-ad-popup/bh-ad-popup.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CovidTestResultAdPage } from './covid-test-result-ad.page';
import { CovidTestResultAdPageRoutingModule } from './covid-test-result-ad-routing.module';

@NgModule({
  imports: [
    BhAdPopupComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    CovidTestResultAdPageRoutingModule
  ],
  declarations: [CovidTestResultAdPage],
  exports: [
    CovidTestResultAdPage
  ]
})
export class CovidTestResultAdPageModule {}

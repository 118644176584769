import { ManagePeopleApptsEditPage } from './manage-people-appts-edit.page';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: ManagePeopleApptsEditPage,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagePeopleApptsEditPageRoutingModule {}

import { BhInputComponentModule } from '../../components/_core/bh-input/bh-input.component.module';
import { BhValidationErrorComponentModule } from '../../components/_core/bh-validation-error/bh-validation-error.component.module';
import { BhSpinnerComponentModule } from '../../components/_core/bh-spinner/bh-spinner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManageGuestRegisterPage } from './manage-guest-register.page';
import { ManageGuestRegisterPageRoutingModule } from './manage-guest-register-routing.module';

@NgModule({
  imports: [
    BhSpinnerComponentModule,
    BhInputComponentModule,
    BhValidationErrorComponentModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ManageGuestRegisterPageRoutingModule
  ],
  declarations: [ManageGuestRegisterPage]
})
export class ManageGuestRegisterPageModule {}

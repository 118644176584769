import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { Profile } from 'src/app/models/profile';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-callback-phone-ad',
  templateUrl: './callback-phone-ad.page.html',
  styleUrls: ['./callback-phone-ad.page.scss'],
})
export class CallbackPhoneAdPage implements OnInit {
  authUser: User;
  profile: Profile;
  submitAttempted = false;
  breadcrumbs: Breadcrumb[] = [
    { label: 'Profile', url: null, direction: null },
  ];
  profileForm: FormGroup = this.formBuilder.group({
    callbackPhone: ['', [Validators.required, Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]],
  });
  validationMessages = {
    callbackPhone: [
      { type: 'required', message: 'Contact Phone is required.' },
      { type: 'pattern', message: 'Use the phone number format: XXX-XXX-XXXX' }
    ],
  };

  constructor(
    private navCtrl: NavController,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private profileService: ProfileService,
    private usersService: UsersService,

  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loadProfile();
  }

  loadProfile() {
    this.profile = this.profileService.getProfile();
    this.authUser = this.authService.getAuthUser();
    this.profileForm.controls.callbackPhone.setValue(this.authUser.callbackPhone);
  }

  async save() {
    if (this.profileForm.valid) {
      this.analytics.clickEvent('saveProfile', '');
      this.notificationsService.startLoading();
      this.authUser.callbackPhone = this.profileForm.controls.callbackPhone.value;
      try {
        const userRes = await this.usersService.update(this.authUser).toPromise();
        await this.profileService.loadProfile(this.authUser.userId, 'self');
        this.notificationsService.stopLoading();
        this.notificationsService.showAlert('Thank you', 'Profile successfully saved');
        this.modalCtrl.dismiss('read');
      } catch (err) {
        this.notificationsService.stopLoading();
        this.notificationsService.handleError(err, 'Profile: save()');
      }
    } else {
      this.notificationsService.showAlert('', 'Complete all required fields marked in red.');
    }
  }


}

import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { SchApplication } from '../../models/sch-application';
import { Employee } from '../../models/employee';
import { Submission } from '../../models/submission';
import { QuestionsService } from '../../services/questions/questions.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ModalController } from '@ionic/angular';
import { SchLocation } from 'src/app/models/sch-location';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { Campaign } from '../../models/campaign';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { Subscription } from 'rxjs';
import { SchTimeSlot } from 'src/app/models/sch-timeslot';
import { SelectOption } from 'src/app/models/_core/select-option';
import * as moment from 'moment';
import { AppValue } from 'src/app/models/app-value';
import { SchLocationDatetimeModalPage } from '../scheduler/sch-location-datetime-modal/sch-location-datetime-modal.page';
import { SchConsent } from 'src/app/models/sch-consent';
import { ManagePeopleApptsVaccineHistoryPage } from '../manage-people-appts-vaccine-history/manage-people-appts-vaccine-history.page';
import { ManagePeopleApptsScreeningPage } from '../manage-people-appts-screening/manage-people-appts-screening.page';
import { ManagePeopleApptsConsentPage } from '../manage-people-appts-consent/manage-people-appts-consent.page';
import { NextDoseInfo } from 'src/app/models/next-dose-info';

@Component({
  selector: 'app-manage-people-appts-edit',
  templateUrl: './manage-people-appts-edit.page.html',
  styleUrls: ['./manage-people-appts-edit.page.scss'],
})
export class ManagePeopleApptsEditPage implements OnInit, OnDestroy {
  @Input() editMode: 'edit' | 'new' = 'new';
  @Input() prevVisit: SchAppointment = null;
  @Input() visit: SchAppointment;
  @Input() schApplication: SchApplication;
  @Input() campaignOptions: SelectOption[];
  @Input() vaccineOptions: SelectOption[];
  @Input() campaigns: Campaign[];
  @Input() relationshipOptions: SelectOption[];
  @Input() index: number;
  @Input() employee: Employee;
  @Input() isGuest = true;
  env = environment;
  selectedCampaign: Campaign;
  selectedVaccine: SchVaccine;
  selectedDose: number;
  doseLabel = 'Dose';
  vaccines: SchVaccine[];
  locations: SchLocation[];
  showVaccineWarning = false;
  doseOptions: SelectOption[] = [];
  hasDeclinations = false;
  guardianConsents: SchConsent[] = [];
  hasGuardianConsent = false;
  ineligibleConsents: SchConsent[] = [];
  isIneligible = false;
  genderOptions: SelectOption[] = this.helpers.genderOptions;

  form1: FormGroup = this.formBuilder.group({
    visitLocation: ['', [Validators.required]],
    visitCampaign: ['', [Validators.required]],
    visitVaccine: ['', [Validators.required]],
    visitDose: ['', [Validators.required]],
    // , Validators.pattern(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/)
    visitDate: ['', [Validators.required]],
    visitTime: ['', [Validators.required]],
    visitTimeLegacy: [''],
    visitAdminStatus: ['', [Validators.required]],
    visitAdminNote: [''],
    visitEnableCompatMode: [false],
    visitSymptomatic: ['', [Validators.required]],
    dob: ['',],
    gender: ['',],
    email: ['', [Validators.required, Validators.pattern(/^.+@.+\..+$/)]],
    phone: ['', [Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]],
    ecName: [''],
    ecRelationship: [''],
    ecPhone: ['', [Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]],
  });
  submitAttempted = false;
  showErrorMessage = false;
  validationMessages = {
    visitLocation: [
      { type: 'required', message: 'Location is required.' },
    ],
    visitCampaign: [
      { type: 'required', message: 'Campaign is required.' },
    ],
    visitVaccine: [
      { type: 'required', message: 'Vaccine is required.' },
    ],
    visitDose: [
      { type: 'required', message: 'Dose/Visit Number is required.' },
    ],
    visitDate: [
      { type: 'required', message: 'Appointment Date is required.' },
      { type: 'pattern', message: 'Appointment Date is invalid. Use MM/DD/YYYY.' }
    ],
    visitTime: [
      { type: 'required', message: 'Appointment Time is required.' },
    ],
    visitTimeLegacy: [
      { type: 'required', message: 'Appointment Time is required.' },
    ],
    visitAdminStatus: [
      { type: 'required', message: 'Appointment Status is required.' },
    ],
    visitSymptomatic: [
      { type: 'required', message: 'COVID-19 Symptomatic Status is required.' },
    ],
    dob: [
      { type: 'required', message: 'A valid Date of Birth is required.' },
      { type: 'pattern', message: 'Date of Birth is invalid. Use MM/DD/YYYY.' }
    ],
    gender: [{ type: 'required', message: 'Gender is required.' }],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Email is invalid.' }
    ],
    phone: [
      { type: 'pattern', message: 'Please use the format of XXX-XXX-XXXX' }
    ],
    ecPhone: [
      { type: 'pattern', message: 'Please use the format of XXX-XXX-XXXX' }
    ],
  };
  currentView: 'loading' | 'new' | 'edit' | 'error' = 'loading';
  fieldSubs: Subscription[] = [];
  tsDabounceTimer = null;
  tsBackCompatMode = false;
  minDate = '2020';
  maxDate = '2030';
  visitDate = null;
  visitTimeSlotSeq: number = null;
  visitTimeLegacy = null;
  timeSlots: SchTimeSlot[] = [];
  timeSlotOptions: SelectOption[] = [];
  locationOptions: SelectOption[] = [];
  adminStatuses: AppValue[] = [];
  adminStatusOptions = [];
  symptomaticOptions: SelectOption[] = [
    {
      active: 1,
      label: 'No',
      value: 0
    },
    {
      active: 1,
      label: 'Yes',
      value: 1
    }
  ];
  initVisit = {};
  vaccineMessage = '';
  dobFormatted: string;

  constructor(
    private modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    public analytics: BhAnalyticsService,
    public schedulerService: SchedulerService,
    private notifications: NotificationsService,
    private questionsService: QuestionsService,
    private helpers: HelperUtilitiesService,
  ) { }

  async ngOnInit() {
    this.initVisit = Object.assign({}, this.visit);
    await this.loadAdminStatusOptions();
    this.doseLabel = this.schApplication.appType === 'V' ? 'Dose' : 'Visit';
    this.subscribeToFields();
    this.setDefaultDateValue();
    if (this.visit.valid) {
      this.editMode = 'edit';
      this.loadData();
    } else {
      this.editMode = 'new';
      this.setEmployeeDemoFields();
      this.loadFromPrevVisit();
      this.visit.ecSeq = 1; // Use1 for invalid ecSeq, not 0/null
      this.visit.active = 1;
      this.visit.aptSeq = 0;
      this.visit.vacApptGroup = 0;
      this.visit.adminNote = '';
      this.visit.firstName = this.employee.firstname;
      this.visit.lastName = this.employee.lastname;
      this.visit.empUserId = this.employee.userid.toLowerCase();
      this.visit.userId = this.employee.userid.toLowerCase();
      this.handleSymptomaticCovidTesting();
      this.campaigns = this.campaigns.filter(c => c.active === 1);
      // console.log('Adding new appointment', this.campaigns.filter(c => c.active === 1), this.campaigns);
      this.campaignOptions = [];
      this.campaigns.forEach(c => {
        const option: SelectOption = { value: c.camSeq, label: c.description };
        this.campaignOptions.push(option);
      });
      this.currentView = 'edit';
      // this.openLocationDateTimeModal(this.visit);
    }
  }

  ngOnDestroy() {
    this.fieldSubs.forEach(s => {
      s.unsubscribe();
      s = null;
    });
  }

  handleSymptomaticCovidTesting() {
    if (this.schApplication.application === this.env.covid19Testing.symptomatic.applicationId) {
      this.form1.controls.visitSymptomatic.setValue(1);
    } else {
      this.form1.controls.visitSymptomatic.setValue(0);
    }

    console.log('symptomatic value', this.form1.controls.visitSymptomatic.value);
  }

  // Load Admin Statuses
  async loadAdminStatusOptions(): Promise<boolean> {
    try {
      this.adminStatusOptions = [];
      const statusRes = await this.schedulerService.getAppValues(this.schApplication.application, 'ADMINSTATUS').toPromise();
      // Prepare Admin Status select options
      if (statusRes && statusRes.appvalues) {
        this.adminStatuses = statusRes.appvalues;
        statusRes.appvalues.forEach((a: AppValue) => {
          const selectOption: SelectOption = { label: a.description, value: a.value };
          this.adminStatusOptions.push(selectOption);
        });
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notifications.handleError(err, 'loadAdminStatusOptions');
      return Promise.resolve(true);
    }
  }

  setLocations(locations: SchLocation[] = null): Promise<boolean> {
    this.locationOptions = [];
    this.locations = locations;
    // Prepare Location select options
    if (this.locations && this.locations.length > 0) {
      this.locations.forEach(l => {
        const name = (l.active === 1) ? l.name : l.name + ' -- INACTIVE';
        this.locationOptions.push({ label: name, value: l.locSeq });
      });
    }
    return Promise.resolve(true);
  }

  setDefaultDateValue() {
    if (this.visit.vacDose === 1 && this.editMode === 'new') {
      const todayMoment = moment();
      this.form1.controls.visitDate.setValue(todayMoment.format('YYYY-MM-DD'));
    }
  }

  async loadData() {
    if (this.visit) {
      await this.loadConsents();
      await this.loadScreeningHistory();
      this.loadAppointment();
      this.currentView = 'edit';
    } else {
      // Missing information
      this.notifications.showAlert('', 'Appointment missing information.');
    }
  }

  async loadScreeningHistory(): Promise<boolean> {
    try {
      if (this.visit.subSeq > 0) {
        const screeningRes = await this.questionsService
          .getHistoryBySubSeq(this.schApplication.screeningFormId, this.visit.subSeq).toPromise();
        if (screeningRes.submission && screeningRes.submission.length > 0) {
          this.visit.screening = screeningRes.submission[screeningRes.submission.length - 1];
        }
      } else {
        const screeningRes = await this.questionsService
          .getHistory(this.schApplication.application, this.employee.userid, false).toPromise();
        // console.log('Screenings loaded', screeningRes);
        if (screeningRes.submission && screeningRes.submission.length > 0) {
          this.visit.screening = screeningRes.submission[screeningRes.submission.length - 1];
        }

      }
      return Promise.resolve(true);
    } catch (err) {
      this.notifications.handleError(err, 'Loading Screening History');
    }
  }

  async loadConsents(): Promise<boolean> {
    try {
      const userId = this.employee.userid;
      const consentRes = await this.schedulerService.getConsentsByAppTypeAndUserId(
        this.schApplication.application,
        userId
      ).toPromise();
      const consents = consentRes.consents;

      // Determine if there is a guardian consent signed
      this.guardianConsents = consents.filter(c => c.consentType === 'GUARDCONSENT' && c.active === 1);
      this.hasGuardianConsent = (this.guardianConsents.length > 0);

      return Promise.resolve(true);
    } catch (err) {
      this.notifications.handleError(err, 'Loading consents');
    }
  }


  async loadAppointment() {
    if (this.visit.camSeq && this.visit.vacSeq && this.visit.locSeq) {
      // console.log('Loading visit parameters: ', this.visit, this.prevVisit, this.visit.camSeq, this.campaigns);

      // Get Campaign
      this.selectedCampaign = this.campaigns.find((c: Campaign) => c.camSeq === this.visit.camSeq);
      if (this.selectedCampaign !== undefined) {
        this.form1.controls.visitCampaign.setValue(this.visit.camSeq);

        // Get Appointment Type (Vaccine)
        this.setVaccines(this.selectedCampaign.vaccines);
        this.selectedVaccine = this.vaccines.find((v: SchVaccine) => v.vacSeq === this.visit.vacSeq);
        if (this.selectedVaccine) {
          this.form1.controls.visitVaccine.setValue(this.visit.vacSeq);
          this.form1.controls.visitDose.setValue(this.visit.vacDose);
          this.locations = this.selectedVaccine.locations;

          // Get Location
          const visitLocation = this.locations.find(l => l.locSeq === this.visit.locSeq);
          if (visitLocation !== undefined) {
            this.form1.controls.visitLocation.setValue(visitLocation.locSeq);
            const visitScheduleDateMoment = moment(this.visit.scheduleDate, 'MM/DD/YYYY hh:mm A');
            await this.loadTimeSlots(this.visit, visitScheduleDateMoment.format('MM/DD/YYYY'));
            this.form1.controls.visitDate.setValue(visitScheduleDateMoment.format('YYYY-MM-DD'));
            this.visitDate = visitScheduleDateMoment.format('MM/DD/YYYY');
            const timeslot = this.getTimeslotFromArray(this.timeSlots, this.visit.tsSeq);
            this.form1.controls.visitTime.setValue((timeslot) ? timeslot.tsSeq : null);
            this.tsBackCompatMode = (this.visit.tsSeq !== null &&
              this.timeSlots.filter(a => a.tsSeq.toString() === this.visit.tsSeq.toString()).length === 0);
            if (this.tsBackCompatMode) {
              this.form1.controls.visitTimeLegacy.setValue(visitScheduleDateMoment.format('HH:mm'));
            }
            this.form1.controls.visitEnableCompatMode.setValue(this.tsBackCompatMode);
            this.form1.controls.visitAdminStatus.setValue(this.visit.adminStatus);
            this.form1.controls.visitAdminNote.setValue(this.visit.adminNote);
            this.form1.controls.visitEnableCompatMode.setValue(this.tsBackCompatMode);
            this.form1.controls.visitSymptomatic.setValue(this.visit.symptomatic);
            this.setTimeFields();
            this.setEmployeeDemoFields();

          } else {
            const errorMsg = 'Unable to open Appointment editor: Missing location';
            this.notifications.handleError(errorMsg, 'loadAppointment');
            this.modalCtrl.dismiss();
          }
        } else {
          const errorMsg = 'Unable to open Appointment editor: Missing vaccine/appt type';
          this.notifications.handleError(errorMsg, 'loadAppointment');
          this.modalCtrl.dismiss();
        }

      } else {
        const errorMsg = 'Unable to open Appointment editor: Missing campaign';
        this.notifications.handleError(errorMsg, 'loadAppointment');
        this.modalCtrl.dismiss();
      }
    }
  }

  async prepareEmployeeDemoFields() {
    console.log('prepareEmployeeDemoFields: subscribed');
    this.fieldSubs.push(
      // Add date logic
      this.form1.controls.dob.valueChanges.subscribe(async val => {
        console.log('Dob value changed');
        await this.setEmployeeBirthDate(val, 'YYYY-MM-DD');
      }),
      // Contact Information
      this.form1.controls.email.valueChanges.subscribe(val => {
        this.visit.email = val;
      }),
      // Mobile Phone
      this.form1.controls.phone.valueChanges.subscribe(val => {
        this.visit.mobilePhone = val;
      }),
      // Emergency Contact
      this.form1.controls.ecName.valueChanges.subscribe(val => {
        this.visit.ecName = val;
      }),
      // EC Relationship
      this.form1.controls.ecRelationship.valueChanges.subscribe(val => {
        this.visit.ecRelationship = val;
      }),
      // EC Phone
      this.form1.controls.ecPhone.valueChanges.subscribe(val => {
        this.visit.ecPhone = val;
      })
    );
  }

  setEmployeeDemoFields() {
    // Person-type specific fields
    if (this.isGuest) {
      this.clearEmployeeDemoFieldValidations();
    } else {
      let dobMoment;
      let email;
      let mobilePhone;
      let genderValue;

      // Define data source for edit type
      if (this.editMode === 'new') {
        dobMoment = moment(this.employee.dob, 'MM/DD/YYYY');
        this.setEmployeeBirthDate(dobMoment.format('YYYY-MM-DD'), 'YYYY-MM-DD');
        email = this.employee.preferredEmail || this.employee.email;
        this.visit.email = email;
        mobilePhone = this.employee.callbackPhone;
        this.visit.callbackPhone = mobilePhone;
        this.visit.mobilePhone = mobilePhone;
        genderValue = this.employee.genderValue;
        this.visit.note = this.helpers.encodeEmployeeGenderInNote(genderValue);
        this.form1.controls.gender.setValue(genderValue);
        this.visit.adminStatus = 'PENDING';
      } else {
        dobMoment = moment(this.visit.dob, 'MM/DD/YYYY');
        email = this.visit.email;
        mobilePhone = this.visit.mobilePhone;
        genderValue = this.helpers.decodeEmployeeGenderFromNote(this.visit.note);
        this.form1.controls.gender.setValue(genderValue);
      }
      // Employee Information
      console.log('loadAppointment: Employee information loading');
      this.form1.controls.dob.setValue(dobMoment.format('YYYY-MM-DD'));
      // const visitGenderValue = this.helpers.decodeEmployeeGenderFromNote(this.visit.note);
      this.form1.controls.email.setValue(email);
      this.form1.controls.phone.setValue(mobilePhone);

      // Subscribe to employee fields
      this.prepareEmployeeDemoFields();
    }

    // Emergency Contact Fields
    const ecName = this.visit.ecName || this.employee.ecName;
    this.form1.controls.ecName.setValue(ecName);
    const ecPhone = this.visit.ecPhone || this.employee.ecPhone;
    this.form1.controls.ecPhone.setValue(ecPhone);
    const ecRelationship = this.visit.ecRelationship || this.employee.ecRelationship;
    this.form1.controls.ecRelationship.setValue(ecRelationship);

  }

  /**
   * Validates format and sets Employee Birth Date on Visit
   *
   * @param dob Date of birth value
   * @param dobFormat DOB format used for parsing
   * @returns Boolean indicating dob was successfully parsed
   */
  async setEmployeeBirthDate(dob: string, dobFormat: string): Promise<boolean> {
    console.log('setEmployeeBirthDate: ', dob, dobFormat);
    const dobMoment = moment(dob, dobFormat, true);
    this.dobFormatted = dobMoment.format('MM/DD/YYYY');
    const validDate = (this.dobFormatted !== 'Invalid date');
    console.log('setEmployeeBirthDate: ', this.dobFormatted, validDate);
    if (validDate) {
      this.visit.dob = this.dobFormatted;
    }
    return Promise.resolve(validDate);
  }

  /**
   * Clears Employee demographic field validations (used for Guests)
   */
  clearEmployeeDemoFieldValidations() {
    this.form1.controls.dob.clearValidators();
    this.form1.controls.dob.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.form1.controls.month.clearValidators();
    this.form1.controls.month.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.form1.controls.day.clearValidators();
    this.form1.controls.day.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.form1.controls.year.clearValidators();
    this.form1.controls.year.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.form1.controls.gender.clearValidators();
    this.form1.controls.gender.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  loadFromPrevVisit() {
    if (this.prevVisit && this.prevVisit.camSeq && this.prevVisit.vacSeq && this.prevVisit.locSeq) {
      // console.log('Loading prevVist parameters: ', this.prevVisit);
      this.form1.controls.visitCampaign.setValue(this.prevVisit.camSeq);
      this.selectedCampaign = this.campaigns.find((c: Campaign) => c.camSeq === this.prevVisit.camSeq);
      this.selectedVaccine = this.vaccines.find((v: SchVaccine) => v.vacSeq === this.prevVisit.vacSeq);
      if (this.prevVisit.vacDose < this.selectedVaccine.doseCount) {
        this.form1.controls.visitVaccine.setValue(this.prevVisit.vacSeq);
        this.form1.controls.visitDose.setValue(this.prevVisit.vacDose + 1);
        this.form1.controls.visitSymptomatic.setValue(this.prevVisit.symptomatic);
        this.selectedDose = this.prevVisit.vacDose + 1;
      } else {
        this.selectedVaccine = null;
      }
    }
  }

  async subscribeToFields() {
    this.fieldSubs.push(
      // Campaign
      this.form1.controls.visitCampaign.valueChanges.subscribe(async val => {
        if (val) {
          const campaign = this.campaigns.find(c => c.camSeq === Number(val));
          if (campaign !== undefined) {
            this.selectedCampaign = campaign;
            this.visit.campaign = campaign;
            this.visit.camSeq = campaign.camSeq;
            this.form1.controls.visitVaccine.setValue('');
            await this.setVaccines(campaign.vaccines);
            this.updateVisitLabel();
          } else {
            console.error('visitCampaign.valueChanges: Missing campaign');
          }
        }
      }),

      // Appointment Type (Vaccine)
      this.form1.controls.visitVaccine.valueChanges.subscribe((val: SchVaccine) => {
        if (val) {
          const vaccine = this.selectedCampaign.vaccines.find(c => c.vacSeq === Number(val));
          if (vaccine !== undefined) {
            this.visit.vacSeq = vaccine.vacSeq;
            this.visit.vaccine = vaccine;
            this.visit.cvaSeq = vaccine.cvaSeq;
            this.setLocations(vaccine.locations);
            const doseCount = vaccine.doseCount;
            this.doseOptions = [];
            for (let i = 1; i <= doseCount; i++) {
              const doseNumber = this.schedulerService.getFriendlyDoseNumber(i);
              const doseOption: SelectOption = { label: doseNumber + ' ' + this.doseLabel.toLowerCase(), value: i };
              this.doseOptions.push(doseOption);
            }
            this.selectedVaccine = vaccine;
            // Prepopulate dose number if single-dose appointment-type
            if (vaccine.doseCount === 1) {
              this.form1.controls.visitDose.setValue(1);
            }
            this.updateVisitLabel();
          } else {
            console.error('visitCampaign.valueChanges: Missing vaccine');
          }
        }
      }),

      // Dose Number updates
      this.form1.controls.visitDose.valueChanges.subscribe((val: number) => {
        if (val) {
          this.selectedDose = val;
          this.visit.vacDose = Number(val);
          this.updateVisitLabel();
        }
      }),

      // Visit Location
      this.form1.controls.visitLocation.valueChanges.subscribe((val: SchLocation) => {
        if (val) {
          const location = this.locations.find(l => l.locSeq === Number(val));
          if (location !== undefined) {
            this.visit.locSeq = location.locSeq;
            this.visit.location = location;
            this.reloadTimeSlots();
          } else {
            console.error('visitLocation.valueChanges: Missing location');
          }
        }
      }),

      // Visit Date
      this.form1.controls.visitDate.valueChanges.subscribe(val => {
        console.log('visitDate changed: ', val);
        const visitDateMoment = moment(val, 'YYYY-MM-DD', true);
        this.visitDate = visitDateMoment.format('MM/DD/YYYY');
        console.log('visitDate to be saved: ', this.visitDate);
        this.setVisitDateTime();
        this.reloadTimeSlots();
      }),

      // Visit Time
      this.form1.controls.visitTime.valueChanges.subscribe(async val => {
        console.log('visitTime set: ', val);
        this.visit.tsSeq = val;
        this.visitTimeSlotSeq = val;
        this.setVisitDateTime();
      }),

      // Visit Time (legacy)
      this.form1.controls.visitTimeLegacy.valueChanges.subscribe(val => {
        // TODO: Review this logic for timeslots
        this.visitTimeLegacy = val;
        this.setVisitDateTime();
      }),

      // Admin Status
      this.form1.controls.visitAdminStatus.valueChanges.subscribe(val => {
        this.visit.adminStatus = val;
      }),

      // Admin Note
      this.form1.controls.visitAdminNote.valueChanges.subscribe(val => {
        this.visit.adminNote = val;
      }),

      // Enable Compatibility Mode
      this.form1.controls.visitEnableCompatMode.valueChanges.subscribe(val => {
        this.tsBackCompatMode = val;
      }),

      // COVID-19 Symptomatic Flag
      this.form1.controls.visitSymptomatic.valueChanges.subscribe(val => {
        this.visit.symptomatic = val;
      }),
    );

  }

  async updateVisitLabel() {
    try {
      if (this.selectedVaccine) {
        const visitDose = this.form1.controls.visitDose.value;
        if (visitDose) {
          this.visit.visitLabel =
            await this.schedulerService.getFriendlyLabel(this.schApplication.application, this.selectedVaccine, visitDose, null);

          if (visitDose > 1) {
            // Get most recent previous dose with same vaccine
            const nextDoseInfo: NextDoseInfo = this.schedulerService.getNextDoseDateRange(this.prevVisit, this.selectedVaccine, true);
            // Calculate scheduling window
            this.visit.startDate = nextDoseInfo.startDate;
            this.visit.endDate = nextDoseInfo.endDate;
          }
        }
      } else {
        this.visit.visitLabel = 'New Appointment';
      }
    } catch (err) {
      console.error('updateVisitLabel error: ', err);
    }
  }


  setVaccines(vaccines: SchVaccine[] = null): Promise<boolean> {
    this.vaccineOptions = [];
    this.vaccines = vaccines.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    if (this.vaccines && this.vaccines.length > 0) {
      this.vaccines.forEach(v => {
        const vacName = v.name + ((v.dose1Available > 0) ? ' -- Availabile' : ' -- Not Available -- DO NOT USE');
        this.vaccineOptions.push({ label: vacName, value: v.vacSeq });
      });
      return Promise.resolve(true);
    }
  }

  async loadTimeSlots(visit, day): Promise<boolean> {
    const vacSeq = (this.selectedVaccine) ? this.selectedVaccine.vacSeq : null;
    if (visit && visit.locSeq && vacSeq && day && visit.vacDose) {
      // Clear timeslots
      this.timeSlots = [];
      this.timeSlotOptions = [];
      const limitSlot = 0;
      try {
        const tsRes = await this.schedulerService.getTimeSlotAvailability(
          visit.locSeq,
          day,
          day,
          vacSeq,
          2, // Set to dose 2 to disable look-ahead
          this.visit.camSeq,
          this.selectedVaccine.cvaSeq,
          limitSlot,
          1
        ).toPromise();

        if (tsRes.slots && tsRes.slots.length > 0) {
          const tsOptions: SelectOption[] = [];
          // Load Select Options
          for (const s of tsRes.slots.sort((a, b) => (a.startTime < b.startTime ? -1 : (a.startTime > b.startTime ? 1 : 0)))) {
            const overbookCount = s.availabilitySlots * -1;
            const label = s.startTime + ((s.availabilitySlots === 0) ? ' - FULL - DO NOT BOOK AT THIS TIME' :
              (s.availabilitySlots < 0) ? ' - Overbooked by ' + overbookCount : ' - Available');
            const opt: SelectOption = { label, value: s.tsSeq };
            tsOptions.push(opt);
          };

          this.timeSlotOptions = tsOptions;

          // Load global timeslots array
          this.timeSlots = tsRes.slots;
          this.tsBackCompatMode = false;
        } else {
          const dayMoment = moment(day, 'MM/DD/YYYY', true);
          const todayMoment = moment();
          if (dayMoment.format('YYYY-MM-DD') < todayMoment.format('YYYY-MM-DD')) {
            // Switch to compatibility mode
            this.tsBackCompatMode = true;
          }
        }
        this.setVisitDateTime();
        return Promise.resolve(true);
      } catch (err) {
        console.error('Unable to get timeslots, trying again', err);
        return Promise.resolve(true);
      }
    }
  }

  async reloadTimeSlots() {
    clearTimeout(this.tsDabounceTimer);
    this.tsDabounceTimer = setTimeout(async () => {
      if (this.form1.controls.visitLocation.valid && this.visitDate && this.form1.controls.visitDate.valid) {
        await this.loadTimeSlots(this.visit, this.visitDate);
        console.log('timeslots loaded from visitLocation', this.timeSlotOptions);
      } else {
        console.log('visitLocation: Visit Location, Visit Date not valid');
      }
    }, 300);
  }

  getTimeslotFromArray(timeslotArray: SchTimeSlot[], tsSeq: number) {
    if (timeslotArray && timeslotArray.length > 0 && tsSeq) {
      const ts = timeslotArray.find((t: SchTimeSlot) => t.tsSeq.toString() === tsSeq.toString());
      return ts;
    } else {
      return null;
    }
  }

  setVisitDateTime() {
    let validDate = false;
    let visitDateMoment = null;
    let visitTimeSlot: SchTimeSlot = null;
    // const visitTime = this.form1.controls.visitTime.value;
    const visitTsSeq = this.visit.tsSeq;

    // if (visitTsSeq) {
    //   const timeslot: SchTimeSlot = this.getTimeslotFromArray(this.timeSlots, visitTsSeq);
    //   this.visitTimeSlotSeq = (timeslot) ? timeslot.tsSeq : null;
    // }

    // Get timeslot
    if (this.tsBackCompatMode || !visitTsSeq) {
      visitTimeSlot = { tsSeq: 0, startTime: this.visitTimeLegacy };
      this.visitTimeSlotSeq = 0;
    } else {
      visitTimeSlot = this.getTimeslotFromArray(this.timeSlots, visitTsSeq);
    }

    // Check first date
    if (this.visitDate && this.visitDate.length === 10 && visitTimeSlot) {
      visitDateMoment = moment(this.visitDate, 'MM/DD/YYYY', true);
      const visitMoment = moment(visitDateMoment.format('MM/DD/YYYY') + ' ' + visitTimeSlot.startTime, 'MM/DD/YYYY HH:mm', true);
      this.visit.tsSeq = visitTimeSlot.tsSeq;
      this.visit.scheduleDate = visitMoment.format('MM/DD/YYYY hh:mm A');
      const formattedSchedDate = visitMoment.format('MMM D, YYYY') + ' at ' + visitMoment.format('h:mm a');
      this.visit.scheduleDateFormatted = formattedSchedDate;
      this.visit.valid = true;
      validDate = true;
      console.log('setVisitDateTime: ', this.form1.controls.visitTime.value);
    }
  }

  setTimeFields() {
    if (this.tsBackCompatMode) {
      this.form1.controls.visitTime.clearValidators();
      this.form1.controls.visitTime.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.form1.controls.visitTimeLegacy.setValidators([Validators.required]);
      this.form1.controls.visitTimeLegacy.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    } else {
      this.form1.controls.visitTime.setValidators([Validators.required]);
      this.form1.controls.visitTime.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.form1.controls.visitTimeLegacy.clearValidators();
      this.form1.controls.visitTimeLegacy.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  openAdvancedEditor() {
    this.currentView = 'edit';
  }

  async openLocationDateTimeModal(visit: SchAppointment) {
    // Use reschedule flag to avoid locking timeslot/saving/corrupting with uncommitted admin changes
    visit.reschedule = true;
    // HACK: Seed current visit as the root appointment to trick scheduler into showing relative information
    // TODO: Rework scheduler location/time modal to use argument-based parameters
    this.schedulerService.appointment.vaccine = this.selectedVaccine;
    this.schedulerService.appointment.campaign = this.selectedCampaign;
    this.schedulerService.appointment.camSeq = this.selectedCampaign.camSeq;
    this.schedulerService.appointment.userId = this.employee.userid;
    this.schedulerService.scheduleCovidVaccineType = visit.covidVaccineType;
    const modal = await this.modalCtrl.create({
      component: SchLocationDatetimeModalPage,
      componentProps: {
        schApplication: this.schApplication.application,
        visit,
        appointment: this.schedulerService.appointment
      },
      backdropDismiss: false,
      cssClass: 'action-sheet-modal',
      // cssClass: 'ad-modal-tall'
    });

    modal.onDidDismiss().then(async val => {
      if (val.data) {
        this.currentView = 'edit';
        const visitFromScheduler: SchAppointment = val.data;
        const selectedLocation = this.locations.find(l => l.locSeq === visitFromScheduler.location.locSeq);
        const visitScheduleDateMoment = moment(visitFromScheduler.scheduleDate, 'MM/DD/YYYY hh:mm A');
        this.visit.location = selectedLocation;
        this.visit.locSeq = visitFromScheduler.location.locSeq;
        this.visit.scheduleDate = visitFromScheduler.scheduleDate;
        const formattedSchedDate = visitScheduleDateMoment.format('MMM D, YYYY') + ' at ' + visitScheduleDateMoment.format('h:mm a');
        this.visit.scheduleDateFormatted = formattedSchedDate;
        this.visit.adminStatus = 'SCHEDULED';
        this.form1.controls.visitAdminStatus.setValue('SCHEDULED');
        this.form1.controls.visitLocation.setValue(selectedLocation.locSeq);
        this.form1.controls.visitDate.setValue(visitScheduleDateMoment.format('YYYY-MM-DD'));
        if (visitFromScheduler.timeslot && visitFromScheduler.tsSeq) {
          this.visit.tsSeq = visitFromScheduler.tsSeq;
          console.log('Valid timeslot selected => 1', visitFromScheduler);
          this.visit.timeslot = visitFromScheduler.timeslot;
          await this.loadTimeSlots(this.visit, visitScheduleDateMoment.format('MM/DD/YYYY'));
          this.form1.controls.visitTime.setValue(visitFromScheduler.tsSeq);
          console.log('Valid timeslot selected => 2',
            this.form1.controls.visitTime.value,
            visitFromScheduler.tsSeq,
            this.form1.controls.visitTime.value === visitFromScheduler.tsSeq);
          this.tsBackCompatMode = false;
        } else {
          // console.log('Walk-in (no timeslot) selected');
          this.form1.controls.visitTimeLegacy.setValue(visitScheduleDateMoment.format('HH:mm'));
          this.tsBackCompatMode = true;
        }
        this.setTimeFields();
        this.visit.valid = true;
      }
    });

    return await modal.present();
  }

  save() {
    if (this.form1.valid && this.visit.valid) {
      console.log('Completed visit', this.visit);
      this.dismiss('saved');
    }
  }

  dismiss(action: 'not-saved' | 'saved' | 'datepicker' = 'not-saved') {
    if (action === 'not-saved') {
      this.visit = this.initVisit;
    }
    this.modalCtrl.dismiss({ action, visit: this.visit, index: this.index });
  }

  async openConsent() {
    const modal = await this.modalCtrl.create({
      component: ManagePeopleApptsConsentPage,
      componentProps: {
        vaccine: this.selectedVaccine,
        visit: this.visit,
        schApplicationId: this.schApplication.application,
        isGuest: this.isGuest,
        ecSeq: this.visit.ecSeq,
        userId: this.employee.userid,
        employee: this.employee,
      },
      backdropDismiss: false,
      cssClass: 'ad-modal-tall'
    });

    modal.onDidDismiss().then(async val => {
      if (val && val.data && val.data.vaccineConsent) {
        const consent: SchConsent = val.data.vaccineConsent;
        this.visit.vaccineConsent = consent;
        this.visit.ecSeq = consent.ecSeq;
        if (this.employee.age && this.employee.age < 18) {
          this.hasGuardianConsent = true;
        }
        // console.log('Appointment updated with consent data', this.appointment);
      }
    });

    return await modal.present();

  }

  async openScreening() {
    const modal = await this.modalCtrl.create({
      component: ManagePeopleApptsScreeningPage,
      componentProps: {
        appointment: this.visit,
        schApplication: this.schApplication,
        userId: this.employee.userid,
        subSeq: this.visit.subSeq
      },
      backdropDismiss: false,
      cssClass: 'ad-modal'
    });

    modal.onDidDismiss().then(async val => {
      if (val && val.data && val.data.screening) {
        const screening: Submission = val.data.screening;
        this.visit.screening = screening;
        this.visit.subSeq = screening.subSeq;
      }
    });
    return await modal.present();
  }

  async openVaccineHistory() {
    const modal = await this.modalCtrl.create({
      component: ManagePeopleApptsVaccineHistoryPage,
      componentProps: {
        userId: this.employee.userid
      },
      backdropDismiss: false,
      cssClass: 'ad-modal-tall'
    });
    return await modal.present();

  }


}

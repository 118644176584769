import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Breadcrumb } from './../../models/breadcrumb';
import { NavController } from '@ionic/angular';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'bh-header-large',
  templateUrl: './bh-header-large.component.html',
  styleUrls: ['./bh-header-large.component.scss'],
})
export class BhHeaderLargeComponent implements OnChanges {
  @Input() title = '';
  @Input() hideBlueStrip = false;
  @Input() forceStyle = 'auto';
  @Input() showBackButton = false;
  @Input() showEndButtons = false;
  @Input() desktopOnly = false;
  @Input() progress = 100;
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() useSegmentBar = false;
  @Input() totalSegments = 0;
  @Input() filledSegments = 0;
  @Input() hideSpacer = false;
  @Input() centerHeading = false;

  rootBreadcrumb: Breadcrumb = null;
  backBreadcrumb: Breadcrumb = null;
  segments: any[] = [];

  constructor(
    private navCtrl: NavController,
    private authService: AuthService
  ) { }

  ngOnChanges() {
    this.buildSegments();
    this.setFirstBreadcrumb();
    this.getLastBreadcrumb();

  }

  buildSegments() {
    if (this.useSegmentBar) {
      this.segments = [];
      let filledSegments = 0;
      for (let i = 0; i < this.totalSegments; i++) {
        const segment = { filled: filledSegments < this.filledSegments };
        this.segments.push(segment);
        filledSegments += 1;
      }
    }
  }

  setFirstBreadcrumb() {
    if (!this.rootBreadcrumb) {
      const authUser = this.authService.getAuthUser();
      const breadcrumb: Breadcrumb = { direction: 'back', isRoot: true };
      if (authUser && authUser.userId) {
        breadcrumb.label = 'Home';
        breadcrumb.url = '/tabs/home';
      } else {
        breadcrumb.label = 'Sign in';
        breadcrumb.url = '/login';
      }
      this.rootBreadcrumb = breadcrumb;
      // this.breadcrumbs.unshift(breadcrumb);
    }
  }

  getLastBreadcrumb() {
    // console.log('Checking breadcrumbs', this.breadcrumbs);
    if (this.breadcrumbs && this.breadcrumbs.length > 0) {
      for (let i = (this.breadcrumbs.length - 1); i > -1; i--) {
        const breadcrumb = this.breadcrumbs[i];
        if (!breadcrumb.isRoot && breadcrumb.url) {
          // this.showBackButton = true;
          this.backBreadcrumb = breadcrumb;
        }
      }

      if (!this.backBreadcrumb) {
        this.backBreadcrumb = this.rootBreadcrumb;
      }
    }
  }

  back() {
    if (this.backBreadcrumb) {
      this.goToBreadcrumb(this.backBreadcrumb);
    }
  }

  goToBreadcrumb(breadcrumb: Breadcrumb) {
    if (breadcrumb.direction && breadcrumb.url) {
      switch (breadcrumb.direction) {
        case 'back':
          this.navCtrl.navigateBack(breadcrumb.url);
          break;
        case 'forward':
          this.navCtrl.navigateForward(breadcrumb.url);
          break;
        case 'root':
          this.navCtrl.navigateRoot(breadcrumb.url);
          break;
        case 'external':
          location.href = breadcrumb.url;
          break;
      }
    }
  }


  // back() {
  //   if (this.backButtonUrl.length > 0) {
  //     this.navCtrl.navigateBack(this.backButtonUrl);
  //   } else {
  //     this.navCtrl.back();
  //   }
  // }

}

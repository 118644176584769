import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CovidVaccineFeedbackModalPage } from './covid-vaccine-feedback-modal.page';
import { CovidVaccineFeedbackModalPageRoutingModule } from './covid-vaccine-feedback-modal-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CovidVaccineFeedbackModalPageRoutingModule
  ],
  declarations: [CovidVaccineFeedbackModalPage]
})
export class CovidVaccineFeedbackModalPageModule {}

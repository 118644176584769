import { BhEmptyMessageComponentModule } from './../_core/bh-empty-message/bh-empty-message.component.module';
import { BhSearchBarComponentModule } from './../_core/bh-search-bar/bh-search-bar.component.module';
import { BhMessageBannerComponentModule } from 'src/app/components/_core/bh-message-banner/bh-message-banner.component.module';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { BhLocationPickerComponent } from './bh-location-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        BhLocationPickerComponent,
    ],
    imports: [
        BhSearchBarComponentModule,
        BhSpinnerComponentModule,
        BhMessageBannerComponentModule,
        BhEmptyMessageComponentModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ],
    exports: [
        BhLocationPickerComponent
    ]
})
export class BhLocationPickerComponentModule { }

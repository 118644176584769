import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Profile } from 'src/app/models/profile';
import { User } from 'src/app/models/user';
import { AuthService } from '../_core/auth/auth.service';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class GuestRouteGuardService {
  private authUser: User = null;
  private profile: Profile = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.authUser = this.authService.getAuthUser();
    this.profile = this.profileService.getProfile();
    const allowNavigate = (this.authUser && this.authUser.userId !== null && this.authUser.NBUEmployee);
    if (!allowNavigate) {
      return this.router.parseUrl('/guest/covid-vaccine');
    }
    return allowNavigate;


  }
}

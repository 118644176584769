import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManageHelpDocsPage } from './manage-help-docs.page';
import { ManageHelpDocsPageRoutingModule } from './manage-help-docs-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ManageHelpDocsPageRoutingModule
  ],
  declarations: [ManageHelpDocsPage]
})
export class ManageHelpDocsPageModule {}

import { BhConfirmationComponentModule } from './../../components/_core/bh-confirmation/bh-confirmation.component.module';
import { BhSpinnerComponentModule } from '../../components/_core/bh-spinner/bh-spinner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManagePeopleApptsConsentPage } from './manage-people-appts-consent.page';
import { ManagePeopleApptsConsentPageRoutingModule } from './manage-people-appts-consent-routing.module';

@NgModule({
  imports: [
    BhSpinnerComponentModule,
    BhConfirmationComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ManagePeopleApptsConsentPageRoutingModule
  ],
  declarations: [ManagePeopleApptsConsentPage]
})
export class ManagePeopleApptsConsentPageModule {}

import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NavController, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { SchConsent } from 'src/app/models/sch-consent';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-covid-vaccine-feedback-modal',
  templateUrl: './covid-vaccine-feedback-modal.page.html',
  styleUrls: ['./covid-vaccine-feedback-modal.page.scss'],
})
export class CovidVaccineFeedbackModalPage implements OnInit {
  env = environment;
  selectedFeedback = null;
  feedbackReason = '';
  feedbackSubmitted = false;
  imageUrl = '';
  heading = '';
  instructions = '';
  telHref = 'tel:' + this.env.constants.ehsPhoneNumber;
  showCallEhsButton = false;
  profile: Profile = null;
  lastVisit: SchAppointment = null;
  authUser: User = null;
  showErrorMessage = false;

  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private profileService: ProfileService,
    private analytics: BhAnalyticsService,
    private schedulerService: SchedulerService,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.profile = this.profileService.getProfile();
    this.authUser = this.authService.getAuthUser();
    this.lastVisit = this.profile.covidVaccineLastVisit;
  }

  setFeedback(status) {
    this.analytics.clickEvent('Vaccine-Checkin-ad: Viewed:', '');
    this.selectedFeedback = status;
  }

  async submit() {
    if (
      this.selectedFeedback === 'good' ||
      (this.selectedFeedback === 'not-well' && this.feedbackReason)
    ) {
      this.analytics.clickEvent('Vaccine-Checkin-ad: Selected Feedback', '');
      this.showErrorMessage = false;
      this.feedbackSubmitted = true;
      const consent: SchConsent = {
        application: this.lastVisit.application,
        consentType: 'COVIDVACCHECK',
        userId: this.authUser.userId,
        response: this.selectedFeedback,
        detail: (this.feedbackReason) ? this.feedbackReason : 'Feeling good',
        active: 1,
      };
      const consentRes = await this.schedulerService.saveConsent(consent).toPromise();

      if (this.selectedFeedback === 'good') {
        this.heading = 'Thank you for your time';
        this.instructions = 'We will periodically ask you how you are feeling again just make sure you\'re doing well.';
        this.imageUrl = '/assets/success-icon.png';
        this.showCallEhsButton = false;
      } else {
        this.heading = 'Not feeling well?';
        this.instructions = 'Please contact Employee Health Services (EHS) at ' + this.env.constants.ehsPhoneLabel +
          ' and report your symptoms now. Lastly, please report your symptoms using the <strong>Safety Reporting System</strong> ' +
          ' located on TheHub > Resources';
        this.imageUrl = '/assets/danger-icon.png';
        this.showCallEhsButton = true;
      }
    } else {
      if (!this.feedbackReason) {
        this.showErrorMessage = true;
      }
    }
  }

  back() {
    this.analytics.clickEvent('Vaccine-Checkin-ad: Dismissed:', '');
    this.navCtrl.navigateForward('/tabs/home');
    this.modalCtrl.dismiss();
  }

}

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BhInputChecklistComponent } from './bh-input-checklist.component';

@NgModule({
    declarations: [
        BhInputChecklistComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ],
    exports: [
        BhInputChecklistComponent
    ]
})
export class BhInputChecklistComponentModule { }

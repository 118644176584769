import { BhMessageBannerComponentModule } from './../_core/bh-message-banner/bh-message-banner.component.module';
import { BhCalendarMonthComponentModule } from './bh-calendar-month/bh-calendar-month.component.module';
import { BhCalendarComponent } from './bh-calendar.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        BhCalendarComponent,
    ],
    imports: [
        BhMessageBannerComponentModule,
        CommonModule,
        FormsModule,
        IonicModule,
        BhCalendarMonthComponentModule
    ],
    exports: [
        BhCalendarComponent
    ]
})
export class BhCalendarComponentModule { }

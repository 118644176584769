import { BhAppHeaderMobileComponentModule } from 'src/app/components/_core/bh-app-header-mobile/bh-app-header-mobile.component.module';
import { BhAppHeaderWebComponentModule } from 'src/app/components/_core/bh-app-header-web/bh-app-header-web.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { WelcomeWhatsNewPage } from './welcome-whats-new.page';
import { BhHeaderLargeComponentModule } from 'src/app/components/bh-header-large/bh-header-large.component.module';

const routes: Routes = [
  {
    path: '',
    component: WelcomeWhatsNewPage
  }
];

@NgModule({
  imports: [
    BhAppHeaderWebComponentModule,
    BhHeaderLargeComponentModule,
    BhAppHeaderMobileComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [WelcomeWhatsNewPage]
})
export class WelcomeWhatsNewPageModule {}

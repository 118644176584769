import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { StorageService } from './../../../services/_core/storage/storage.service';
import { SchLocation } from 'src/app/models/sch-location';
import { SchedulerService } from './../../../services/scheduler/scheduler.service';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { CalendarWeek, CalendarWeekday } from 'src/app/models/calendarWeek';
import { SchTimeSlot } from 'src/app/models/sch-timeslot';

@Component({
  selector: 'bh-calendar-month',
  templateUrl: './bh-calendar-month.component.html',
  styleUrls: ['./bh-calendar-month.component.scss'],
})
export class BhCalendarMonthComponent implements OnChanges {
  @Input() selectedMonth = 1;
  @Input() selectedYear = 2020;
  @Input() selectedDay = null;
  @Input() location: SchLocation;
  @Input() allowFutureDates = true;
  @Input() allowPastDates = true;
  @Input() overrideStartDate = null;
  @Input() overrideEndDate = null;
  @Input() vacDose: number = null;
  @Input() vaccine: SchVaccine = null;
  @Input() camSeq: number = null;
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() selectedDate = new EventEmitter<any>();

  today = moment();
  selectedTime = moment(this.selectedMonth + '/' + 1 + '/' + this.selectedYear, 'M/D/YYYY', true);
  thisMonth = this.selectedTime.get('month');
  thisYear = this.selectedTime.get('year');
  totalDays = 0;
  totalWeeks = 0;
  calendarWeeks: CalendarWeek[] = [];
  initCalendar = false;
  startDate = null;
  startMoment = null;
  endDate = null;
  endMoment = null;
  showSpinner = false;
  timeslots: SchTimeSlot[];
  loadTimeSlotsPromise = null;
  isSupportMode = false;
  authUser: User = null;

  constructor(
    public notifications: NotificationsService,
    private schedulerService: SchedulerService,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.resetTodayMoment();
    this.authUser = this.authService.getAuthUser();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      'selectedMonth' in changes ||
      'selectedYear' in changes ||
      'selectedDay' in changes ||
      'appointment' in changes ||
      'location' in changes ||
      'overrideStartDate' in changes ||
      'overrideEndDate' in changes ||
      'vacDose' in changes ||
      'vaccine' in changes
    ) {
      this.checkForSupportMode();
      this.clearCalendar();
      this.buildCalendar();
    }
  }

  async checkForSupportMode(): Promise<boolean> {
    const supportMode = await this.storageService.getData('KioskMode');
    // console.log('KioskMode', supportMode);
    this.isSupportMode = (supportMode && supportMode.enabled === true) ? true : false;
    return Promise.resolve(true);
  }

  /**
   * Reset today variable's hour to 12:00 am for past day calculation
   */
  resetTodayMoment() {
    // console.log('Resetting view to today');
    const todayMonth = this.today.format('M');
    const todayDay = this.today.format('D');
    const todayYear = this.today.format('YYYY');
    this.today = moment(todayMonth + '/' + todayDay + '/' + todayYear + ' 00:00:00', 'M/D/YYYY HH:mm:ss', true);
  }

  async clearCalendar() {
    this.loadTimeSlotsPromise = null;
    this.calendarWeeks = [];
  }

  async buildCalendar() {
    if (this.location) {
      // Prepare calendar properties

      // User overrideStartDate if available or location startDate
      this.startDate = (this.overrideStartDate) ? this.overrideStartDate : this.location.startDate;
      this.startMoment = this.startDate ? moment(this.startDate + ' 00:00', 'MM/DD/YYYY HH:mm') : null;

      // User overrideEndDate if available or location endDate
      this.endDate = (this.overrideEndDate) ? this.overrideEndDate : this.location.endDate;
      this.endMoment = this.endDate ? moment(this.endDate + ' 23:59', 'MM/DD/YYYY HH:mm') : null;

      this.today = moment();
      this.selectedTime = (this.selectedDay && !this.initCalendar) ?
        this.selectedDay :
        moment(this.selectedMonth + '/' + 1 + '/' + this.selectedYear, 'M/D/YYYY', true);
      this.initCalendar = true;
      this.thisMonth = this.selectedTime.get('month');
      this.thisYear = this.selectedTime.get('year');
      this.totalDays = 0;
      this.totalWeeks = 0;
      this.calendarWeeks = [];
      const numberOfDays = this.getNumberOfDays();
      const offsetDays = this.getOffsetDays();
      this.totalDays = numberOfDays + offsetDays;
      this.totalWeeks = Math.ceil(this.totalDays / 7);
      await this.getTimeSlots();

      let dayCount = 0;
      // Loop through weeks
      for (let i = 1; i <= this.totalWeeks; i++) {
        const week: CalendarWeek = {};
        week.num = i;
        week.days = [];

        // Loop through days
        for (let d = 1; d <= 7; d++) {
          dayCount += 1;
          if (dayCount <= this.totalDays) {
            const day: CalendarWeekday = {};
            day.num = (dayCount <= offsetDays) ? null : (dayCount - offsetDays);
            day.date = moment(this.selectedMonth + '/' + day.num + '/' + this.selectedYear + ' 00:00', 'M/D/YYYY HH:mm', true);
            day.isToday = (moment().format('M/D/YYYY') === day.date.format('M/D/YYYY'));
            day.isAvailable = (day.date.format('YYYY-MM-DD') >= this.startMoment.format('YYYY-MM-DD') &&
              day.date.format('YYYY-MM-DD') <= this.endMoment.format('YYYY-MM-DD'));
            day.isPastDay = day.date.format('YYYY-MM-DD') < this.today.format('YYYY-MM-DD');
            day.isFutureDay = day.date.format('YYYY-MM-DD') > this.today.format('YYYY-MM-DD');
            day.isSelected = this.selectedDay ? this.selectedDay.format('M/D/YYYY') === day.date.format('M/D/YYYY') : false;
            // NOTE: Add !isToday requirement to the hasActivity logic to block today as a scheduling option
            // hasActivity flag determines if day has availability
            day.hasActivity =
              day.isAvailable
                && !day.isPastDay
                // && this.checkToday(day) // Disabled to allow employees to schedule COVID Tests on same day
                ? this.checkAvailability(day) : false;
            week.days.push(day);
          }
        }
        this.calendarWeeks.push(week);
      }
    }
  }

  getNumberOfDays(): number {
    // console.log('ThisMonth: ', this.thisMonth);
    switch (this.thisMonth) {
      case 0:
      case 2:
      case 4:
      case 6:
      case 7:
      case 9:
      case 11:
        return 31;

      case 1:
        return (Math.floor(this.thisYear % 4) === 0) ? 29 : 28;

      default:
        return 30;
    }
  }

  getOffsetDays() {
    const firstDay = moment(this.selectedMonth + '/' + 1 + '/' + this.selectedYear, 'M/D/YYYY', true);
    const dayOfWeek = firstDay.get('day');
    return dayOfWeek;
  }

  async getTimeSlots(): Promise<any> {
    this.isLoading.emit(true);
    this.showSpinner = true;
    this.timeslots = [];
    const thisMonth = Number(moment().format('MM'));
    const thisYear = Number(moment().format('YYYY'));
    const firstDay = (this.selectedMonth === thisMonth && this.selectedYear === thisYear) ?
      moment() :
      moment(this.selectedMonth + '/' + 1 + '/' + this.selectedYear, 'M/D/YYYY', true);
    const lastDay = moment(this.selectedMonth + '/' + 1 + '/' + this.selectedYear, 'M/D/YYYY', true).add(1, 'month').add(-1, 'day');
    const vacSeq = (this.vaccine) ? this.vaccine.vacSeq : null;
    const limitSlot = this.vaccine.lookAhead;
    for (let i = 0; i < 3; i++) {
      try {
        const data = await this.schedulerService.getTimeSlotAvailability(
          this.location.locSeq,
          firstDay.format('MM/DD/YYYY'),
          lastDay.format('MM/DD/YYYY'),
          vacSeq,
          this.vacDose,
          this.camSeq,
          this.vaccine.cvaSeq,
          limitSlot,
          1
        ).toPromise();
        this.timeslots = data.slots;
        this.showSpinner = false;
        this.isLoading.emit(false);
        break;
      } catch (err) {
        console.error('Unable to get data, trying again', err);
      }
    }
    return Promise.resolve(true);
  }

  selectDate(day: CalendarWeekday) {
    // console.log('Selected date ', day);
    // console.log('Result: ' +  (this.isSupportMode && day.isToday));
    if (day.num !== null) {
      if (day.date > this.today && !this.allowFutureDates) {
        this.notifications.showToast('You cannot select a future day.');
      } else if (!day.isToday && day.date < this.today && !this.allowPastDates) {
        this.notifications.showToast('You cannot select a past day.');
      } else if (!day.isAvailable) {
        this.notifications.showToast('This day is not available.');
      } else if (!day.hasActivity && !(this.isSupportMode && day.isToday)) {
        this.notifications.showToast('This day is not available.');
      } else {
        this.selectedDate.emit(day.date);
        this.clearSelectedDays();
        day.isSelected = true;
      }
    }
  }

  clearSelectedDays() {
    this.calendarWeeks.forEach(week => {
      week.days.forEach(day => {
        day.isSelected = false;
      });
    });
  }

  checkToday(day: CalendarWeekday): boolean {
    if (
      (this.isSupportMode && day.isToday) ||
      (this.authUser.role === 'GST_ADMIN' && day.isToday) ||
      (this.authUser.role === 'ADMIN' && day.isToday) ||
      (this.authUser.role === 'SYS_ADMIN' && day.isToday) ||
      !day.isToday
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkAvailability(day: CalendarWeekday) {
    let dayAvailability = 0;
    this.timeslots.forEach((t: SchTimeSlot) => {
      const now = moment('01/01/1900 ' + moment().format('HH:mm'), 'MM/DD/YYYY HH:mm', true);
      const timeslotMoment = moment('01/01/1900 ' + t.startTime, 'MM/DD/YYYY HH:mm', true);
      if (t.availabilityDate === day.date.format('MM/DD/YYYY')) {
        if (t.availabilityDate === this.today.format('MM/DD/YYYY') && now.format('HH:mm') <= timeslotMoment.format('HH:mm')) {
          // Today - only look forward
          dayAvailability += t.availabilitySlots;
        } else if (t.availabilityDate !== this.today.format('MM/DD/YYYY')) {
          // Future day
          dayAvailability += t.availabilitySlots;
        }
      }
    });
    // console.log('Found availability: ' + day.date.format('MM/DD/YYYY'), dayAvailability);
    return dayAvailability > 0;
  }
}

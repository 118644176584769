import { BhAppointmentCardComponentModule } from './../../components/bh-appointment-card/bh-appointment-card.component.module';
import { BhAdPopupComponentModule } from './../../components/bh-ad-popup/bh-ad-popup.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AppointmentReminderAdPage } from './appointment-reminder-ad.page';
import { AppointmentReminderAdPageRoutingModule } from './appointment-reminder-ad-routing.module';

@NgModule({
  imports: [
    BhAdPopupComponentModule,
    BhAppointmentCardComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    AppointmentReminderAdPageRoutingModule
  ],
  declarations: [AppointmentReminderAdPage]
})
export class AppointmentReminderAdPageModule {}

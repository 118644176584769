import { User } from './../../../models/user';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';

/**
 * ID: bh-user-badge
 * Name: BH User Badge
 * Description: Displays user's initials in a colorful badge
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-02 - MW - v2: Fixed user-badge colors for color-0, color-9
 */
@Component({
  selector: 'bh-user-badge',
  templateUrl: './bh-user-badge.component.html',
  styleUrls: ['./bh-user-badge.component.scss'],
})
export class BhUserBadgeComponent implements OnChanges {
  @Input() userFullName: string;
  @Input() userId: string;
  @Input() isSelf = false;
  @Input() size: 'large' | 'medium' | 'small' = 'medium';
  senderInitials = '';
  authUser: User;
  colorClass = '';

  constructor(
    private authService: AuthService,
    private helpers: HelperUtilitiesService,
  ) { }

  ngOnChanges() {
    this.authUser = this.authService.getAuthUser();
    this.setUserBadge();
  }

  setUserBadge() {
    if (this.userFullName) {
      const firstName = this.helpers.getFirstName(this.userFullName);
      const lastName = this.helpers.getLastName(this.userFullName);
      const firstNameInitial = firstName ? firstName.substr(0, 1) : null;
      const lastNameInitial = lastName ? lastName.substr(0, 1) : null;
      this.senderInitials = firstNameInitial + lastNameInitial;
      this.setBadgeColor();
    }
  }

  setBadgeColor() {
    if (this.isSelf) {
      this.colorClass = 'self';
    } else {
      if (this.userId && this.userId.length > 1) {
        const lastChar = this.userId.substr(this.userId.length - 1, 1);
        this.colorClass = 'color-' + lastChar;
      }
    }
  }


}

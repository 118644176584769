import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private analytics: BhAnalyticsService
    ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.analytics.clickEvent('Welcome: Set Reminders', 'Everday 7am');
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  goToPrefs(shouldSetReminders: boolean) {
    this.analytics.clickEvent('Welcome: Set Reminders', 'Choose reminders');
    const options: NavigationOptions = {
      queryParams: {
        setReminders: shouldSetReminders
      }
    };
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('/tabs/profile/reminders/covid19', options);
  }
}

import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Message } from 'src/app/models/message';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { StorageService } from 'src/app/services/_core/storage/storage.service';

@Component({
  selector: 'app-manage-messaging-popup',
  templateUrl: './manage-messaging-popup.page.html',
  styleUrls: ['./manage-messaging-popup.page.scss'],
})
export class ManageMessagingPopupPage implements OnInit {
  env = environment;
  msgId: string;
  message: Message;

  constructor(
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private notifications: NotificationsService,
    private messagingService: MessagingService,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loadMessaging();
  }

  async loadMessaging() {
    try {
      const msgRes = await this.messagingService.getByIdAndLanguage(this.env.covid19Vaccine.applicationId, this.msgId).toPromise();
      this.message = msgRes.msg;
      this.analytics.screenView('AdminMessagingView: ' + this.message.id);
    } catch (err) {
      this.notifications.handleError(err, 'loadMessaging', null, 'An error occurred while loading your message.');
    }
  }

  async acknowledge() {
    this.storageService.saveData('msg_' + this.msgId, this.message);
    this.analytics.clickEvent('AdminMessagingPopUp: Acknowledged', this.message.id);
    this.modalCtrl.dismiss();
  }


}

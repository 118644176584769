import { NavController, ModalController } from '@ionic/angular';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeedbackPage } from 'src/app/pages/_core/feedback/feedback.page';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

/**
 * ID: bh-confirmation
 * Name: BH Confirmation
 * Description: Displays standardized confirmation element
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-05-09 - MW - v2: Updated for Ionic 6, shortened name from bh-content-confirmation to bh-confirmation
 */
@Component({
  selector: 'bh-confirmation',
  templateUrl: './bh-confirmation.component.html',
  styleUrls: ['./bh-confirmation.component.scss'],
})
export class BhConfirmationComponent implements OnInit {
  @Input() heading = 'Saved successfully!';
  @Input() message = 'Your work was successfully saved.';
  @Input() continueButtonLabel = 'Done';
  @Input() secondaryButtonLabel = null;
  @Input() centerButtons = false;
  @Input() showShareButton = false;
  @Input() showFeedbackButton = false;
  @Output() continueEvent = new EventEmitter<any>();
  @Output() secondaryButtonEvent = new EventEmitter<any>();

  constructor(
    private navService: NavigationService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  continue() {
    this.continueEvent.emit();
  }

  secondaryAction() {
    this.secondaryButtonEvent.emit();
  }

  async share() {
    const topModal: HTMLIonModalElement = await this.modalCtrl.getTop();
    if (topModal) {
      topModal.dismiss();
    }
    this.navService.navigateRoot('share');
  }

  async sendFeedback() {
    const topModal: HTMLIonModalElement = await this.modalCtrl.getTop();
    if (topModal) {
      topModal.dismiss();
    }
    const modal = await this.modalCtrl.create({
      component: FeedbackPage,
    });

    await modal.present();
  }

}

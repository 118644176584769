import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

/**
 * ID: bh-empty-message
 * Name: BH Empty
 * Description: Displays no-data (empty) messaging
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-05-09 - MW - v2: Added integrated action button, renamed bh-content-empty to bh-empty-message
 */
@Component({
  selector: 'bh-empty-message',
  templateUrl: './bh-empty-message.component.html',
  styleUrls: ['./bh-empty-message.component.scss'],
})
export class BhEmptyMessageComponent implements OnInit {
  @Input() ionIcon;
  @Input() message;
  @Input() showActionButton;
  @Input() actionButtonLabel;
  @Output() clickEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  buttonClicked() {
    this.clickEvent.emit();
  }

}

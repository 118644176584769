import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testing-prompt',
  templateUrl: './testing-prompt.page.html',
  styleUrls: ['./testing-prompt.page.scss'],
})
export class TestingPromptPage implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  scheduleTest() {
    this.modalCtrl.dismiss(true);
  }

  dismiss() {
    this.modalCtrl.dismiss(false);
  }

}

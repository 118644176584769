import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datepicker-modal',
  templateUrl: './datepicker-modal.page.html',
  styleUrls: ['./datepicker-modal.page.scss'],
})
export class DatepickerModalPage implements OnInit {
  @Input() setDate;
  @Input() appointment;
  @Input() location;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  selectedDate(val) {
    // console.log('Got value from datepicker', val);
    this.modalCtrl.dismiss({
      selectedDate: val
    });
  }

}

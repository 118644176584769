import { SchVaccine } from 'src/app/models/sch-vaccine';
import { SchLocation } from 'src/app/models/sch-location';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { CalendarMonth } from 'src/app/models/calendarWeek';

@Component({
  selector: 'bh-calendar',
  templateUrl: './bh-calendar.component.html',
  styleUrls: ['./bh-calendar.component.scss'],
})
export class BhCalendarComponent implements OnChanges {
  @Output() selectedDate = new EventEmitter<any>();
  @Input() setDate: string;
  @Input() location: SchLocation;
  @Input() showMonthYearSelectors = false;
  @Input() allowFutureDates = true;
  @Input() allowPastDates = true;
  @Input() overrideStartDate = null;
  @Input() overrideEndDate = null;
  @Input() historyDates: any[] = [];
  @Input() vaccine: SchVaccine;
  @Input() vacDose: number;
  @Input() camSeq: number;

  months: CalendarMonth[] = [
    {
      num: 1,
      name: 'January'
    },
    {
      num: 2,
      name: 'February'
    },
    {
      num: 3,
      name: 'March'
    },
    {
      num: 4,
      name: 'April'
    },
    {
      num: 5,
      name: 'May'
    },
    {
      num: 6,
      name: 'June'
    },
    {
      num: 7,
      name: 'July'
    },
    {
      num: 8,
      name: 'August'
    },
    {
      num: 9,
      name: 'September'
    },
    {
      num: 10,
      name: 'October'
    },
    {
      num: 11,
      name: 'November'
    },
    {
      num: 12,
      name: 'December'
    },
  ];
  years = [];
  today = moment();
  thisMonth = this.today.get('month');
  thisYear = this.today.get('year');
  selectedDay;
  selectedMonth = this.thisMonth + 1;
  selectedYear = this.thisYear;
  calendarMonthYear = moment(this.selectedMonth + '/1/' + this.selectedYear, 'M/D/YYYY', true);
  isCalendarLoading = true;

  constructor(
    public modalCtrl: ModalController
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      'setDate' in changes ||
      'location' in changes ||
      'showMonthYearSelectors' in changes ||
      'allowFutureDates' in changes ||
      'allowPastDates' in changes ||
      'overrideStartDate' in changes ||
      'overrideEndDate' in changes ||
      'historyDates' in changes ||
      'vacDose' in changes ||
      'vaccine' in changes
    ) {
      // console.log('Changes detected', changes);
      this.defineStartDate();
      if ('setDate' in changes) {
        this.defineDateArguments();
      }
    }
  }

  populateYears() {
    for (let i = this.thisYear; i >= 1900; i--) {
      this.years.push(i);
    }
  }

  defineStartDate() {
    const startDate = (this.overrideStartDate) ? this.overrideStartDate : this.location.startDate;
    // console.log('BhCalendar: StartDate', startDate);
    const startMoment = startDate ? moment(startDate + ' 00:00', 'MM/DD/YYYY HH:mm') : null;

    if (startMoment > this.today) {
      this.thisMonth = startMoment.get('month');
      this.thisYear = startMoment.get('year');
      this.selectedMonth = this.thisMonth + 1;
      this.selectedYear = this.thisYear;
      this.calendarMonthYear = moment(this.selectedMonth + '/1/' + this.selectedYear, 'M/D/YYYY', true);
    }
  }

  defineDateArguments() {
    if (this.setDate) {
      this.selectedDay = moment(this.setDate, 'M/D/YYYY', true);
      this.selectedMonth = this.selectedDay.get('month') + 1;
      this.selectedYear = this.selectedDay.get('year');
      this.selectedMonthYear();
    }

    this.populateYears();
  }

  selectedMonthYear() {
  }

  getNextMonth() {
    if (!this.isCalendarLoading) {
      if (this.selectedMonth === 12) {
        this.selectedMonth = 1;
        this.selectedYear += 1;
      } else {
        this.selectedMonth += 1;
      }
      this.updateCalendarMonthYear();
    }
  }

  getPrevMonth() {
    if (!this.isCalendarLoading) {
      if (this.selectedMonth === 1) {
        this.selectedMonth = 12;
        this.selectedYear -= 1;
      } else {
        this.selectedMonth -= 1;
      }
      this.updateCalendarMonthYear();

    }
  }

  onSelectedDate(evt) {
    this.selectedDate.emit(evt);
  }

  updateCalendarMonthYear() {
    this.calendarMonthYear = moment(this.selectedMonth + '/1/' + this.selectedYear, 'M/D/YYYY', true);
  }

  onIsLoading(val) {
    this.isCalendarLoading = val;
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'bh-workflow-buttons',
  templateUrl: './bh-workflow-buttons.component.html',
  styleUrls: ['./bh-workflow-buttons.component.scss'],
})
export class BhWorkflowButtonsComponent implements OnChanges {
  @Input() nextLabel = 'Next';
  @Input() nextDisabled = false;
  @Input() nextHidden = false;
  @Input() previousLabel = 'Back';
  @Input() previousDisabled = false;
  @Input() previousHidden = false;
  @Input() blendMode = false;
  @Input() nextToolTip = null;
  @Output() onclickNext = new EventEmitter();
  @Output() onclickPrevious = new EventEmitter();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('nextDisabled', this.nextDisabled);
  }

  clickedPrevious() {
    this.onclickPrevious.emit(true);
  }

  clickedNext() {
    this.onclickNext.emit(true);
  }

}

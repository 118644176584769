import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Guest } from 'src/app/models/guest';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { encode } from 'punycode';

@Injectable({
  providedIn: 'root'
})
export class GuestsService {
  env = environment;
  selectedGuest: BehaviorSubject<Guest> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private helpers: HelperUtilitiesService
  ) { }

  searchByLastName(lastName): Observable<any> {
    const url = `${this.env.apiUrl}/guests?lastName=${lastName}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'GET guests/searchByLastName');
          }
          return res;
        })
      );
  }

  getById(id): Observable<any> {
    const url = `${this.env.apiUrl}/guests?userId=${id}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'GET guests/searchById');
          }
          return res;
        })
      );
  }

  getGuestLoginData(email, genKey, dob, schApplicationId) {
    const body = {
      email,
      genKey,
      dob,
      formId: this.env.covid19Screening.formId,
      schApp: schApplicationId
    };

    // Get user info
    const url = this.env.apiUrl + '/guests/login';
    // const url = environment.API_URL + '/guest/login';
    return this.http.post(url, body).pipe(
      tap(async (data: any) => {
        // console.log('guest login data: ', data);
        // return data;
      })
    );

  }

  register(guestProfile: Guest): Observable<any> {
    const url = `${this.env.apiUrl}/guests/register`;
    return this.http.post(url, guestProfile)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'POST guests/register');
          }
          return res;
        })
      );
  }

  update(guestProfile: Guest): Observable<any> {
    const url = `${this.env.apiUrl}/guests/${guestProfile.userId}`;
    return this.http.put(url, guestProfile)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'PUT guests/update');
          }
          return res;
        })
      );
  }

  saveSelfProfile(guestProfile: Guest): Observable<any> {
    const url = `${this.env.apiUrl}/guests/current`;
    return this.http.put(url, guestProfile)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'PUT guests/saveSelfProfile');
          }
          return res;
        })
      );
  }

  resetAccessKey(id): Observable<any> {
    const url = `${this.env.apiUrl}/guests/reset-invite`;
    const body = {
      userId: id,
      formId: this.env.covid19Screening.formId, // TODO: Investigate why I'm sending screening FormId
      schApp: this.env.covid19Vaccine.applicationId
    };
    return this.http.post(url, body)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'POST guests/resetAccessKey');
          }
          return res;
        })
      );
  }

  resendAccessKeyEmail(email, dob): Observable<any> {
    const url = `${this.env.apiUrl}/guests/invite`;
    const body = {
      email,
      dob,
      embId: 'RESENDGK',
      testAsProd: 1
    };
    return this.http.post(url, body)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'POST guests/resendAccessKeyEmail');
          }
          return res;
        })
      );
  }


  validateEmail(email): Observable<any> {
    const encodedEmail = this.helpers.encodeEmail(email);
    const url = `${this.env.apiUrl}/guests/validate?email=${encodedEmail}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'GET guests/validateEmail');
          }
          return res;
        })
      );
  }

  validateEmailAndBirthdate(email, dob): Observable<any> {
    const encodedEmail = this.helpers.encodeEmail(email);
    const url = `${this.env.apiUrl}/guests/validate?email=${encodedEmail}&dob=${dob}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'GET guests/validateEmailAndBirthdate');
          }
          return res;
        })
      );
  }



}

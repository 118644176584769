import { OhmVaccineHistory } from './ohm-vaccine-history';
export const OhmVaccineMockData: OhmVaccineHistory[] =
    [
        // Pfizer 1
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '01-05-2021',
            lot: 'X123456',
            manufacturer: 'Pfizer-BioNTech',
            vaccineCVXCode: '208',
            vaccineType: 'COVID-19, MRNA, Spike Protein (Pfizer)',
            dose: 'Dose 1',
            DOB: '01-01-1990',
            mockId: 'pfizer1',
            injectAsMock: false
        },
        // Pfizer 2
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '01-26-2021',
            lot: 'X123456',
            manufacturer: 'Pfizer-BioNTech',
            vaccineCVXCode: '208',
            vaccineType: 'COVID-19, MRNA, Spike Protein (Pfizer)',
            dose: 'Dose 2',
            DOB: '01-01-1990',
            mockId: 'pfizer2',
            injectAsMock: false
        },
        // Pfizer 3
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '11-04-2021',
            lot: 'X123456',
            manufacturer: 'Pfizer-BioNTech',
            vaccineCVXCode: '208',
            vaccineType: 'COVID-19, MRNA, Spike Protein (Pfizer)',
            dose: 'Dose 3',
            DOB: '01-01-1990',
            mockId: 'pfizer3',
            injectAsMock: false
        },
        // Moderna 1
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '01-07-2021',
            lot: 'X123456',
            manufacturer: 'Moderna US Inc.',
            vaccineCVXCode: '207',
            vaccineType: 'COVID-19,MRNA, Spike Protein (Moderna)',
            dose: 'Dose 1',
            DOB: '01-01-1990',
            mockId: 'moderna1',
            injectAsMock: false
        },
        // Moderna 2
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '02-04-2021',
            lot: 'X123456',
            manufacturer: 'Moderna US Inc.',
            vaccineCVXCode: '207',
            vaccineType: 'COVID-19,MRNA, Spike Protein (Moderna)',
            dose: 'Dose 2',
            DOB: '01-01-1990',
            mockId: 'moderna2',
            injectAsMock: false
        },
        // Moderna 3
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '11-18-2021',
            lot: 'X123456',
            manufacturer: 'Moderna US Inc.',
            vaccineCVXCode: '207',
            vaccineType: 'COVID-19,MRNA, Spike Protein (Moderna)',
            dose: 'Dose 3',
            DOB: '01-01-1990',
            mockId: 'moderna3',
            injectAsMock: false
        },
        // J&J 1
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '06-02-2021',
            lot: 'X123456',
            manufacturer: 'Janssen Products LP',
            vaccineCVXCode: '212',
            vaccineType: 'COVID-19 Vaccine, Vector-nr, RS-Ad26, PF',
            dose: 'Dose 1',
            DOB: '01-01-1990',
            mockId: 'jj1',
            injectAsMock: false
        },
        // J&J 2
        {
            userId: 'en00000',
            ohmUserId: '00000',
            vaccineDate: '11-28-2021',
            lot: 'X123456',
            manufacturer: 'Janssen Products LP',
            vaccineCVXCode: '212',
            vaccineType: 'COVID-19 Vaccine, Vector-nr, RS-Ad26, PF',
            dose: 'Dose 2',
            DOB: '01-01-1990',
            mockId: 'jj2',
            injectAsMock: false
        },
    ];

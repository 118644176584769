import { GuestCovidVaccineRegisterPageRoutingModule } from './../guest-covid-vaccine-register/guest-covid-vaccine-register-routing.module';
import { BhSpinnerComponentModule } from './../../components/_core/bh-spinner/bh-spinner.component.module';
import { BhMessageBannerComponentModule } from './../../components/_core/bh-message-banner/bh-message-banner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GuestCovidVaccineResendLinkPage } from './guest-covid-vaccine-resend-link.page';

@NgModule({
  imports: [
    BhMessageBannerComponentModule,
    BhSpinnerComponentModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    GuestCovidVaccineRegisterPageRoutingModule
  ],
  declarations: [GuestCovidVaccineResendLinkPage]
})
export class GuestCovidVaccineResendLinkPageModule {}

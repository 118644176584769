import { BhSpinnerComponentModule } from './../_core/bh-spinner/bh-spinner.component.module';
import { BhTimechipsComponent } from './bh-timechips.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        BhTimechipsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        BhSpinnerComponentModule,
    ],
    exports: [
        BhTimechipsComponent
    ]
})
export class BhTimechipsComponentModule { }

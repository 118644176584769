import { SchApplication } from 'src/app/models/sch-application';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { SchTimeSlot } from 'src/app/models/sch-timeslot';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SchLocation } from 'src/app/models/sch-location';
import * as moment from 'moment';

@Component({
  selector: 'app-sch-location-datetime-modal',
  templateUrl: './sch-location-datetime-modal.page.html',
  styleUrls: ['./sch-location-datetime-modal.page.scss'],
})
export class SchLocationDatetimeModalPage implements OnInit {
  @Input() schApplication: SchApplication;
  @Input() appointment: SchAppointment;
  @Input() visit: SchAppointment = null;

  stepTitle = 'SELECT LOCATION: ';
  step = 'location';
  selectedLocation: SchLocation;
  selectedDate: string = null; // Timeslot?
  selectedTimeSlot: SchTimeSlot;
  selectedItems: SelectedItem = { location: null, date: null };
  overrideStartDate = null;
  overrideEndDate = null;
  disableLocationPicker = false;
  vacDose: number;
  vaccine: SchVaccine;
  camSeq: number;
  currentView: 'picker' | 'no-vaccine' = 'picker';
  schApplicationId: string = null;

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private schedulerService: SchedulerService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.stepTitle = this.stepTitle + this.visit.visitLabel;
    this.vacDose = this.visit.vacDose;
    this.vaccine = this.schedulerService.appointment.vaccine;
    this.camSeq = this.schedulerService.appointment.camSeq;
    this.schApplicationId = this.schApplication.application;
    // console.log('Received schAppointment', this.schedulerService.appointment);
    // console.log('LocationDateTimeModal', this.visit);
    // Lock location for secondary doses
    const location = (this.visit.location) ? this.visit.location : this.appointment.location;
    // console.log('ionViewWillEnter => Visit', this.visit);
    // console.log('ionViewWillEnter => Location', location);
    // console.log('ionViewWillEnter => visitLocation', this.visit.location);
    // console.log('ionViewWillEnter => apptLocation', this.appointment.location);
    if (this.vacDose > 1) {
      this.disableLocationPicker = true;
      this.selectedLocation = location;
    }
  }

  setLocation(val) {
    // console.log('Received location from picker: ', val);
    if (val) {
      this.currentView = 'picker';
      this.selectedLocation = val;
      this.visit.locSeq = this.selectedLocation.locSeq;
      this.visit.location = this.selectedLocation;
    } else {
      this.currentView = 'no-vaccine';
    }
  }

  selectDate(val) {
    this.selectedDate = val.format('M/D/YYYY');
  }

  selectTimeSlot(val) {
    this.selectedTimeSlot = val;
  }

  prepareDoseTimeInfo() {
    if (this.visit.vacDose === 1) {
      // FIRST DOSE
      const doseEndDateMoment = moment(this.schedulerService.appointment.vaccine.dose1AvailableDate + ' 23:59', 'MM/DD/YYYY HH:mm');
      const locationEndDateMoment = moment(this.selectedLocation.endDate + ' 23:59', 'MM/DD/YYYY HH:mm');
      // Get which occurs last: doseEndDate vs. locationEndDate
      const endDateMoment = (doseEndDateMoment < locationEndDateMoment) ? doseEndDateMoment : locationEndDateMoment;
      this.overrideEndDate = endDateMoment.format('MM/DD/YYYY');
    } else {
      // SECOND DOSE
      const locationStartDateMoment = moment(this.selectedLocation.startDate + ' 00:00', 'MM/DD/YYYY HH:mm');
      const visitStartDateMoment = moment(this.visit.startDate + ' 00:00', 'MM/DD/YYYY HH:mm');
      // Get which occurs first: visitStartDate vs. locationStartDate
      const startDateMoment = (visitStartDateMoment > locationStartDateMoment) ? visitStartDateMoment : locationStartDateMoment;
      this.overrideStartDate = startDateMoment.format('MM/DD/YYYY');
      const locationEndDateMoment = moment(this.selectedLocation.endDate + ' 23:59', 'MM/DD/YYYY HH:mm');
      const apptEndDateMoment = moment(this.visit.endDate + ' 23:59', 'MM/DD/YYYY HH:mm');
      // Get which occurs last: visitEndDate vs. locationEndDate
      const endDateMoment = (apptEndDateMoment < locationEndDateMoment) ? apptEndDateMoment : locationEndDateMoment;
      this.overrideEndDate = endDateMoment.format('MM/DD/YYYY');
    }

    // console.log('VisitIndex ' + this.visit.visitIndex + ' => overrideStart: ' +
    // this.overrideStartDate + ' => overrideEnd: ' + this.overrideEndDate);
  }

  validateStep() {
    if (this.step === 'location' && this.selectedLocation) {
      return true;
    } else if (this.step === 'date' && this.selectedLocation && this.selectedDate) {
      return true;
    } else if (this.step === 'time' && this.selectedLocation && this.selectedDate && this.selectedTimeSlot) {
      return true;
    } else {
      return false;
    }
  }

  nextPage() {
    if (this.validateStep()) {
      if (this.step === 'location') {
        this.stepTitle = 'SELECT DAY: ' + this.visit.visitLabel;
        this.prepareDoseTimeInfo();
        this.step = 'date';
      } else if (this.step === 'date') {
        this.stepTitle = 'SELECT TIME: ' + this.visit.visitLabel;
        this.step = 'time';
      } else if (this.step === 'time') {
        this.visit.locSeq = this.selectedLocation.locSeq;
        this.visit.location = this.selectedLocation;
        const selectedDateMoment = moment(this.selectedDate + ' ' + this.selectedTimeSlot.startTime, 'M/D/YYYY HH:mm');
        this.visit.scheduleDate = selectedDateMoment.format('MM/DD/YYYY hh:mm A');
        this.visit.scheduleDateFormatted = selectedDateMoment.format('MMM D, YYYY h:mm A');
        this.visit.tsSeq = this.selectedTimeSlot.tsSeq;
        this.visit.timeslot = this.selectedTimeSlot;
        this.visit.valid = true;
        this.visit.active = 1;
        // console.log('Visit before sending from locationDateTime', this.visit, this.visit.valid);
        this.modalCtrl.dismiss(this.visit);
      }
    }
  }

  previousPage() {
    // this.navCtrl.navigateBack('/schedule/vaccines');
    if (this.step === 'location') {
      this.modalCtrl.dismiss();
    } else if (this.step === 'date') {
      this.stepTitle = 'SELECT LOCATION: ' + this.visit.visitLabel;
      this.selectedDate = null;
      this.step = 'location';
    } else if (this.step === 'time') {
      this.stepTitle = 'SELECT DAY: ' + this.visit.visitLabel;
      this.selectedTimeSlot = null;
      this.step = 'date';
    }
  }

  openGoogleMaps(link) {
    this.analytics.clickEvent('LocationDateTimePicker: Directions and Map', link);
    window.open(link, '_system');
  }
}

export interface SelectedItem {
  location?: SchLocation;
  date?: string;
  timeSlot?: SchTimeSlot;
}

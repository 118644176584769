import { environment } from 'src/environments/environment';
import { User } from '../../models/user';
import { AuthService } from '../../services/_core/auth/auth.service';
import { BhAnalyticsService } from '../../services/_core/bhanalytics/bhanalytics.service';
import { GuestsService } from '../../services/guests/guests.service';
import { Guest } from 'src/app/models/guest';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SelectOption } from 'src/app/models/_core/select-option';
import * as moment from 'moment';

@Component({
  selector: 'app-manage-guest-waitlist-edit',
  templateUrl: './manage-guest-waitlist-edit.page.html',
  styleUrls: ['./manage-guest-waitlist-edit.page.scss'],
})
export class ManageGuestWaitlistEditPage implements OnInit {
  @Input() guest: Guest;
  env = environment;
  form1: FormGroup = this.formBuilder.group({
    action: ['', Validators.required],
  });
  showErrorMessage = false;
  validationMessages = {
    action: [{ type: 'required', message: 'Action cannot be blank.' }],
  };
  wlStatusLabel = '';
  nextAttemptLabel = '1st Attempt';
  priorityGroups = [];
  priorityGroup = null;
  authUser: User;

  constructor(
    private modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private scheduleService: SchedulerService,
    private guestsService: GuestsService,
    private analytics: BhAnalyticsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    this.authUser = this.authService.getAuthUser();
    this.analytics.screenView('WaitListEdit');
    this.getUserFriendlyWaitListStatus();
    this.getAge();
    this.loadPriorityGroups();
  }

  getUserFriendlyWaitListStatus() {
    switch (this.guest.wlStatus) {
      case 'NEW':
        this.wlStatusLabel = 'Newly Added';
        break;
      case 'CALLED1X':
        this.wlStatusLabel = 'Attempted calling 1x';
        this.nextAttemptLabel = '2nd Attempt';
        break;
      case 'CALLED2X':
        this.wlStatusLabel = 'Attempted calling 2x';
        this.nextAttemptLabel = '3rd Attempt';
        break;
      case 'CALLED3X':
        this.wlStatusLabel = 'Attempted calling 3x';
        this.nextAttemptLabel = '4th Attempt';
        break;
      case 'SCHEDULED':
        this.wlStatusLabel = 'Scheduled';
        break;
      case 'REMOVED':
        this.wlStatusLabel = 'Removed from Waitlist';
        break;
    }
  }

  async getAge() {
    const dobMoment = moment(this.guest.dob, 'MM/DD/YYYY', true);
    const today = moment();
    this.guest.age = today.diff(dobMoment, 'year');
  }

  async loadPriorityGroups() {
    try {
      const groupRes = await this.scheduleService.getPriorityGroups(false).toPromise();
      this.priorityGroups = groupRes.groups;
      this.priorityGroup = this.priorityGroups.filter(g => g.groupCode === this.guest.groupCode)[0];
    } catch (err) {
      console.error('Error occurred', err);
    }
  }

  dismiss(reload = false) {
    this.modalCtrl.dismiss({ reload });
  }

  async setAction(action) {
    this.analytics.clickEvent('WaitListEdit: ' + action, this.guest.userId);
    switch (action) {
      case 'call':
        this.promptCall();
        break;

      case 'schedule':
        this.openPage(`/tabs/manage/on-demand-waitlist/${this.guest.userId}/${this.env.covid19Vaccine.applicationId}/appointments`);
        break;

      case 'remove':
        this.promptRemoval();
        break;

      case 'profile':
        this.openPage(`/tabs/manage/on-demand-waitlist/${this.guest.userId}`);
        break;
    }
  }

  openPage(page) {
    this.dismiss();
    this.navCtrl.navigateForward(page);
  }

  async promptCall() {
    const alert = await this.alertCtrl.create({
      header: 'Log unanswered call?',
      message: 'Log a call that was attempted but the individual did not answer. '
        + ' After 3 failed attempts, they will be removed from the list.',
      cssClass: 'wide-alert',
      inputs: [
        {
          type: 'text',
          placeholder: 'Add a note',
          name: 'wlNotes'
        }
      ],
      buttons: [
        {
          text: 'Go back',
          cssClass: 'button-color-secondary',
          role: 'cancel'
        },
        {
          text: 'Log call',
          handler: (data) => {
            this.setCall(data.wlNotes);
          }
        },
      ]
    });

    await alert.present();
  }

  async setCall(wlNotes) {

    let newStatus = 'CALLED1X';
    switch (this.guest.wlStatus) {
      case 'NEW':
        newStatus = 'CALLED1X';
        break;

      case 'CALLED1X':
        newStatus = 'CALLED2X';
        break;

      case 'CALLED2X':
        newStatus = 'CALLED3X';
        break;
    }

    this.guest.ondWaitList = (newStatus === 'CALLED3X') ? 0 : 1;
    await this.guestsService.update(this.guest).toPromise();

    // Set new status and notes
    this.guest.wlStatus = newStatus;
    this.guest.wlNotes = (moment().format('YYYY-MM-DD HH:mm') + ' - ' + this.authUser.userId
      + ' - Call: ' + wlNotes + '\n' + this.guest.wlNotes).trim();
    const updateRes = await this.scheduleService.updateWaitListStatus(this.guest).toPromise();
    this.analytics.clickEvent('WaitListEdit: ' + newStatus, this.guest.userId);
    this.dismiss(true);
  }

  async promptRemoval() {
    const alert = await this.alertCtrl.create({
      header: 'Remove from Waitlist?',
      message: 'You are about to remove this person from the On Demand Waitlist.',
      cssClass: 'wide-alert',
      inputs: [
        {
          type: 'text',
          placeholder: 'Why are you removing this person?',
          name: 'wlNotes'
        }
      ],
      buttons: [
        {
          text: 'Go back',
          cssClass: 'button-color-secondary',
          role: 'cancel'
        },
        {
          text: 'Remove',
          cssClass: 'button-color-danger',
          handler: (data) => {
            this.removeFromWaitlist(data.wlNotes);
          }
        },
      ]
    });

    await alert.present();
  }

  async removeFromWaitlist(wlNotes) {
    this.guest.ondWaitList = 0;
    await this.guestsService.update(this.guest).toPromise();

    // Set new status and notes
    this.guest.wlStatus = 'REMOVED';
    this.guest.wlNotes = (moment().format('YYYY-MM-DD HH:mm') + ' - ' + this.authUser.userId
      + ' - Removed: ' + wlNotes + '\n' + this.guest.wlNotes).trim();
    const updateRes = await this.scheduleService.updateWaitListStatus(this.guest).toPromise();
    this.analytics.clickEvent('WaitListEdit: Removed', this.guest.userId);
    this.dismiss(true);
  }



}

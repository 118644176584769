import { AdPopUp } from 'src/app/models/ad-popup';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-covid-vaccine-ad',
  templateUrl: './covid-vaccine-ad.page.html',
  styleUrls: ['./covid-vaccine-ad.page.scss'],
})
export class CovidVaccineAdPage implements OnInit {
  @Input() ad: AdPopUp;
  headingText = 'Get your COVID-19 Vaccine';
  subheadingText = 'Schedule your vaccine now.';
  imageUrl = '/assets/vaccine-ad.png';

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
    this.analytics.clickEvent('COVID-Vaccine-Ad: Viewed:', '');
    if (this.ad.adName === 'schedule-covid-vaccine-booster') {
      this.headingText = 'Get your COVID-19 Vaccine Booster';
      this.imageUrl = '/assets/vaccine-ad.png';
    }
  }


  dismiss(returnValue) {
    this.analytics.clickEvent('COVID-Vaccine-Ad: Action:', returnValue);
    this.modalCtrl.dismiss(returnValue);
  }

}

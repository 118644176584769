import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bh-visual-banner',
  templateUrl: './bh-visual-banner.component.html',
  styleUrls: ['./bh-visual-banner.component.scss'],
})
export class BhVisualBannerComponent implements OnInit {
  @Input() heading: string;
  @Input() message: string;
  @Input() iconUrl: string;
  @Input() ctaLabel: string;
  @Output() clickEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  clickCta() {
    this.clickEvent.emit(true);
  }

}

import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { User } from 'src/app/models/user';
import { Profile } from 'src/app/models/profile';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UsersService } from 'src/app/services/users/users.service';
import { Preference } from 'src/app/models/preference';

@Component({
  selector: 'app-assignment-text-ad',
  templateUrl: './assignment-text-ad.page.html',
  styleUrls: ['./assignment-text-ad.page.scss'],
})
export class AssignmentTextAdPage implements OnInit {
  authUser: User;
  profile: Profile;
  submitAttempted = false;
  profileForm: FormGroup = this.formBuilder.group({
    callbackPhone: [
      '',
      [Validators.required, Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)],
    ],
  });
  validationMessages = {
    callbackPhone: [
      // { type: 'required', message: 'Contact Phone is required.' },
      { type: 'pattern', message: 'Use the phone number format: XXX-XXX-XXXX' },
    ],
  };

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private profileService: ProfileService,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private usersService: UsersService
  ) { }

  ngOnInit() {
    this.loadProfileData();
    this.analytics.clickEvent('Assignment-Text-Ad: Viewed:', '');
  }

  loadProfileData() {
    this.profile = this.profileService.getProfile();
    this.authUser = this.authService.getAuthUser();
  }

  async textAssignments() {
    //update assignment notification preferences
    if (this.profileForm.valid) {
      this.analytics.clickEvent('assignment text', '');
      this.notificationsService.startLoading('Saving preferences');
      this.authUser.callbackPhone = this.profileForm.controls.callbackPhone.value;
      for (const p of this.authUser.preferences) {
        if (p.prefType === 'CRTEXTSTATUS') {
          p.prefValue = 'Y';
        }
        if (p.prefType === 'CRALLOWTEXTS') {
          p.prefValue = 'Y';
        }
      }
      // console.log('preferences', this.authUser.preferences);
      try {
        const userRes = await this.usersService.update(this.authUser).toPromise();
        await this.profileService.loadProfile(this.authUser.userId, 'self', 'Saving preferences');
        this.notificationsService.stopLoading();
        // this.notificationsService.showAlert('Thank you', 'Profile successfully saved');
        this.modalCtrl.dismiss('read');
      } catch (err) {
        this.notificationsService.stopLoading();
        this.notificationsService.handleError(err, 'Profile: save()');
        this.dismiss('read');
      }
    } else {
      this.notificationsService.showAlert(
        '',
        'Complete all required fields marked in red.'
      );
    }
  }

  async dismiss(returnValue) {
    this.analytics.clickEvent('Assignment-Text-Ad: Action:', returnValue);
    for (const p of this.authUser.preferences) {
      if (p.prefType === 'CRTEXTSTATUS') {
        p.prefValue = 'N';
      }
      if (p.prefType === 'CRALLOWTEXTS') {
        p.prefValue = 'N';
      }
    }
    try {
      const userRes = await this.usersService.update(this.authUser).toPromise();
      this.profileService.loadProfile(this.authUser.userId, 'self', 'Updating profile');
      this.notificationsService.stopLoading();
      // this.notificationsService.showAlert('Thank you', 'Profile successfully saved');

    } catch (err) {
      this.notificationsService.stopLoading();
      this.notificationsService.handleError(err, 'Profile: save()');
    }
    console.log('Dismissing');
    this.modalCtrl.dismiss(returnValue);
  }
}

import { VerlockerService } from 'src/app/services/_core/verlocker/verlocker.service';
import { environment } from '../../../../environments/environment.dev';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { MenuController, PopoverController, NavController } from '@ionic/angular';
import { UserMenuPopoverPage } from 'src/app/pages/_core/user-menu-popover/user-menu-popover.page';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { VerlockerData } from 'src/app/models/_core/verlocker-data';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Profile } from 'src/app/models/profile';

/**
 * ID: bh-app-header-mobile
 * Name: BH Mobile Header
 * Description: Displays mobile-specific header
 * Version: 3
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented bh-app-logo to replace static version
 * 2022-05-24 - MW - v3: Made app-name clickable to return Home
 */
@Component({
  selector: 'bh-app-header-mobile',
  templateUrl: './bh-app-header-mobile.component.html',
  styleUrls: ['./bh-app-header-mobile.component.scss'],
})
export class BhAppHeaderMobileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() color = '';
  @Input() subheading = '';
  @Input() transparent = false;
  @Input() tilt = false;
  @Input() breadcrumbs: Breadcrumb[] = [];
  env = environment;
  authUser: User;
  subscriptions: Subscription[] = [];
  showUpdateIcon = false;
  showBackButton = false;
  rootBreadcrumb: Breadcrumb = null;
  backBreadcrumb: Breadcrumb = null;
  appLogoColor = this.color ? this.color : 'var(--font-color)';
  profileSub: Subscription = null;
  profile: Profile;
  showNotificationBadge = false;

  constructor(
    private popoverCtrl: PopoverController,
    public authService: AuthService,
    private analytics: BhAnalyticsService,
    private verlocker: VerlockerService,
    public navService: NavigationService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.subscribeToProfile();
    this.subscribeToFeeds();
    this.setFirstBreadcrumb();
    this.getLastBreadcrumb();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.appLogoColor = this.color ? this.color : 'var(--font-color)';
  }

  ngOnDestroy() {
    this.unsubcribeFromFeeds();
  }

  subscribeToFeeds() {
    // Subscribe to user
    this.subscriptions.push(
      this.authService.authUser.subscribe(val => this.authUser = val)
    );

    // Subscribe to verlocker app-version checking
    this.verlocker.verlockerDataSubject.subscribe((val: VerlockerData) => {
      this.showUpdateIcon = (val.verstatus === 'REQUESTED');
    });

    // Subscribe to unread comments
    this.subscriptions.push(
    );
  }

  unsubcribeFromFeeds() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  subscribeToProfile() {
    if (!this.profileSub) {
      this.profileSub = this.profileService.profileSubject.subscribe((data) => {
        this.profile = data;
        this.showNotificationBadge = this.profile && this.profile.checklist && this.profile.checklist.filter(i => !i.isComplete).length > 0;
      });
    } else {
      console.log('Profile sub already subscribed');
    }
  }

  async openNotifications(ev) {
    this.analytics.clickEvent('mobile-header: open notifications', '');
    this.navService.navigateForward('/tabs/notifications');
  }

  goHome() {
    this.navService.navigateHome();
  }

  openUpdates(ev) {
    this.navService.navigateForward('check-for-updates');
  }

  async openUserMenu(ev) {
    const popover = await this.popoverCtrl.create({
      component: UserMenuPopoverPage,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }
  setFirstBreadcrumb() {
    if (!this.rootBreadcrumb) {
      const authUser = this.authService.getAuthUser();
      const breadcrumb: Breadcrumb = { direction: 'back', isRoot: true };
      if (authUser && authUser.userId) {
        breadcrumb.label = 'Home';
        breadcrumb.url = '/tabs/home';
      } else {
        breadcrumb.label = 'Sign in';
        breadcrumb.url = '/login';
      }
      this.rootBreadcrumb = breadcrumb;
      // this.breadcrumbs.unshift(breadcrumb);
    }
  }

  getLastBreadcrumb() {
    if (this.breadcrumbs && this.breadcrumbs.filter(b => b.url).length > 0) {
      // console.log('Processing breadcrumbs', this.breadcrumbs);
      const lastBreadcrumb = this.breadcrumbs[this.breadcrumbs.filter(b => b.url).length - 1];
      if (!lastBreadcrumb.isRoot && lastBreadcrumb.url) {
        this.backBreadcrumb = lastBreadcrumb;
        this.showBackButton = true;
      }
      // for (let i = (this.breadcrumbs.length - 1); i > -1; i--) {
      //   const breadcrumb = this.breadcrumbs[i];
      //   if (!breadcrumb.isRoot && breadcrumb.url) {
      //     this.showBackButton = true;
      //     this.backBreadcrumb = breadcrumb;
      //   }
      // }

      if (!this.backBreadcrumb) {
        this.showBackButton = true;
        this.backBreadcrumb = this.rootBreadcrumb;
      }
    } else {
      this.showBackButton = true;
      this.backBreadcrumb = this.rootBreadcrumb;
    }
  }

  back() {
    if (this.backBreadcrumb) {
      this.navService.navigateBack(this.backBreadcrumb.url);
    }
  }


}

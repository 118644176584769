import { BhAppLogoComponentModule } from '../bh-app-logo/bh-app-logo.component.module';
import { BhUserBadgeComponentModule } from '../bh-user-badge/bh-user-badge.component.module';
import { BhLogoComponentModule } from '../bh-logo/bh-logo.component.module';
import { BhAppHeaderMobileComponent } from './bh-app-header-mobile.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BhAppHeaderMobileComponent
  ],
  imports: [
    BhAppLogoComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    BhLogoComponentModule,
    BhUserBadgeComponentModule,
  ],
  exports: [
    BhAppHeaderMobileComponent
  ]
})
export class BhAppHeaderMobileComponentModule { }

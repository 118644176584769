import { RemindersAdMonkeypoxPageModule } from './pages/reminders-ad-monkeypox/reminders-ad-monkeypox.module';
import { WelcomePageModule } from './pages/welcome/welcome.module';
/* eslint-disable max-len */
import { CovidVaccineFeedbackModalPageModule } from './pages/covid-vaccine-feedback-modal/covid-vaccine-feedback-modal.module';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { TouchID } from '@awesome-cordova-plugins/touch-id/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { VerlockerPageModule } from './pages/_core/verlocker/verlocker.module';
import { InterceptorService } from './services/_core/interceptor/interceptor.service';
import { UserMenuPopoverPageModule } from './pages/_core/user-menu-popover/user-menu-popover.module';
import { DatepickerModalPageModule } from './pages/datepicker-modal/datepicker-modal.module';
import { CovidTestResultPageModule } from './pages/covid-test-result/covid-test-result.module';
import { CovidVaccineAdPageModule } from './pages/covid-vaccine-ad/covid-vaccine-ad.module';
import { CallbackPhoneAdPageModule } from './pages/callback-phone-ad/callback-phone-ad.module';
import { CovidTestResultAdPageModule } from './pages/covid-test-result-ad/covid-test-result-ad.module';
import { AppointmentReminderAdPageModule } from './pages/appointment-reminder-ad/appointment-reminder-ad.module';
import { GuestCovidVaccineRegisterPageModule } from './pages/guest-covid-vaccine-register/guest-covid-vaccine-register.module';
import { GuestCovidVaccineResendLinkPageModule } from './pages/guest-covid-vaccine-resend-link/guest-covid-vaccine-resend-link.module';
import { ManageQuestionsEditAnswersPageModule } from './pages/manage-questions-edit-answers/manage-questions-edit-answers.module';
import { ManageQuestionsEditAnswersAddPageModule } from './pages/manage-questions-edit-answers-add/manage-questions-edit-answers-add.module';
import { ManagePeopleApptsVaccineHistoryPageModule } from './pages/manage-people-appts-vaccine-history/manage-people-appts-vaccine-history.module';
import { ManageGuestWaitlistEditPageModule } from './pages/manage-guest-waitlist-edit/manage-guest-waitlist-edit.module';
import { ManageMessagingEditPageModule } from './pages/manage-messaging-edit/manage-messaging-edit.module';
import { ManageMessagingPopupPageModule } from './pages/manage-messaging-popup/manage-messaging-popup.module';
import { ManageHelpDocsPageModule } from './pages/manage-help-docs/manage-help-docs.module';
import { ManageGuestRegisterPageModule } from './pages/manage-guest-register/manage-guest-register.module';
import { ManagePeopleApptsEditPageModule } from './pages/manage-people-appts-edit/manage-people-appts-edit.module';
import { ManagePeopleApptsConsentPageModule } from './pages/manage-people-appts-consent/manage-people-appts-consent.module';
import { ManagePeopleApptsScreeningPageModule } from './pages/manage-people-appts-screening/manage-people-appts-screening.module';
import { SchLocationDatetimeModalPageModule } from './pages/scheduler/sch-location-datetime-modal/sch-location-datetime-modal.module';
import { RemindersAdPageModule } from './pages/reminders-ad/reminders-ad.module';
import { WelcomeWhatsNewPageModule } from './pages/welcome-whats-new/welcome-whats-new.module';
import { TestingPromptPageModule } from './pages/testing-prompt/testing-prompt.module';
import { AssignmentTextAdPageModule } from './pages/assignment-text-ad/assignment-text-ad.module';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    VerlockerPageModule,
    UserMenuPopoverPageModule,
    AppointmentReminderAdPageModule,
    AssignmentTextAdPageModule,
    CallbackPhoneAdPageModule,
    CovidTestResultAdPageModule,
    CovidTestResultPageModule,
    CovidVaccineAdPageModule,
    CovidVaccineFeedbackModalPageModule,
    DatepickerModalPageModule,
    GuestCovidVaccineRegisterPageModule,
    GuestCovidVaccineResendLinkPageModule,
    ManageGuestRegisterPageModule,
    ManageGuestWaitlistEditPageModule,
    ManageHelpDocsPageModule,
    ManageMessagingEditPageModule,
    ManageMessagingPopupPageModule,
    ManagePeopleApptsConsentPageModule,
    ManagePeopleApptsEditPageModule,
    ManagePeopleApptsScreeningPageModule,
    ManagePeopleApptsVaccineHistoryPageModule,
    ManageQuestionsEditAnswersPageModule,
    ManageQuestionsEditAnswersAddPageModule,
    RemindersAdPageModule,
    RemindersAdMonkeypoxPageModule,
    SchLocationDatetimeModalPageModule,
    WelcomePageModule,
    WelcomeWhatsNewPageModule,
    TestingPromptPageModule
  ],
  providers: [
    AES256,
    StatusBar,
    Device,
    InAppBrowser,
    Keyboard,
    LocalNotifications,
    OpenNativeSettings,
    Network,
    // PinCheck, -- Not Supported in Ionic 6
    TouchID,
    LoginRouteGuardService,
    BhAnalyticsRouteGuardService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

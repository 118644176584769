import { BhEmptyMessageComponentModule } from './../_core/bh-empty-message/bh-empty-message.component.module';
import { BhAppointmentCardComponent } from './bh-appointment-card.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BhAppointmentCardComponent
    ],
  imports: [
    BhEmptyMessageComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    BhAppointmentCardComponent
  ]
})
export class BhAppointmentCardComponentModule { }

import { BhTimeslotPickerComponentModule } from './../../../components/bh-timeslot-picker/bh-timeslot-picker.component.module';
import { BhWorkflowButtonsComponentModule } from 'src/app/components/bh-workflow-buttons/bh-workflow-buttons.component.module';
import { BhCalendarComponentModule } from './../../../components/bh-calendar/bh-calendar.component.module';
import { BhLocationPickerComponentModule } from './../../../components/bh-location-picker/bh-location-picker.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SchLocationDatetimeModalPage } from './sch-location-datetime-modal.page';
import { SchLocationDatetimeModalPageRoutingModule } from './sch-location-datetime-modal-routing.module';

@NgModule({
  imports: [
    BhLocationPickerComponentModule,
    BhTimeslotPickerComponentModule,
    BhCalendarComponentModule,
    BhWorkflowButtonsComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    SchLocationDatetimeModalPageRoutingModule
  ],
  declarations: [SchLocationDatetimeModalPage]
})
export class SchLocationDatetimeModalPageModule {}

import { SchApplication } from './../../models/sch-application';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { User } from 'src/app/models/user';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { CampaignAvailability, CampaignVaccineAvailabilityResult } from './../../models/campaign-availability';
import { StorageService } from './../../services/_core/storage/storage.service';
import { NotificationsService } from './../../services/_core/notifications/notifications.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchLocation } from 'src/app/models/sch-location';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';

@Component({
  selector: 'bh-location-picker',
  templateUrl: './bh-location-picker.component.html',
  styleUrls: ['./bh-location-picker.component.scss'],
})
export class BhLocationPickerComponent implements OnChanges, OnDestroy {
  @Input() schApplication: SchApplication = null;
  @Input() appointment = null; // Delete
  @Output() selectLocationEvent = new EventEmitter();
  submitAttempted = false;
  form1: FormGroup = this.formBuilder.group({
    location: ['', Validators.required],
  });
  showErrorMessage = false;
  locations: SchLocation[];
  displayLocations: SchLocation[];
  selectedLocationId: number;
  selectedLocation: SchLocation;
  locationControlSub: Subscription = null;
  isSupportMode = false;
  singleOptionMode = false;
  isSearching = false;
  campAvailability: CampaignAvailability[] = [];
  currentView: 'loading' | 'single-select' | 'multi-select' | 'no-vaccine' | 'error' = 'loading';
  noVaccineText = '';
  isGuest = false;
  authUser: User = null;
  vaccineAvailResult: CampaignVaccineAvailabilityResult;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private schedulerService: SchedulerService,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService,
    private messagingService: MessagingService,
    private authService: AuthService
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      ('application' in changes || 'schApplication' in changes) &&
      this.schApplication !== undefined && this.schApplication
    ) {
      this.isSupportMode = await this.schedulerService.isUsingSupportMode();
      this.loadData();
    }
  }

  ngOnDestroy() {
    if (this.locationControlSub) {
      this.locationControlSub.unsubscribe();
      this.locationControlSub = null;
    }
  }

  checkForGuest() {
    console.log('checkForGuest', this.schedulerService.appointment);
    this.isGuest = (this.schedulerService.appointment.userId.substr(0, 2).trim().toLowerCase() === 'ww');
  }

  async loadData() {
    try {
      if (
        this.schedulerService.appointment &&
        this.schedulerService.appointment.vaccine &&
        this.schedulerService.appointment.vaccine.locations
      ) {
        this.checkForGuest();
        const campaign = this.schedulerService.appointment.campaign;
        const authUser = this.authService.getAuthUser();
        const age = (this.isGuest && authUser.age) ? authUser.age : 18;
        const zip = (this.isGuest && authUser.zip) ? authUser.zip : '';
        const noVaccineMsgId = this.isGuest ? 'WWGUESTREGNOVACCREG' : 'WWGUESTREGNOVACC';
        this.noVaccineText = await this.getMessaging(noVaccineMsgId);
        // console.log('bhLocationPicker: campaign: ', campaign);
        // console.log('bhLocationPicker: age: ', age);
        // console.log('bhLocationPicker: zip: ', zip);
        this.vaccineAvailResult =
          await this.schedulerService.getCampaignVaccineAvailability(campaign, true, zip, age, false, true, null);
        if (this.vaccineAvailResult && this.vaccineAvailResult.hasAvailability) {
          this.campAvailability = this.vaccineAvailResult.availability;
          this.schedulerService.appointment.vaccine.locations.forEach(l => this.getLocationVlaInfo(l, this.vaccineAvailResult));
          this.locations = this.schedulerService.appointment.vaccine.locations.filter(
            (l: SchLocation) =>
              l.vlaActive === 1 &&
              l.active === 1 &&
              this.hasLocationAvailabilty(l.locSeq) &&
              (l.vlaSuspend !== 1 || (l.vlaSuspend === 1 && this.isSupportMode))
          );
          this.displayLocations = this.locations;
          // console.log('displayLocations: ', this.displayLocations);
          if (this.displayLocations && this.displayLocations.length === 1) {
            this.selectedLocation = this.displayLocations[0];
            this.form1.controls.location.setValue(this.selectedLocation);
            this.singleOptionMode = true;
            this.selectLocationEvent.emit(this.selectedLocation);
            // console.log('Selected location', this.selectedLocation);
          }
          this.locationControlSub =
            this.form1.controls.location.valueChanges.subscribe((val) => {
              this.selectLocationEvent.emit(val);
            });

          // Set view
          if (this.singleOptionMode === false) {
            this.currentView = 'multi-select';
          } else if (this.displayLocations.length === 1 && this.singleOptionMode === true) {
            this.currentView = 'single-select';
          } else if (this.displayLocations.length === 0) {
            this.currentView = 'no-vaccine';
          }
        } else {
          // this.notifications.showAlert('There are no locations available for this vaccine.');
          this.selectLocationEvent.emit(null);
          this.currentView = 'no-vaccine';
          console.log('There are no locations available for this vaccine.');
        }
      } else {
        this.selectLocationEvent.emit(null);
        this.currentView = 'no-vaccine';
        console.log('There is no availability for this vaccine.');
      }
    } catch (err) {
      this.currentView = 'error';
      console.error('An error occurred', err);
    }
  }

  searchLocations(val) {
    this.isSearching = (val && val !== '');
    this.selectedLocationId = null;
    const valLowerCase = val.toLowerCase().trim();
    this.displayLocations = this.locations.filter((loc: SchLocation) => (
        loc.name.toLowerCase().trim().indexOf(valLowerCase) > -1 ||
        loc.description.toLowerCase().trim().indexOf(valLowerCase) > -1 ||
        loc.address1.toLowerCase().trim().indexOf(valLowerCase) > -1 ||
        loc.city.toLowerCase().trim().indexOf(valLowerCase) > -1 ||
        loc.state.toLowerCase().trim().indexOf(valLowerCase) > -1 ||
        loc.zip.toLowerCase().trim().indexOf(valLowerCase) > -1
      ));
    this.analytics.clickEvent('LocationPicker: Search', '\'' + val + '\' returned results: ' + this.displayLocations.length);
  }

  getLocationVlaInfo(loc: SchLocation, vaccineAvailResult: CampaignVaccineAvailabilityResult) {
    const vLoc = vaccineAvailResult.availableLocations.filter(l => l.locSeq.toString() === loc.locSeq.toString())[0];
    if (vLoc) {
      loc.vlaActive = vLoc.vlaActive;
      loc.vlaSuspend = vLoc.vlaSuspend;
      loc.vlaSuspendText = vLoc.vlaSuspendText;
    } else {
      console.log('No matching vLocs found');
    }
  }

  hasLocationAvailabilty(locSeq): boolean {
    const vaccine = this.schedulerService.appointment.vaccine;
    const locAvailability = this.campAvailability.filter((l: CampaignAvailability) => (
        l.locSeq.toString() === locSeq.toString() &&
        l.hasAvailability === 1 &&
        l.vacSeq.toString() === vaccine.vacSeq.toString()
      ));
    return locAvailability.length > 0;
  }

  async getMessaging(messageId): Promise<string> {
    try {
      const appRes = await this.messagingService.getByIdAndLanguage(
        this.schApplication.application,
        messageId
      ).toPromise();
      // console.log('appRes for getMessaging()', appRes);
      const msgText = (appRes.msg) ? appRes.msg.text : null;
      return Promise.resolve(msgText);
    } catch (err) {
      console.error('Error occurred while loading messaging', err);
      this.currentView = 'error';
    }
  }

  openGoogleMaps(link) {
    this.analytics.clickEvent('LocationPicker: Directions and Map', link);
    window.open(link, '_system');
  }

  reload() {
    window.location.reload();
  }

}

import { BhValidationErrorComponentModule } from 'src/app/components/_core/bh-validation-error/bh-validation-error.component.module';
import { BhPasswordProtectionComponent } from './bh-password-protection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BhPasswordProtectionComponent
    ],
  imports: [
    BhValidationErrorComponentModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    BhPasswordProtectionComponent
  ]
})
export class BhPasswordProtectionComponentModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManageMessagingEditPage } from './manage-messaging-edit.page';
import { ManageMessagingEditPageRoutingModule } from './manage-messaging-edit-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ManageMessagingEditPageRoutingModule,
    // QuillModule.forRoot({
    //   modules: {
    //     syntax: false,
    //     toolbar: [
    //       ['bold', 'italic', 'underline', 'strike', 'link'],    // toggled buttons
    //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
    //       [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
    //       ['blockquote'],
    //       [{ list: 'ordered' }, { list: 'bullet' }],
    //       [{ script: 'sub' }, { script: 'super' }],     // superscript/subscript
    //       [{ indent: '-1' }, { indent: '+1' }],         // outdent/indent
    //       [{ direction: 'rtl' }, { align: [] }],        // text direction
    //       ['clean'],                                    // remove formatting button
    //     ]
    //   },
    // }),

  ],
  declarations: [ManageMessagingEditPage]
})
export class ManageMessagingEditPageModule {}

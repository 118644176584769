import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bh-ad-popup',
  templateUrl: './bh-ad-popup.component.html',
  styleUrls: ['./bh-ad-popup.component.scss'],
})
export class BhAdPopupComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() imagePosX = 'center';
  @Input() imagePosY = 'center';
  @Input() imageSize = 'cover';
  @Input() imageContainerHeight = '230px';
  @Input() imageContainerMargin = '0 0 32px 0';
  @Input() showCloseButton = true;
  @Output() dismissEvent = new EventEmitter(false);

  constructor() { }

  ngOnInit() {
    // console.log('Ad Component initialized', this.heading);
  }

  dismiss() {
    this.dismissEvent.emit(true);
  }

}

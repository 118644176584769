import { GuestCovidVaccineRegisterPageRoutingModule } from './guest-covid-vaccine-register-routing.module';
import { BhInputChecklistComponentModule } from 'src/app/components/bh-input-checklist/bh-input-checklist.component.module';
import { BhAppVersionComponentModule } from './../../components/_core/bh-app-version/bh-app-version.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { GuestCovidVaccineRegisterPage } from './guest-covid-vaccine-register.page';
import { BhAppointmentCardComponentModule } from 'src/app/components/bh-appointment-card/bh-appointment-card.component.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhLogoComponentModule } from 'src/app/components/_core/bh-logo/bh-logo.component.module';
import { BhMessageBannerComponentModule } from 'src/app/components/_core/bh-message-banner/bh-message-banner.component.module';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { BhValidationErrorComponentModule } from 'src/app/components/_core/bh-validation-error/bh-validation-error.component.module';

@NgModule({
  imports: [
    BhAppVersionComponentModule,
    BhSpinnerComponentModule,
    BhAppointmentCardComponentModule,
    BhMessageBannerComponentModule,
    BhLogoComponentModule,
    BhInputComponentModule,
    BhInputChecklistComponentModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    GuestCovidVaccineRegisterPageRoutingModule
  ],
  declarations: [GuestCovidVaccineRegisterPage]
})
export class GuestCovidVaccineRegisterPageModule {}

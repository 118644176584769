import { LocalNotificationMessage } from './../../models/local-notification-message';
import { AdPopUp } from 'src/app/models/ad-popup';
import { AdPopupService } from './../ad-popup/ad-popup.service';
import { Preference } from './../../models/preference';
import { Injectable } from '@angular/core';
import { ILocalNotificationActionType, LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Platform } from '@ionic/angular';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {

  // Message formats
  covidLocalNotification: LocalNotificationMessage = {
    title: 'WorkWell',
    body: 'Take your Daily Wellness Screening.'
  };
  mpLocalNotification1: LocalNotificationMessage = {
    title: 'WorkWell',
    body: 'Take today\'s first Monkeypox Screening.'
  };
  mpLocalNotification2: LocalNotificationMessage = {
    title: 'WorkWell',
    body: 'Take today\'s second Monkeypox Screening.'
  };

  constructor(
    private localNotifications: LocalNotifications,
    private platform: Platform,
    private adPopupService: AdPopupService,
    private nativeSettings: OpenNativeSettings
  ) { }

  async checkForNotificationsEnabled(): Promise<boolean> {
    if (this.platform.is('cordova')) {
      try {
        const hasPermission = await this.localNotifications.hasPermission();
        return Promise.resolve(hasPermission);
      } catch (err) {
        console.error('Error: ', err);
      }
    } else {
      return Promise.resolve(false);
    }
  }

  async openNotificationSettings() {
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      await this.nativeSettings.open('notification_id');
    }
  }

  updateLocalNotifications(hrs: number, min: number, prefs: Preference[], prefix, notificationMessage: LocalNotificationMessage) {
    // Check for Cordova app
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      // Check for requestPermission
      this.localNotifications.hasPermission().then((granted) => {
        // Check for granted privilege
        if (granted) {
          this.scheduleNotificationSeries(hrs, min, prefs, prefix, notificationMessage);
        } else {
          this.localNotifications.requestPermission().then((permGranted) => {
            // Check for granted privilege
            if (permGranted) {
              this.scheduleNotificationSeries(hrs, min, prefs, prefix, notificationMessage);
            }
          });
        }
      });
    }
  }

  clearLocalNotifications(prefix: string) {
    // Check for Cordova app
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      // Check for requestPermission
      this.localNotifications.hasPermission().then(async (granted) => {
        // Check for granted privilege
        if (granted) {
          const schedIds = await this.localNotifications.getScheduledIds();
          for (const id of schedIds) {
            if (prefix === 'MP' && id >= 1000) {
              console.log('Cancelling MP notification: ' + id);
              await this.localNotifications.cancel(id);
            } else {
              console.log('Cancelling COVID notification: ' + id);
              await this.localNotifications.cancel(id);
            }
          }
          // this.localNotifications.cancelAll().then((val) => {
          //   this.localNotifications.clearAll().then((clearVal) => {
          //   });
          // });
        }
      });
    }
  }

  scheduleNotificationSeries(hrs, min, prefs: Preference[], prefix: string, notificationMessage: LocalNotificationMessage) {
    let prefixCode = prefix.toUpperCase();
    switch (prefixCode) {
      case 'MP2':
        prefixCode = 'MP';
        break;
    }
    // Clear existing notifications
    this.localNotifications.cancelAll().then((val) => {
      this.localNotifications.clearAll().then((clearVal) => {

        // Get existing IDs  (if any)
        this.localNotifications.getIds().then((idVal) => {

          // Schedule each enabled value
          prefs.forEach((pref: Preference) => {
            if (pref.prefType === prefixCode + 'REMSUN' && pref.prefValue === 'Y') {
              this.scheduleNotification(0, hrs, min, prefix, notificationMessage);
            }

            if (pref.prefType === prefixCode + 'REMMON' && pref.prefValue === 'Y') {
              this.scheduleNotification(1, hrs, min, prefix, notificationMessage);
            }

            if (pref.prefType === prefixCode + 'REMTUE' && pref.prefValue === 'Y') {
              this.scheduleNotification(2, hrs, min, prefix, notificationMessage);
            }

            if (pref.prefType === prefixCode + 'REMWED' && pref.prefValue === 'Y') {
              this.scheduleNotification(3, hrs, min, prefix, notificationMessage);
            }

            if (pref.prefType === prefixCode + 'REMTHU' && pref.prefValue === 'Y') {
              this.scheduleNotification(4, hrs, min, prefix, notificationMessage);
            }

            if (pref.prefType === prefixCode + 'REMFRI' && pref.prefValue === 'Y') {
              this.scheduleNotification(5, hrs, min, prefix, notificationMessage);
            }

            if (pref.prefType === prefixCode + 'REMSAT' && pref.prefValue === 'Y') {
              this.scheduleNotification(6, hrs, min, prefix, notificationMessage);
            }
          });

          setTimeout(() => {
            this.localNotifications.getIds().then((confVal) => {
            });
          }, 1000);

        });
      });
    });

  }

  scheduleNotification(day, hrs, min, id, notificationMessage: LocalNotificationMessage) {
    console.log('scheduleNotification: scheduling ' + id, day, hrs, min, notificationMessage);
    this.localNotifications.schedule({
      id: this.setLocalNotificationId(id, day),
      // sound: 'file://assets/alert-sound.mp3',
      // BH: Please take your wellness screening now.
      title: notificationMessage.title,
      text: notificationMessage.body,
      foreground: true,
      launch: true,
      lockscreen: true,
      vibrate: true,
      trigger: { every: { weekday: day, hour: hrs, minute: min }, count: 1 },
      actions: [
        {
          id: 'take',
          type: ILocalNotificationActionType.BUTTON,
          title: 'Take Screening'
        }
      ]
    });
  }

  setLocalNotificationId(id: string, day: number) {
    let idCode = day;
    switch (id.toLowerCase()) {
      case 'mp':
        idCode = Number('100' + day);
        break;

      case 'mp2':
        idCode = Number('200' + day);
        break;
    }
    return idCode;
  }


  // updateNotification(day, hrs, min) {
  //   this.localNotifications.update({
  //     id: day,
  //     title: 'BH: Please take your wellness screening now.',
  //     sound: 'file://assets/alert-sound.mp3',
  //     trigger: { every: { weekday: day, hour: hrs, minute: min }, count: 1 }
  //   });
  // }

  async presentFirstTimeRemindersSetup() {
    // Check for Cordova app
    if (this.platform.is('cordova')) {

      // Check for requestPermission
      await this.localNotifications.requestPermission().then(async (granted) => {
        // console.log('isGranted', granted);

        // Check for granted privilege
        if (granted) {
          // const modal = await this.modalController.create({
          //   component: WelcomePage,
          //   backdropDismiss: false
          // });
          // await modal.present();
          const remindersAd: AdPopUp = {
            adName: 'reminders',
            status: 'show',
            order: 2
          };
          this.adPopupService.addAd(remindersAd);
        }
      });
    }
  }

}

import { BhMessageBannerComponentModule } from './../_core/bh-message-banner/bh-message-banner.component.module';
import { BhTimechipsComponentModule } from './../bh-timechips/bh-timechips.component.module';
import { BhTimeslotPickerComponent } from './bh-timeslot-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        BhTimeslotPickerComponent
    ],
    imports: [
        BhMessageBannerComponentModule,
        BhTimechipsComponentModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ],
    exports: [
        BhTimeslotPickerComponent
    ]
})
export class BhTimeslotPickerComponentModule { }

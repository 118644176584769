import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonKeysPipe'
})
export class JsonKeysPipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const keys = [];
    for (const key of value) {
      keys.push({ key, value: value[key] });
    }
    return keys;
  }

}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectOption } from 'src/app/models/_core/select-option';

@Component({
  selector: 'bh-input-checklist',
  templateUrl: './bh-input-checklist.component.html',
  styleUrls: ['./bh-input-checklist.component.scss'],
})
export class BhInputChecklistComponent implements OnInit {
  @Input() label = '';
  @Input() value = [];
  @Input() required = false;
  @Input() selectOptions: SelectOption[] = [];
  @Input() noIndent = false;
  @Input() addMarginBottom = true;
  @Input() showNoneOfTheAbove = false;
  @Output() valueEvent = new EventEmitter();
  noneOfTheAbove = false;

  constructor() { }

  ngOnInit() { }

  updateChecklistValue() {
    const value = this.selectOptions.filter(a => a.value);
    if (value.length > 0) {
      this.noneOfTheAbove = false;
    }
    this.valueEvent.emit(value);
  }

  updateNoneOfTheAbove() {
    // console.log('NoneOfTheAbove value updated', this.noneOfTheAbove);
    if (this.noneOfTheAbove) {
      for (const v of this.selectOptions) {
        v.value = false;
        // console.log('Cleared value', v);
      }
      this.valueEvent.emit([{ description: 'None of the above', value: true, checkValue: 'None of the above' }]);
    }
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'bh-password-protection',
  templateUrl: './bh-password-protection.component.html',
  styleUrls: ['./bh-password-protection.component.scss'],
})
export class BhPasswordProtectionComponent implements OnInit {
  @Input() purposeDescription = '';
  @Output() authenticatedEvent = new EventEmitter<boolean>();
  @Output() backEvent = new EventEmitter<boolean>();
  isAuthenticated = false;
  submitAttempted = false;
  form1: FormGroup = this.formBuilder.group({
    password: ['', Validators.required],
  });
  showErrorMessage = false;
  validationMessages = {
    password: [{ type: 'required', message: 'Password is required.' }],
  };

  constructor(
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private analytics: BhAnalyticsService,
  ) { }

  ngOnInit() { }

  authenticate() {
    if (this.form1.valid) {
      const authUser = this.authService.getAuthUser();
      const userId = authUser.userId;
      const password = this.form1.controls.password.value;
      this.notificationsService.startLoading();
      this.login(userId, password);
    }
  }

  login(userId, password) {
    this.showErrorMessage = false;
    this.authService.login(userId, password, false, false).subscribe(async data => {
      if (data.x_status && data.x_status === 'S') {
        this.isAuthenticated = true;
        this.authenticatedEvent.emit(this.isAuthenticated);
        this.analytics.customEvent(this.purposeDescription, 'SL', 'Successfully logged in');
        this.notificationsService.stopLoading();
      } else {
        this.analytics.customEvent(this.purposeDescription, 'FL', 'Failed to login');
        this.notificationsService.stopLoading();
        this.showErrorMessage = true;
      }
    }, error => {
      this.notificationsService.stopLoading();
      this.notificationsService.handleError(error, 'login()');
    });
  }

  back() {
    this.backEvent.emit(true);
  }
}

import { environment } from 'src/environments/environment';
import { SchAppointment } from './../../models/sch-appointment';
import { SchApplication } from 'src/app/models/sch-application';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { NavController, AlertController } from '@ionic/angular';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// import { AddToCalendarService } from 'src/app/services/add-to-calendar/add-to-calendar.service';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import * as moment from 'moment';
import { SchVaccine } from 'src/app/models/sch-vaccine';

@Component({
  selector: 'bh-appointment-card',
  templateUrl: './bh-appointment-card.component.html',
  styleUrls: ['./bh-appointment-card.component.scss'],
})
export class BhAppointmentCardComponent implements OnChanges {
  @Input() visit;
  @Input() showButtons;
  @Input() showDoseNumbers = false;
  @Input() showScheduleDose = false;
  @Input() schApplicationId: string;
  @Input() cardStyle: 'card' | 'list-item' | 'staged' | 'blend';
  @Input() showViewButton = false;
  env = environment;
  schApplication: SchApplication;
  isGuest = false;
  urlGuestPrefix = '';
  mapUrl = '';
  doseLabel = 'Dose';

  constructor(
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    // private addToCalendarService: AddToCalendarService,
    private schedulerService: SchedulerService,
    private analytics: BhAnalyticsService,
    private authService: AuthService,
    private profileService: ProfileService
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    this.checkForGuest();
    this.doseLabel = this.visit.schApplication.appType === 'V' ? 'Dose' : 'Visit';
  }

  addToCalendar() {
    // this.addToCalendarService.addToCalendar(this.visit);
  }

  checkForGuest() {
    const authUser = this.authService.getAuthUser();
    if (authUser.NBUEmployee) {
      this.isGuest = true;
      this.urlGuestPrefix = '/guest';
    }
  }

  viewAppointment(visit: SchAppointment) {
    this.analytics.clickEvent('ApptCard > View Details', '');
    this.schedulerService.visit = visit;
    // this.navCtrl.navigateForward(`${this.urlGuestPrefix}/appointments/view`);
    this.navCtrl.navigateForward('/tabs/profile/appointments/view');
  }

  // Schedule second dose
  async schedule() {
    const profile = this.profileService.getProfile();
    const appointment = profile.covidVaccineAppointments[0];
    const schApplicationId = appointment.schApplication.application;
    appointment.reschedule = true;
    const validVisits = appointment.visits.filter(
      v => v.adminStatus !== 'CANCELED' && v.adminStatus !== 'PENDING' && v.adminStatus !== 'NOSHOW');
    appointment.visits = validVisits;
    appointment.vaccine = await this.getVaccineInfo(appointment.visits[0].vacSeq);
    appointment.schedStatus = 'scheduling';
    this.schedulerService.appointment = appointment;
    this.navCtrl.navigateForward(`/guest/schedule/${schApplicationId}/vaccine-doses`);
  }


  async reschedule() {
    const scheduleMoment = moment(this.visit.scheduleDate, 'MM/DD/YYYY hh:mm A');
    const now = moment();
    if (
      now.format('MM/DD/YYYY') === scheduleMoment.format('MM/DD/YYYY') &&
      now <= scheduleMoment &&
      this.schApplication &&
      this.schApplication.flexibleAttend
    ) {
      const alert = await this.alertCtrl.create({
        header: 'Change today\'s appointment?',
        message: 'We strongly recommend attending your appointment today even if you arrive ' +
          ' earlier or later than your scheduled time. <br><br>' +
          ' Can you still attend this appointment today even if at a different time?',
        cssClass: 'wide-alert',
        buttons: [
          {
            text: 'Go back',
            cssClass: 'font-color-primary',
            role: 'cancel',
            handler: () => {
              this.analytics.clickEvent('ApptCard > Change Today\'s Appt?: No', '');
            }
          },
          {
            text: 'Change my appointment',
            cssClass: 'font-color-primary',
            handler: () => {
              this.analytics.clickEvent('ApptCard > Change Today\'s Appt?: Yes', '');
              this.schedulerService.visit = this.visit;
              this.navCtrl.navigateForward(`${this.urlGuestPrefix}/appointment-change`);
            }
          },
        ]
      });

      await alert.present();
    } else {
      this.analytics.clickEvent('ApptCard > Reschedule/Cancel appointment', '');
      this.schedulerService.visit = this.visit;
      this.navCtrl.navigateForward(`${this.urlGuestPrefix}/appointment-change`);
    }
  }

  async getVaccineInfo(vacSeq): Promise<SchVaccine> {
    // const vaccineRes = await this.schedulerService.getVaccinesByShotType(shotType).toPromise();
    const vaccine = this.profileService.vaccines.filter(v => Number(v.vacSeq) === vacSeq)[0];
    return Promise.resolve(vaccine);
  }

  openGoogleMaps(link) {
    this.analytics.clickEvent('ApptCard: Directions and Map', link);
    window.open(link, '_system');
  }

}

import { ManageQuestionsEditAnswersAddPage } from './manage-questions-edit-answers-add.page';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: ManageQuestionsEditAnswersAddPage,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageQuestionsEditAnswersAddPageRoutingModule {}

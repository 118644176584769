import { Message } from './../../models/message';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  env = environment;
  contentMessages: Message[] = [];

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getByIdAndSubIdAndLanguage(schApplicationId, messageId, subId = null, language = 'EN') {
    const url = `${this.env.apiUrl}/info/messages?application=${schApplicationId}&id=${messageId}&subId=${subId}&language=${language}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getAll');
          }
          return res;
        })
      );
  }

  getByIdAndLanguage(schApplicationId, messageId, language = 'EN') {
    const url = `${this.env.apiUrl}/info/messages?application=${schApplicationId}&id=${messageId}&language=${language}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getAll');
          }
          return res;
        })
      );
  }

  getByMessageTypeAndLanguage(schApplicationId, messageType, language = 'EN') {
    const url = `${this.env.apiUrl}/info/messages?application=${schApplicationId}&type=${messageType}&language=${language}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Questions/getAll');
          }
          return res;
        })
      );
  }

  createMessaging(msg: Message) {
    const url = `${this.env.apiUrl}/info/messages`;
    return this.http.post(url, msg)
      .pipe(
        tap((res: any) => {
          // console.log('createMessaging():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Creating message');
          }
          return res;
        })
      );
  }

  updateMessaging(msg: Message) {
    const url = `${this.env.apiUrl}/info/messages/${msg.msgSeq}`;
    return this.http.put(url, msg)
      .pipe(
        tap((res: any) => {
          // console.log('updateMessaging():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Update message');
          }
          return res;
        })
      );
  }

}

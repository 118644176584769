import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { Campaign } from '../../models/campaign';
import { User } from '../../models/user';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Guest } from 'src/app/models/guest';
import { SelectOption } from 'src/app/models/_core/select-option';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { GuestsService } from 'src/app/services/guests/guests.service';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { StorageService } from 'src/app/services/_core/storage/storage.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-manage-guest-register',
  templateUrl: './manage-guest-register.page.html',
  styleUrls: ['./manage-guest-register.page.scss'],
})
export class ManageGuestRegisterPage implements OnInit {
  @Input() regType: 'standard' | 'waitlist' | 'incompleteReg' = 'standard';
  modalTitle = 'Register Person';
  pageTitle = 'Register Person';
  pageDescription = 'Register a non-Baystate Health associated person on their behalf.';
  pageButton = 'Register';

  env = environment;
  dobFormatted = null;
  currentView: 'form' | 'loading' | 'no-vaccine' | 'error' | 'email-resent' | 'continue-reg' = 'loading';
  form1: FormGroup = this.formBuilder.group({
    campaign: ['', Validators.required],
    dob: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/)]],
    email: ['', [Validators.required, Validators.pattern(/^.+@.+\..+$/)]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    month: ['Month', Validators.required],
    day: ['Day', Validators.required],
    year: ['Year', Validators.required],
    gender: ['', Validators.required],
    mobilePhone: ['', [Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]],
    homePhone: ['', [Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]],
    address1: ['', Validators.required],
    address2: [''],
    city: ['', Validators.required],
    state: ['MA', Validators.required],
    zip: ['', [Validators.required, Validators.minLength(5)]],
    // sendConfirmation: [false],
    goToScheduling: [true],
    ondWaitList: [false]
  });
  submitAttempted = false;
  showErrorMessage = false;
  showMobileOrHomePhoneError = false;
  invalidDate = false;
  validationMessages = {
    campaign: [{ type: 'required', message: 'This field is required.' }],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Email is invalid.' }
    ],
    // group: [{ type: 'required', message: 'This field is required.' }],
    // otherGroup: [{ type: 'required', message: 'This field is required.' }],
    // groupCity: [{ type: 'required', message: 'This field is required.' }],
    // otherGroupCity: [{ type: 'required', message: 'This field is required.' }],
    firstName: [{ type: 'required', message: 'First Name is required.' }],
    lastName: [{ type: 'required', message: 'Last Name is required.' }],
    dob: [
      { type: 'required', message: 'A valid Date of Birth is required.' },
      { type: 'pattern', message: 'Date of Birth is invalid. Use MM/DD/YYYY.' }
    ],
    gender: [{ type: 'required', message: 'Gender is required.' }],
    mobilePhone: [
      { type: 'validatePhoneNumbers', message: 'Either Mobile or Home Phone number is required.' },
      { type: 'pattern', message: 'Please use the format of 000-000-0000' }
    ],
    homePhone: [
      { type: 'validatePhoneNumbers', message: 'Either Mobile or Home Phone number is required.' },
      { type: 'pattern', message: 'Please use the format of 000-000-0000' }
    ],
    address1: [{ type: 'required', message: 'Address is required.' }],
    city: [{ type: 'required', message: 'City is required.' }],
    state: [{ type: 'required', message: 'State is required.' }],
    zip: [
      { type: 'required', message: 'Zip Code is required.' },
      { type: 'minlength', message: 'Zip Code must be 5 characters (00000).' },
    ],

  };
  campaigns: Campaign[] = [];
  campaign: Campaign;
  campaignOptions: SelectOption[] = [];
  groupOptions: SelectOption[] = [];
  groupCityOptions: SelectOption[] = [];
  genderOptions: SelectOption[] = this.helpers.genderOptions;
  states: SelectOption[] = this.helpers.stateOptions;
  months = this.helpers.months;
  days = [];
  years = [];
  welcomeText = null;
  medConditionsText = null;
  certainWorkersText = null;
  noVaccineText = null;
  helpText = null;
  helpPhone = null;
  hasAgreed = false;
  fieldSubs: Subscription[] = [];
  guest: Guest = {};
  email = null;
  authUser: User = null;
  profile = null;
  suspendInfo = null;
  isSupportMode = false;
  isSpecialCampaign = false;

  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    private messagingService: MessagingService,
    private schedulerService: SchedulerService,
    private guestsService: GuestsService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private profileService: ProfileService,
    private analytics: BhAnalyticsService,
    private storageService: StorageService,
    private helpers: HelperUtilitiesService
  ) {
    this.populateDaysYears();
  }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    this.authUser = this.authService.getAuthUser();
    await this.loadCampaigns();
    // await this.loadGroupValues();
    await this.loadMessaging();
    if (this.regType === 'incompleteReg') {
      this.loadIncompleteReg();
    } else if (this.regType === 'waitlist') {
      this.prepareWaitlistReg();
    } else {
      this.currentView = 'form';
      // this.promptVaccineWarning();
    }
    this.subscribeToGuestFields();
  }

  ionViewDidLeave() {
    this.fieldSubs.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  async prepareWaitlistReg() {
    this.modalTitle = 'Add Person';
    this.pageTitle = 'Add Person to On Demand Waitlist';
    this.pageDescription = 'Pre-register a person and add them to the waitlist.';
    this.pageButton = 'Add to Waitlist';
    this.clearValidations();
    this.currentView = 'form';
    await this.loadMessaging();
  }

  async promptVaccineWarning(campDescription) {
    if (this.campaign && this.campaign.camSeq) {
      const vaccineAvailResult =
        await this.schedulerService.getCampaignVaccineAvailability(this.campaign, false, '', 18, true, true, null);
      if (!vaccineAvailResult || !vaccineAvailResult.hasAvailability) {
        this.notificationsService.showAlert('Warning',
          'We have run out of vaccine supply for this campaign: ' + campDescription);
      }

    }
  }

  checkForSpecialCampaign() {
    if (this.campaign.camSeq !== this.env.covid19Vaccine.guestCamSeq) {
      this.isSpecialCampaign = true;
    } else {
      this.isSpecialCampaign = false;
    }
  }

  async loadIncompleteReg() {
    this.currentView = 'continue-reg';
    await this.loadMessaging();
    this.form1.controls.email.setValue(this.guest.email);
    // this.form1.controls.group.setValue(this.guest.groupCode);
    // if (this.guest.groupCode === 'OTHER') {
    //   this.form1.controls.otherGroup.setValue(this.guest.group);
    // }
    // this.form1.controls.groupCity.setValue(this.guest.groupCity);
    // this.form1.controls.otherGroupCity.setValue(this.guest.groupCity);
    // Birthdate fields
    const dobMoment = moment(this.guest.dob, 'MM/DD/YYYY', true);
    if (dobMoment.format() !== 'Invalid date') {
      this.form1.controls.month.setValue(dobMoment.format('M'));
      this.form1.controls.day.setValue(dobMoment.format('D'));
      this.form1.controls.year.setValue(dobMoment.format('YYYY'));
      this.form1.controls.dob.setValue(dobMoment.format('MM/DD/YYYY'));
    }
    this.form1.controls.firstName.setValue(this.guest.firstName);
    this.form1.controls.lastName.setValue(this.guest.lastName);
    this.form1.controls.gender.setValue(this.guest.gender);
    this.form1.controls.mobilePhone.setValue(this.guest.mobilePhone);
    this.form1.controls.homePhone.setValue(this.guest.homePhone);
    this.form1.controls.address1.setValue(this.guest.address1);
    this.form1.controls.address2.setValue(this.guest.address2);
    this.form1.controls.city.setValue(this.guest.city);
    this.form1.controls.state.setValue(this.guest.state);
    this.form1.controls.zip.setValue(this.guest.zip);
  }

  clearValidations() {
    this.form1.controls.campaign.clearValidators();
    this.form1.controls.campaign.updateValueAndValidity({ onlySelf: true });
    this.guest.camSeq = 0;
    this.guest.campaign = 'waitlist';
    this.form1.controls.email.clearValidators();
    this.form1.controls.email.setValidators(Validators.pattern(/^.+@.+\..+$/));
    this.form1.controls.email.updateValueAndValidity({ onlySelf: true });
    this.form1.controls.gender.clearValidators();
    this.form1.controls.gender.updateValueAndValidity({ onlySelf: true });
    this.form1.controls.address1.clearValidators();
    this.form1.controls.address1.updateValueAndValidity({ onlySelf: true });
    this.form1.controls.zip.clearValidators();
    this.form1.controls.zip.updateValueAndValidity({ onlySelf: true });

  }

  populateDaysYears() {
    this.days = [];
    this.years = [];

    // Populate days
    for (let i = 1; i < 32; i++) {
      this.days.push(i);
    }

    // Populate years
    const today = moment();
    const year = Number(today.format('YYYY'));
    for (let y = year; y > 1900; y--) {
      this.years.push(y);
    }
  }

  startRegistration() {
    this.currentView = 'form';
  }

  async checkVaccines() {
    try {
      const shotType = 'COVID';
      const vaccineRes = await this.schedulerService.getVaccinesByShotType(shotType, this.env.covid19Vaccine.supportCamSeq).toPromise();
      if (
        (
          vaccineRes &&
          vaccineRes.vaccines &&
          vaccineRes.vaccines.
            filter(v => v.dose1Available > 0 && (v.status === 'ACTIVE')).length > 0
        )
      ) {
        // has vaccine
        return Promise.resolve(true);
      } else {
        // has NO vaccine
        return Promise.resolve(false);
      }
    } catch (err) {
      console.error('Error occurred when checking vaccine', err);
      this.currentView = 'error';
    }

  }

  async loadMessaging(): Promise<boolean> {
    this.medConditionsText = await this.getMessaging('WWMEDCONDITIONS');
    this.certainWorkersText = await this.getMessaging('WWCERTAINWORKERS');
    return Promise.resolve(true);
  }

  async getMessaging(messageId): Promise<string> {
    try {
      const appRes = await this.messagingService.getByIdAndLanguage(
        this.env.covid19Vaccine.applicationId,
        messageId
      ).toPromise();
      // console.log('appRes for getMessaging()', appRes);
      const msgText = (appRes.msg) ? appRes.msg.text : null;
      return Promise.resolve(msgText);
    } catch (err) {
      console.error('Error occurred while loading messaging', err);
      this.currentView = 'error';
    }

  }

  async loadCampaigns(): Promise<boolean> {
    try {
      // Add Campaign options
      this.campaignOptions = [];
      const camRes = await this.schedulerService.getCampaigns(true, true).toPromise();
      // console.log('loadCampaigns: Campaigns', camRes);
      this.campaigns = camRes.campaigns;
      this.campaigns.forEach((c: Campaign) => {
        // const camVaccineTotal = this.checkCamVaccine(c) > 0 ? ' -- VACCINE AVAILABLE' : ' -- NO VACCINE, DO NOT USE';
        const option: SelectOption = { label: c.description, value: c.camSeq };
        this.campaignOptions.push(option);
      });
      this.form1.controls.campaign.setValue(this.env.covid19Vaccine.supportCamSeq);
      this.setCampaign(this.env.covid19Vaccine.supportCamSeq.toString());
      return Promise.resolve(true);
    } catch (err) {
      console.error('Error occurred while loading campaign values', err);
      this.currentView = 'error';
      return Promise.resolve(false);
    }
  }

  checkCamVaccine(campaign: Campaign): number {
    let vaccineTotal = 0;
    if (campaign.vaccines.length > 0) {
      campaign.vaccines.forEach((v: SchVaccine) => {
        // Ignore negative vaccine count
        if (v.dose1Available > 0) {
          vaccineTotal += v.dose1Available;
        }
      });
    }
    return vaccineTotal;
  }

  async subscribeToGuestFields() {
    this.fieldSubs.push(
      this.form1.controls.firstName.valueChanges.subscribe(val => this.guest.firstName = val),
      this.form1.controls.lastName.valueChanges.subscribe(val => this.guest.lastName = val),
      // Add date logic
      this.form1.controls.month.valueChanges.subscribe(val => this.validateBirthDate()),
      this.form1.controls.day.valueChanges.subscribe(val => this.validateBirthDate()),
      this.form1.controls.year.valueChanges.subscribe(val => this.validateBirthDate()),
      this.form1.controls.dob.valueChanges.subscribe(val => this.guest.dob = val),
      this.form1.controls.email.valueChanges.subscribe(val => this.guest.email = val),
      this.form1.controls.gender.valueChanges.subscribe(val => this.guest.gender = val),
      this.form1.controls.mobilePhone.valueChanges.subscribe(
        val => {
          this.guest.phone = val;
          this.guest.mobilePhone = val;
          this.showMobileOrHomePhoneError = !this.validatePhoneNumbers();
        }),
      this.form1.controls.homePhone.valueChanges.subscribe(
        val => {
          this.guest.homePhone = val;
          this.showMobileOrHomePhoneError = !this.validatePhoneNumbers();
        }
      ),
      this.form1.controls.address1.valueChanges.subscribe(val => this.guest.address1 = val),
      this.form1.controls.address2.valueChanges.subscribe(val => this.guest.address2 = val),
      this.form1.controls.city.valueChanges.subscribe(val => this.guest.city = val),
      this.form1.controls.state.valueChanges.subscribe(val => this.guest.state = val),
      this.form1.controls.zip.valueChanges.subscribe(val => this.guest.zip = val),
      this.form1.controls.campaign.valueChanges.subscribe(val => this.setCampaign(val)),
      this.form1.controls.ondWaitList.valueChanges.subscribe(val => this.guest.ondWaitList = val ? 1 : 0),
    );
  }

  setCampaign(newCamSeq: string) {
    this.campaign = this.campaigns.filter(c => c.camSeq.toString() === newCamSeq)[0];
    this.guest.camSeq = this.campaign.camSeq;
    this.guest.campaign = 'supportmode';
    this.promptVaccineWarning(this.campaign.description);
    this.checkForSpecialCampaign();
  }

  async validateEmailAndBirthdate(email, dob) {
    try {
      this.email = email;
      if (this.form1.controls.email.valid) {
        const emailRes = await this.guestsService.validateEmailAndBirthdate(email, dob).toPromise();
        const emailExists = (emailRes.inUse === 1);
        if (emailExists) {
          this.presentEmailInUseAlert();
        }
      } else {
        console.log('Email input not valid', this.form1.controls.email);
      }
    } catch (err) {
      if (err.error.message.indexOf('Validation Failed:') > -1) {
        this.notificationsService.showAlert('Email Format Error',
          'The format of the email you entered is not valid. Please check that it was entered correctly.');
      } else {
        console.error('Error occurred while validating email/dob', err);
        this.currentView = 'error';
      }
    }
  }


  async presentEmailInUseAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Warning: Already registered',
      message: 'Our records indicate someone was previously registered with this email and birthdate.' +
        ' It is not recommended that you continue registering, but we can send this email a link to schedule or manage appointments.',
      cssClass: 'wide-alert reverse-buttons',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Continue',
          cssClass: 'font-color-primary',
        },
        {
          text: 'Resend appointment link',
          cssClass: 'font-color-primary',
          handler: async () => {
            console.log('Resending link');
            try {
              const emailRes = await this.guestsService.resendAccessKeyEmail(this.guest.email, this.dobFormatted).toPromise();
              this.currentView = 'email-resent';
            } catch (err) {
              this.currentView = 'email-resent';
            }
          },
        },
      ]
    });
    await alert.present();
  }

  validateBirthDate(): boolean {
    const month = this.form1.controls.month.value;
    const day = this.form1.controls.day.value;
    const year = this.form1.controls.year.value;
    const dobMoment = moment(year + '-' + month + '-' + day, 'YYYY-M-D', true);
    const today = moment();
    this.dobFormatted = dobMoment.format('MM/DD/YYYY');
    this.invalidDate = (this.dobFormatted === 'Invalid date');
    if (!this.invalidDate) {
      const yearsOld = today.diff(dobMoment, 'year');
      console.log('Person is ' + yearsOld);
      if (yearsOld >= this.env.covid19Vaccine.minRegAge) {
        if (!this.validateAudience()) {
          this.showAudienceIneligibleAlert();
        } else {
          if (this.regType !== 'waitlist' && this.guest.email) {
            if (this.validateEmailAndBirthdate(this.guest.email, this.dobFormatted)) {
              this.form1.controls.dob.setValue(this.dobFormatted);
              this.guest.dob = this.dobFormatted;
            } else {
              // this.presentEmailInUseAlert();
              this.form1.controls.month.setValue('Month');
              this.form1.controls.day.setValue('Day');
              this.form1.controls.year.setValue('Year');
              this.form1.controls.dob.setValue('');
              this.guest.dob = null;
            }
          } else {
            // Waitlist bypass email validation
            this.form1.controls.dob.setValue(this.dobFormatted);
            this.guest.dob = this.dobFormatted;
          }

        }
      } else {
        this.notificationsService.showAlert('Warning',
          'This vaccine is only available for individuals ' + this.env.covid19Vaccine.minRegAge + ' years or older.');
      }
      // Allow user to continue anyway
      this.form1.controls.dob.setValue(this.dobFormatted);
      this.guest.dob = this.dobFormatted;
    }
    return (this.dobFormatted !== 'Invalid date');
  }

  validateAudience() {
    // 2021.01.27 = For first responders, and patients 55+
    const month = this.form1.controls.month.value;
    const day = this.form1.controls.day.value;
    const year = this.form1.controls.year.value;
    const dobMoment = moment(year + '-' + month + '-' + day, 'YYYY-M-D', true);
    const today = moment();
    const yearsOld = today.diff(dobMoment, 'year');
    if (
      (this.guest.groupCode === 'PATNT' && yearsOld >= 55) ||
      this.guest.groupCode !== 'PATNT'
    ) {
      return true;
    } else {
      return false;
    }
  }

  showAudienceIneligibleAlert() {
    this.notificationsService.showAlert('Warning: not eligible yet',
      'At this time, individuals aged 55 or older, individuals with 2 certain health conditions, certain workers' +
      ' K-12 education workers, first responders, healthcare workers, congregate care workers, and ' +
      ' low income/senior housing workers are allowed to register. <br><br>' +
      'For more information on vaccine eligibility, please see: ' +
      '<a href="https://www.mass.gov/info-details/massachusetts-covid-19-vaccination-phases" target="_blank" ' +
      'class="font-color-primary">Massachusetts\' COVID-19 vaccination phases</a>');
  }

  validatePhoneNumbers() {
    const mobilePhone = this.form1.controls.mobilePhone.value;
    const homePhone = this.form1.controls.homePhone.value;
    return mobilePhone !== '' || homePhone !== '';
  }

  async register() {
    this.submitAttempted = true;
    if (this.form1.valid && this.validatePhoneNumbers()) {
      this.currentView = 'loading';
      // this.guest.campaign = 'supportmode';
      this.guest.regSource = 'R';
      this.guest.testAsProd = 1;
      this.guest.supportreg = 1;
      this.guest.embId = 'EMBRESETGK';
      this.guest.state = this.form1.controls.state.value;
      // const otherGroupCode = this.form1.controls.otherGroup.value;
      // Assign Special Campaign
      if (this.isSpecialCampaign) {
        this.guest.groupCode = this.env.covid19Vaccine.specialGuestGroupId;
        this.guest.group = this.campaign.description;
      } else {
        this.guest.groupCode = this.env.covid19Vaccine.genGuestGroupId;
        this.guest.group = 'General Public (or Proxy)';
      }

      if (this.regType === 'waitlist') {
        this.guest.embId = null;
        // this.guest.sendConfirmation = 0; // Ideal state
        this.guest.ondWaitList = 1;
        this.guest.wlStatus = 'NEW';
        this.guest.supportreg = 0;
        this.guest.wlNotes = (moment().format('YYYY-MM-DD HH:mm') + ' - ' + this.authUser.userId +
          ' - Added (Pre-Reg) \n').trim();
      }

      try {
        let regRes = null;
        if (this.regType === 'incompleteReg') {
          regRes = await this.guestsService.update(this.guest).toPromise();
          const resetRes = await this.guestsService.resetAccessKey(this.guest.userId).toPromise();
          if (resetRes.x_status === 'S') {
            console.log('Reset GenKey completed');
          }
        } else {
          regRes = await this.guestsService.register(this.guest).toPromise();
        }
        if (regRes.x_status === 'S') {
          if (this.regType !== 'incompleteReg') {
            this.guest.nbuSeq = regRes.nbuSeq;
            this.guest.userId = regRes.userId;
          }

          if (this.regType === 'waitlist') {
            await this.schedulerService.updateWaitListStatus(this.guest).toPromise();
            // await this.guestsService.update(this.guest).toPromise();
          }

          // Valid user detected
          if (this.guest && this.guest.userId) {
            const userId = this.guest.userId.toLowerCase();
            this.currentView = 'form';
            const goToScheduling = this.form1.controls.goToScheduling.value;
            const url = goToScheduling ?
              `/tabs/manage/people/${userId}/${this.env.covid19Vaccine.applicationId}/appointments` :
              `/tabs/manage/people/${userId}`;

            // Navigate to next page if not a waitlist addition
            if (this.regType === 'standard') {
              this.navCtrl.navigateForward(url);
              this.analytics.clickEvent('AdminGuestReg: register user', 'Valid user; opening scheduler');
            } else {
              this.analytics.clickEvent('AdminGuestReg: add to waitlist', 'Valid user; returning to waitlist');
            }
            this.dismiss(true);
          } else {
            this.currentView = 'form';
            this.notificationsService.showAlert('Error', 'An unexpected error occurred while registering. Please try again.');
          }
        } else {
          this.currentView = 'form';
          this.notificationsService.showAlert('Error', 'An unexpected error occurred while registering. Please try again.');
        }
      } catch (err) {
        this.notificationsService.stopLoading();
        // this.notificationsService.showAlert('Error', 'An unexpected error occurred while registering. Please try again.');
        console.error('Error occurred while registering', err);
        this.currentView = 'error';
      }
    } else {
      if (!this.form1.valid) {
        this.notificationsService.showAlert('Please complete required fields',
          'There are one or more empty required fields detected. Please complete each red-marked field.');
      } else if (!this.validatePhoneNumbers()) {
        this.notificationsService.showAlert('Phone number required',
          'Enter a mobile or home phone number to continue. Only one is required.');
      }
    }
  }

  dismiss(isRegistered = false) {
    this.modalCtrl.dismiss({ isRegistered });
  }

  reload() {
    window.location.reload();
  }


}

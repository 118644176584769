import { BhCalendarComponentModule } from './../../components/bh-calendar/bh-calendar.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DatepickerModalPage } from './datepicker-modal.page';
import { DatepickerModalPageRoutingModule } from './datepicker-modal-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BhCalendarComponentModule,
    DatepickerModalPageRoutingModule
  ],
  declarations: [DatepickerModalPage]
})
export class DatepickerModalPageModule {}

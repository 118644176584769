import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-covid-test-result-ad',
  templateUrl: './covid-test-result-ad.page.html',
  styleUrls: ['./covid-test-result-ad.page.scss'],
})
export class CovidTestResultAdPage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
    this.analytics.clickEvent('COVID-Test-Result-Ad: Viewed:', '');
  }

  async viewCovidTestResult() {
    this.dismiss('read');
  }

  dismiss(returnValue) {
    this.analytics.clickEvent('COVID-Test-Result-Ad: Action:', returnValue);
    this.modalCtrl.dismiss(returnValue);
  }

}

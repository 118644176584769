import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { BhAppHeaderMobileComponentModule } from 'src/app/components/_core/bh-app-header-mobile/bh-app-header-mobile.component.module';
import { BhHeaderLargeComponentModule } from '../../components/bh-header-large/bh-header-large.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManagePeopleApptsVaccineHistoryPage } from './manage-people-appts-vaccine-history.page';
import { ManagePeopleApptsVaccineHistoryPageRoutingModule } from './manage-people-appts-vaccine-history-routing.module';

@NgModule({
  imports: [
    BhSpinnerComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ManagePeopleApptsVaccineHistoryPageRoutingModule
  ],
  declarations: [ManagePeopleApptsVaccineHistoryPage]
})
export class ManagePeopleApptsVaccineHistoryPageModule {}

import { StorageService } from './../../services/_core/storage/storage.service';
import { SchConsent } from 'src/app/models/sch-consent';
import { Subscription } from 'rxjs';
import { AdPopUp } from 'src/app/models/ad-popup';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController, NavController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Profile } from 'src/app/models/profile';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { User } from 'src/app/models/user';
import * as moment from 'moment';

@Component({
  selector: 'app-appointment-reminder-ad',
  templateUrl: './appointment-reminder-ad.page.html',
  styleUrls: ['./appointment-reminder-ad.page.scss'],
})
export class AppointmentReminderAdPage implements OnInit {
  @Input() reminderType = 'reminder';
  @Input() ad: AdPopUp = null;
  imageUrl = '/assets/success-icon.png';
  heading = 'Attend your upcoming appointment';
  authUser: User;
  profile: Profile;
  visit: SchAppointment;
  daysToVisit: number;
  routeSub: Subscription;
  schApplicationId: string = null;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private schedulerService: SchedulerService,
    private authService: AuthService,
    private profileService: ProfileService,
    private analytics: BhAnalyticsService,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.subscribeToRoute();
    this.analytics.clickEvent('Appointment-Reminder-Ad: Viewed:', this.ad.adName);

    // if (this.reminderType === 'confirmation') {
    //   this.imageUrl = '/assets/warning-icon.png';
    //   this.heading = 'Please confirm your upcoming appointment';
    // }

    this.profile = this.profileService.getProfile();
    this.authUser = this.authService.getAuthUser();

    console.log('Ad visit', this.ad);

    switch (this.ad.adName) {
      case 'attend-covid-vaccine':
        if (!this.profile.covidVaccineAppointments || !this.profile.covidVaccineNextVisit) {
          // console.log('Missing next covid visit info, probably a change');
          setTimeout(() => { this.modalCtrl.dismiss(); }, 500);
        } else {
          this.visit = this.profile.covidVaccineNextVisit;
          this.schApplicationId = this.visit.application;
          this.daysToVisit = this.profile.covidVaccineDaysToNextVisit;
          const scheduledMoment = moment(this.visit.scheduleDate, 'MM/DD/YYYY hh:mm a');
          const todayMoment = moment();
          if (scheduledMoment.format('MM/DD/YYYY') === todayMoment.format('MM/DD/YYYY')) {
            this.heading = 'Please attend your appointment today!';
          }
        }
        break;

      case 'attend-covid-vaccine-booster':
        if (!this.profile.covidVaccineAppointments || !this.profile.covidVaccineBoosterNextVisit) {
          // console.log('Missing next covid visit info, probably a change');
          setTimeout(() => { this.modalCtrl.dismiss(); }, 500);
        } else {
          this.visit = this.profile.covidVaccineBoosterNextVisit;
          this.daysToVisit = this.profile.covidVaccineBoosterDaysToNextVisit;
          const scheduledMoment = moment(this.visit.scheduleDate, 'MM/DD/YYYY hh:mm a');
          const todayMoment = moment();
          if (scheduledMoment.format('MM/DD/YYYY') === todayMoment.format('MM/DD/YYYY')) {
            this.heading = 'Please attend your appointment today!';
          }
        }
        break;

      case 'attend-covid-testing':
        if (!this.profile.covidTestingAppointments || !this.profile.covidTestingNextVisit) {
          // console.log('Missing next covid visit info, probably a change');
          setTimeout(() => { this.modalCtrl.dismiss(); }, 500);
        } else {
          this.visit = this.profile.covidTestingNextVisit;
          this.schApplicationId = this.visit.application;
          this.daysToVisit = this.profile.covidTestingDaysToNextVisit;
          const scheduledMoment = moment(this.visit.scheduleDate, 'MM/DD/YYYY hh:mm a');
          const todayMoment = moment();
          if (scheduledMoment.format('MM/DD/YYYY') === todayMoment.format('MM/DD/YYYY')) {
            this.heading = 'Please attend your appointment today!';
          }
        }
        break;

      case 'attend-appointment':
        if (!this.ad || !this.ad.visit) {
          // console.log('Missing next covid visit info, probably a change');
          setTimeout(() => { this.modalCtrl.dismiss(); }, 500);
        } else {
          this.visit = this.ad.visit;
          this.schApplicationId = this.ad.visit.application;
          // this.daysToNextVisit = this.profile.covidTestingDaysToNextVisit;
          // this.nextVisit = this.profile.covidTestingNextVisit;
          const scheduledMoment = moment(this.visit.scheduleDate, 'MM/DD/YYYY hh:mm a');
          const todayMoment = moment();
          if (scheduledMoment.format('MM/DD/YYYY') === todayMoment.format('MM/DD/YYYY')) {
            this.heading = 'Please attend your appointment today!';
          }
        }
        break;

    }
  }

  ionViewWillLeave() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
      this.routeSub = null;
    }
  }

  subscribeToRoute() {
    this.routeSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.modalCtrl.dismiss('reschedule');
      }
    });
  }

  async confirm() {
    if (this.visit) {
      this.analytics.clickEvent('Appointment-Reminder-Ad: Action', 'Confirmed');
      const consent: SchConsent = {
        application: this.visit.application,
        consentType: 'APPTCONFIRMATION',
        userId: this.authUser.userId,
        response: 'Confirmed',
        detail: 'AptSeq: ' + this.visit.aptSeq + ' - Visit: ' + this.visit.visitLabel,
        active: 1
      };
      this.schedulerService.saveConsent(consent).toPromise();
      const todayMoment = moment();
      console.log('Saving: attend-appointment-' + this.visit.aptSeq, { viewed: todayMoment.format('MM/DD/YYYY HH:mm') });
      this.storageService.saveData('attend-appointment-' + this.visit.aptSeq, { viewed: todayMoment.format('MM/DD/YYYY HH:mm') });
    }
    this.modalCtrl.dismiss('read');
  }

  rescheduleOrCancel() {
    this.analytics.clickEvent('Appointment-Reminder-Ad: Action:', 'Reschedule or Cancel Appt');
    // this.navCtrl.navigateForward('/tabs/home/appointment-change');
    this.modalCtrl.dismiss('dismiss');
  }

  addToCalendar() {
    this.analytics.clickEvent('Appointment-Reminder-Ad: Action:', 'Add Appointment to Calendar');
  }

}

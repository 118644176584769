import { BhWorkflowButtonsComponent } from './bh-workflow-buttons.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BhWorkflowButtonsComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    BhWorkflowButtonsComponent
  ]
})
export class BhWorkflowButtonsComponentModule { }
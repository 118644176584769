/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuestRouteGuardService } from './services/guest-route-guard/guest-route-guard.service';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login/my-appointments',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'My Appointments', roles: [] },
    children: [
      {
        path: '',
        loadChildren: () => import('../app/pages/appointments-list/appointments-list.module').then(m => m.AppointmentsListPageModule)
      }
    ]
  },
  {
    path: 'help-forgot-pwd',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'share',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'help',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'help-no-pin',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help-no-pin/help-no-pin.module').then(m => m.HelpNoPinPageModule)
  },
  {
    path: 'errors',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors/errors.module').then(m => m.ErrorsPageModule)
  },
  {
    path: 'errors-detail',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors-detail/errors-detail.module').then(m => m.ErrorsDetailPageModule)
  },
  {
    path: 'check-for-updates',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Check for Updates', roles: [] },
    loadChildren: () => import('./pages/_core/check-for-updates/check-for-updates.module').then(m => m.CheckForUpdatesPageModule)
  },
  {
    path: 'about',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'bh-input-text-editor',
    loadChildren: () => import('./components/_core/bh-input-text-editor/bh-input-text-editor.module')
      .then(m => m.BhInputTextEditorPageModule)
  },
  {
    path: 'feedback',
    data: { pageName: 'Feedback', roles: [] },
    loadChildren: () => import('./pages/_core/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  // {
  //   path: 'profile/preferences',
  //   canActivate: [BhAnalyticsRouteGuardService],
  //   data: { pageName: 'Preferences', roles: [] },
  //   loadChildren: () => import('./pages/preferences/preferences.module').then(m => m.PreferencesPageModule)
  // },
  {
    path: 'welcome',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Welcome', roles: [] },
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'schedule/:schApplicationId/information',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Information', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-information/sch-information.module').then(m => m.SchInformationPageModule)
  },
  {
    path: 'schedule/:schApplicationId/screening',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Screening', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-screening/sch-screening.module').then(m => m.SchScreeningPageModule)
  },
  {
    path: 'schedule/:schApplicationId/vaccines',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Vaccine', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-vaccine/sch-vaccine.module').then(m => m.SchVaccinePageModule)
  },
  {
    path: 'schedule/:schApplicationId/consent',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Consent', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-consent/sch-consent.module').then(m => m.SchConsentPageModule)
  },
  {
    path: 'schedule/:schApplicationId/vaccine-doses',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Vaccine Doses', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-vaccine-doses/sch-vaccine-doses.module').then(m => m.SchVaccineDosesPageModule)

  },
  {
    path: 'schedule/:schApplicationId/location',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Location Select', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-location/sch-location.module').then(m => m.SchLocationPageModule)
  },
  {
    path: 'schedule/:schApplicationId/contact-info',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Contact Info', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-contact-info/sch-contact-info.module').then(m => m.SchContactInfoPageModule)
  },
  {
    path: 'schedule/:schApplicationId/review',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Review', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-review/sch-review.module').then(m => m.SchReviewPageModule)
  },
  {
    path: 'schedule/:schApplicationId/done',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Done', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-done/sch-done.module').then(m => m.SchDonePageModule)
  },
  {
    path: 'schedule/:schApplicationId/done/:result',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Done w/ result', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-done/sch-done.module').then(m => m.SchDonePageModule)
  },
  {
    path: 'covid-vaccine-declination',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Vaccine Declination', roles: [] },
    loadChildren: () => import('./pages/covid-vaccine-declination/covid-vaccine-declination.module')
      .then(m => m.CovidVaccineDeclinationPageModule)
  },
  {
    path: 'appointment-event-ineligible/:status',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Vaccine Ineligible', roles: [] },
    loadChildren: () => import('./pages/appointment-event-ineligible/appointment-event-ineligible.module')
      .then(m => m.AppointmentEventIneligiblePageModule)
  },
  {
    path: 'appointment-change',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Appointment Change', roles: [] },
    loadChildren: () => import('./pages/appointment-change/appointment-change.module').then(m => m.AppointmentChangePageModule)
  },

  // // Guest Scheduler Routes
  {
    path: 'guest/schedule/:schApplicationId/information',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Information', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-information/sch-information.module').then(m => m.SchInformationPageModule)
  },
  {
    path: 'guest/schedule/:schApplicationId/screening',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Screening', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-screening/sch-screening.module').then(m => m.SchScreeningPageModule)
  },
  {
    path: 'guest/schedule/:schApplicationId/vaccines',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Vaccine', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-vaccine/sch-vaccine.module').then(m => m.SchVaccinePageModule)
  },
  {
    path: 'guest/schedule/:schApplicationId/consent',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Consent', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-consent/sch-consent.module').then(m => m.SchConsentPageModule)
  },
  {
    path: 'guest/schedule/:schApplicationId/vaccine-doses',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Vaccine Doses', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-vaccine-doses/sch-vaccine-doses.module').then(m => m.SchVaccineDosesPageModule)

  },
  {
    path: 'guest/schedule/:schApplicationId/location',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Location Select', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-location/sch-location.module').then(m => m.SchLocationPageModule)

  },
  {
    path: 'guest/schedule/:schApplicationId/contact-info',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Contact Info', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-contact-info/sch-contact-info.module').then(m => m.SchContactInfoPageModule)

  },
  {
    path: 'guest/schedule/:schApplicationId/review',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Review', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-review/sch-review.module').then(m => m.SchReviewPageModule)

  },
  {
    path: 'guest/schedule/:schApplicationId/done',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Done', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-done/sch-done.module').then(m => m.SchDonePageModule)
  },
  {
    path: 'guest/schedule/:schApplicationId/done/:result',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Done w/ result', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-done/sch-done.module').then(m => m.SchDonePageModule)
  },
  {
    path: 'guest/covid-vaccine-declination',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Vaccine Declination', roles: [] },
    loadChildren: () => import('./pages/covid-vaccine-declination/covid-vaccine-declination.module')
      .then(m => m.CovidVaccineDeclinationPageModule)
  },
  {
    path: 'guest/appointment-event-ineligible/:status',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Vaccine Ineligible', roles: [] },
    loadChildren: () => import('./pages/appointment-event-ineligible/appointment-event-ineligible.module')
      .then(m => m.AppointmentEventIneligiblePageModule)
  },
  {
    path: 'guest/appointment-change',
    canActivate: [GuestRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Appointment Change', roles: [] },
    loadChildren: () => import('./pages/appointment-change/appointment-change.module').then(m => m.AppointmentChangePageModule)

  },
  // // END of Guest Scheduler Routes
  {
    path: 'view/schedule/done',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Scheduler: Done', roles: [] },
    loadChildren: () => import('./pages/scheduler/sch-done/sch-done.module').then(m => m.SchDonePageModule)
  },
  {
    path: 'welcome-whats-new',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Welcome: What\'s New', roles: [] },
    loadChildren: () => import('./pages/welcome-whats-new/welcome-whats-new.module').then(m => m.WelcomeWhatsNewPageModule)
  },
  {
    path: 'testing-prompt',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Testing Ad', roles: [] },
    loadChildren: () => import('./pages/testing-prompt/testing-prompt.module').then(m => m.TestingPromptPageModule)
  },
  {
    path: 'covid-test-result',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Result', roles: [] },
    loadChildren: () => import('./pages/covid-test-result/covid-test-result.module').then(m => m.CovidTestResultPageModule)
  },
  {
    path: 'covid-test-result-ad',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Result Ad', roles: [] },
    loadChildren: () => import('./pages/covid-test-result-ad/covid-test-result-ad.module').then(m => m.CovidTestResultAdPageModule)
  },
  {
    path: 'reminders-ad',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Reminders Ad', roles: [] },
    loadChildren: () => import('./pages/reminders-ad/reminders-ad.module').then(m => m.RemindersAdPageModule)

  },
  {
    path: 'covid-vaccine-ad',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Vaccine Ad', roles: [] },
    loadChildren: () => import('./pages/covid-vaccine-ad/covid-vaccine-ad.module').then(m => m.CovidVaccineAdPageModule)
  },
  {
    path: 'appointment-reminder-ad',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Appointment Reminder Ad', roles: [] },
    loadChildren: () => import('./pages/appointment-reminder-ad/appointment-reminder-ad.module')
      .then(m => m.AppointmentReminderAdPageModule)
  },
  {
    path: 'covid-testing-ad',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Testing Ad', roles: [] },
    loadChildren: () => import('./pages/appointments-list/appointments-list.module').then(m => m.AppointmentsListPageModule)
  },
  {
    path: 'guest/covid-vaccine',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Guest appointments', roles: [] },
    loadChildren: () => import('./pages/guest-covid-vaccine-appointments/guest-covid-vaccine-appointments.module')
      .then(m => m.GuestCovidVaccineAppointmentsPageModule)

  },
  {
    path: 'guest/covid-vaccine/register',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Guest registration', roles: [] },
    loadChildren: () => import('./pages/guest-covid-vaccine-register/guest-covid-vaccine-register.module')
      .then(m => m.GuestCovidVaccineRegisterPageModule)
  },
  {
    path: 'debug-options',
    loadChildren: () => import('./pages/debug-options/debug-options.module').then(m => m.DebugOptionsPageModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('./pages/resources/resources.module').then( m => m.ResourcesPageModule)
  },
  {
    path: 'manage-advanced',
    loadChildren: () => import('./pages/manage-advanced/manage-advanced.module').then( m => m.ManageAdvancedPageModule)
  },
  {
    path: 'assignment-text-ad',
    loadChildren: () => import('./pages/assignment-text-ad/assignment-text-ad.module').then( m => m.AssignmentTextAdPageModule)
  },
  {
    path: 'assignment-notifications',
    loadChildren: () => import('./pages/assignment-notifications/assignment-notifications.module').then( m => m.AssignmentNotificationsPageModule)
  },
  {
    path: 'screening-history-positive-covid19',
    loadChildren: () => import('./pages/screening-history-positive-covid19/screening-history-positive-covid19.module')
    .then( m => m.ScreeningHistoryPositiveCovid19PageModule)
  },
  {
    path: 'screening-history-positive-covid19-entry',
    loadChildren: () => import('./pages/screening-history-positive-covid19-entry/screening-history-positive-covid19-entry.module').then( m => m.ScreeningHistoryPositiveCovid19EntryPageModule)
  },
  {
    path: 'learn-more-covid19-positive',
    loadChildren: () => import('./pages/learn-more-covid19-positive/learn-more-covid19-positive.module').then( m => m.LearnMoreCovid19PositivePageModule)
  },
  {
    path: 'screening-history-rtw',
    loadChildren: () => import('./pages/screening-history-rtw/screening-history-rtw.module').then( m => m.ScreeningHistoryRtwPageModule)
  },
  {
    path: 'screening-history-rtw-entry',
    loadChildren: () => import('./pages/screening-history-rtw-entry/screening-history-rtw-entry.module').then( m => m.ScreeningHistoryRtwEntryPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

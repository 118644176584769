import { BhSpinnerComponentModule } from './../../_core/bh-spinner/bh-spinner.component.module';
import { BhCalendarMonthComponent } from './bh-calendar-month.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        BhCalendarMonthComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        BhSpinnerComponentModule,
    ],
    exports: [
        BhCalendarMonthComponent
    ]
})
export class BhCalendarMonthComponentModule { }

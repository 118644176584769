import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Message } from 'src/app/models/message';
import { NavController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'app-manage-messaging-edit',
  templateUrl: './manage-messaging-edit.page.html',
  styleUrls: ['./manage-messaging-edit.page.scss'],
})
export class ManageMessagingEditPage implements OnInit {
  env = environment;
  msgId?: string;
  form1: FormGroup = this.formBuilder.group({
    contentHtml: [''],
    contentSource: [''],
  });
  showErrorMessage = false;
  validationMessages = {
    contentHtml: [],
    contentSource: []
  };
  message: Message = {};
  authUser: User = null;
  currentSegment = 'editor';

  constructor(
    public formBuilder: FormBuilder,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private notifications: NotificationsService,
    private messagingService: MessagingService,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    this.loadMessaging();
    this.authUser = this.authService.getAuthUser();
  }

  async loadMessaging() {
    try {
      const msgRes = await this.messagingService.getByIdAndLanguage(this.env.covid19Vaccine.applicationId, this.msgId).toPromise();
      // console.log('Message received', msgRes);
      this.message = msgRes.msg;
      this.message.loginUserId = this.authUser.userId;
      this.analytics.screenView('AdminMessagingEdit: ' + this.message.id);
      // this.message.active = 1;
      this.form1.controls.contentHtml.setValue(this.message.text);
      this.form1.controls.contentSource.setValue(this.message.text);
    } catch (err) {
      this.notifications.handleError(err, 'loadMessaging', null, 'An error occurred while loading your message.');
    }
  }

  async save() {
    try {
      const contentHtml =
        (this.currentSegment === 'editor') ? this.form1.controls.contentHtml.value : this.form1.controls.contentSource.value;
      this.message.text = (contentHtml) ? contentHtml : '';
      const msgRes = await this.messagingService.updateMessaging(this.message).toPromise();
      this.analytics.clickEvent('AdminMessagingEdit: Save', this.message.id);
      this.notifications.showAlert('', 'Content was successfully saved.');
      this.modalCtrl.dismiss();
    } catch (err) {
      this.notifications.handleError(err, 'saveMessage', null, 'An error occurred while saving your message.');
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  segmentChanged(event) {
    const newTab = event.detail.value;
    const contentHtml = this.form1.controls.contentHtml.value;
    const contentSource = this.form1.controls.contentSource.value;
    if (this.form1.controls.contentHtml && this.form1.controls.contentSource) {
      if (newTab === 'editor' && this.currentSegment === 'source') {
        this.form1.controls.contentHtml.setValue(contentSource);
      } else if (newTab === 'source' && this.currentSegment === 'editor') {
        this.form1.controls.contentSource.setValue(contentHtml);
      }
    }
    this.currentSegment = newTab;
  }

}

import { BhConfirmationComponentModule } from './../../components/_core/bh-confirmation/bh-confirmation.component.module';
import { BhValidationErrorComponentModule } from '../../components/_core/bh-validation-error/bh-validation-error.component.module';
import { BhInputComponentModule } from '../../components/_core/bh-input/bh-input.component.module';
import { BhMessageBannerComponentModule } from '../../components/_core/bh-message-banner/bh-message-banner.component.module';
import { BhSpinnerComponentModule } from '../../components/_core/bh-spinner/bh-spinner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManagePeopleApptsScreeningPage } from './manage-people-appts-screening.page';
import { ManagePeopleApptsScreeningPageRoutingModule } from './manage-people-appts-screening-routing.module';

@NgModule({
  imports: [
    BhMessageBannerComponentModule,
    BhInputComponentModule,
    BhConfirmationComponentModule,
    BhSpinnerComponentModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ManagePeopleApptsScreeningPageRoutingModule
  ],
  declarations: [ManagePeopleApptsScreeningPage]
})
export class ManagePeopleApptsScreeningPageModule {}

import { Component, OnInit, Input, Output, OnChanges, ViewEncapsulation } from '@angular/core';

/**
 * ID: bh-logo
 * Name: BH Logo
 * Description: Displays standardized Baystate Health logo
 * Version: 3
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-23 - MW - v2: Fixed image selection and arguments
 * 2022-05-26 - MW - v3: Implemented theme awareness
 */
@Component({
  selector: 'bh-logo',
  templateUrl: './bh-logo.component.html',
  styleUrls: ['./bh-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BhLogoComponent implements OnChanges {
  @Input() height = '32px';
  @Input() width = 'auto';
  @Input() align = 'left';
  @Input() theme = null;
  @Input() logoType = 'simple';
  logoImage = '/assets/bh-logos/bh-simple-bk.png';
  logoImageCss = 'url(\'' + this.logoImage + '\')';

  constructor() { }

  ngOnChanges() {
    // this.logoImage = (this.logoType === 'simple') ? '/assets/bh-logos/bh-wh.png' : '/assets/bh-logos/bh-ac-stacked-wh.png';
    // switch (this.logoType) {
    //   case 'simple': // Simple Baystate Health
    //     this.logoImage = (this.theme === 'light') ? '/assets/bh-logos/bh-simple-wh.png' : '/assets/bh-logos/bh-simple-bk.png';
    //     break;

    //   case 'simple-stacked': // Simple Baystate Health stacked
    //     this.logoImage = (this.theme === 'light') ? '/assets/bh-logos/bh-stacked-wh.png' : '/assets/bh-logos/bh-stacked-bk.png';
    //     break;

    //   case 'ac-stacked': // Standard Advancing Care: stacked
    //     this.logoImage = (this.theme === 'light') ? '/assets/bh-logos/bh-ac-stacked-wh.png' : '/assets/bh-logos/bh-ac-stacked-bk.png';
    //     break;

    //   case 'ac-sxs': // Advancing Care: Side by side
    //     this.logoImage = (this.theme === 'light') ? '/assets/bh-logos/bh-ac-sxs-wh.png' : '/assets/bh-logos/bh-ac-sxs-bk.png';
    //     break;

    //   case 'ac-sxs-wide': // Advancing Care: Side by side - wide version
    //     this.logoImage = (this.theme === 'light') ? '/assets/bh-logos/bh-ac-sxs-wide-wh.png' : '/assets/bh-logos/bh-ac-sxs-wide-bk.png';
    //     break;

    // }
    // this.logoImageCss = 'url(\'' + this.logoImage + '\')';
  }

}

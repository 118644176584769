import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BhAnalyticsService } from '../../services/_core/bhanalytics/bhanalytics.service';

@Component({
  selector: 'app-manage-questions-edit-answers-add',
  templateUrl: './manage-questions-edit-answers-add.page.html',
  styleUrls: ['./manage-questions-edit-answers-add.page.scss'],
})
export class ManageQuestionsEditAnswersAddPage implements OnInit {
  submitAttempted = false;
  form1: FormGroup = this.formBuilder.group({
    label: ['', Validators.required],
    value: ['', Validators.required],
  });
  showErrorMessage = false;
  validationMessages = {
    label: [{ type: 'required', message: 'Option label cannot be blank.' }],
    value: [{ type: 'required', message: 'Option value cannot be blank.' }],
  };

  constructor(
    public formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
  }
  ionViewDidEnter() {
  }
  addAnswer() {
    this.submitAttempted = true;
    if (this.form1.valid) {
      const opt = {
        label: this.form1.controls.label.value,
        value: this.form1.controls.value.value,
        active: true,
        order: 0
      };
      this.modalCtrl.dismiss(opt);
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }



}

import { NavPage } from '../../../models/_core/nav-page';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { VerlockerService } from 'src/app/services/_core/verlocker/verlocker.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { HelperUtilitiesService } from '../../../services/_core/helper-utilities/helper-utilities.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { User } from 'src/app/models/user';
import { UserMenuPopoverPage } from 'src/app/pages/_core/user-menu-popover/user-menu-popover.page';
import { Subscription } from 'rxjs';
import { VerlockerData } from 'src/app/models/_core/verlocker-data';

/**
 * ID: bh-app-header-web
 * Name: BH Web Header
 * Description: Displays a wide viewport web header for use on pages that don't use tabs-page to
 * maintain streamlined look + feel -- should mirror tabs-page buttons
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-05-24 - MW - v2: Added user badge
 */
@Component({
  selector: 'bh-app-header-web',
  templateUrl: './bh-app-header-web.component.html',
  styleUrls: ['./bh-app-header-web.component.scss'],
})
export class BhAppHeaderWebComponent implements OnInit {
  @Input() activeTabId;
  env = environment;
  authUser: User = null;
  isGuest = false;
  subscriptions: Subscription[] = [];
  showUpdateIcon = false;
  navPages: NavPage[] = [];
  accessiblePages = 0;

  constructor(
    private navCtrl: NavController,
    private authService: AuthService,
    private helpers: HelperUtilitiesService,
    private popoverCtrl: PopoverController,
    private analytics: BhAnalyticsService,
    private verlocker: VerlockerService,
    public navService: NavigationService,
  ) { }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.navService.checkPrivileges(this.authUser);
    this.isGuest = (!this.authUser || !this.authUser.userId);
    this.navPages = this.navService.navPages;
    this.subscribeToFeeds();
  }

  subscribeToFeeds() {
    // Subscribe to user
    this.subscriptions.push(
      this.authService.authUser.subscribe(val => {
        this.authUser = val;
        this.navService.checkPrivileges(this.authUser);
      })
    );

    // Subscribe to verlocker app-version checking
    this.verlocker.verlockerDataSubject.subscribe((val: VerlockerData) => {
      this.showUpdateIcon = (val.verstatus === 'REQUESTED');
    });

  }

  unsubcribeToFeeds() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  openPage(page) {
    this.analytics.clickEvent('web-header: navigate', page);
    this.navService.navigateRoot(page);
  }

  login() {
    this.navService.navigateBack('login');
  }

  async openNotifications(ev) {
    this.analytics.clickEvent('web-header: open notifications', '');
    this.navService.navigateForward('/tabs/notifications');
  }

  async openUpdates(ev) {
    this.navService.navigateForward('check-for-updates');
  }

  async openUserMenu(ev) {
    const popover = await this.popoverCtrl.create({
      component: UserMenuPopoverPage,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }


}

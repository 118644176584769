import { environment } from 'src/environments/environment';
import { AdPopupService } from './../../services/ad-popup/ad-popup.service';
import { CovidResultService } from './../../services/covid-result/covid-result.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { CovidResult } from 'src/app/models/covid-result';
import * as moment from 'moment';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { Profile } from 'src/app/models/profile';

@Component({
  selector: 'app-covid-test-result',
  templateUrl: './covid-test-result.page.html',
  styleUrls: ['./covid-test-result.page.scss'],
})
export class CovidTestResultPage implements OnInit {
  env = environment;
  isAuthenticated = false;
  covidResult: CovidResult;
  currentView: 'loading' | 'result' = 'loading';
  breadcrumbs: Breadcrumb[] = [
    { label: 'Profile', url: '/tabs/profile', direction: 'back' },
    { label: 'COVID-19 Test Result', url: null, direction: null },
  ];
  emoSupportTitle: string;
  emoSupportMessage: string;
  profile: Profile;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthService,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private profileService: ProfileService,
    private covidResultService: CovidResultService,
    private navCtrl: NavController,
    private adPopupService: AdPopupService,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    this.loadEmoSupportMessaging();
    const authUser = this.authService.getAuthUser();
    this.isAuthenticated = authUser.authenticated;
    if (this.isAuthenticated) {
      this.profile = this.profileService.getProfile();
      await this.loadCovidTestResult();
      this.getCovidTestResult(authUser.userId);
    } else {
      this.currentView = 'result';
    }
  }

  async authenticate(result: boolean) {
    this.isAuthenticated = result;
    if (this.isAuthenticated) {
      const authUser = this.authService.getAuthUser();
      await this.loadCovidTestResult();
      this.getCovidTestResult(authUser.userId);
    }
  }

  async loadCovidTestResult(): Promise<boolean> {
    try {
      const authUser = this.authService.getAuthUser();
      await this.profileService.loadCovidTestingProfile(this.profile, authUser);
    } catch (err) {
      this.notifications.handleError(err, 'covid-test-result.loadCovidTestResult');
    }
    return Promise.resolve(true);
  }

  async loadEmoSupportMessaging() {
    try {
      const mesRes = await this.messagingService
        .getByMessageTypeAndLanguage(this.env.covid19Testing.symptomatic.applicationId, 'EMO_SUP_BANNER_TRES').toPromise();
      this.emoSupportTitle = mesRes.msgs.filter(m => m.id === 'WWEMOSUPPORTTITLE')[0].text;
      this.emoSupportMessage = mesRes.msgs.filter(m => m.id === 'WWEMOSUPPORTBODY')[0].text;
    } catch (err) {
      console.error('Unable to load booster effects messaging:', err);
    }
  }

  viewEmoSupportResources() {
    this.analytics.clickEvent('view-covid-result: View Emotional Support Resources', '');
    this.navCtrl.navigateRoot('/tabs/resources/emotional-support');
  }

  back() {
    // this.modalCtrl.dismiss();
    this.analytics.clickEvent('view-covid-result: Back', '');
    this.navCtrl.navigateBack('/tabs/home');
  }

  async getCovidTestResult(userId) {
    const covidResult: CovidResult = await this.covidResultService.getResult(userId);
    covidResult.collectDate = (covidResult.collectDate) ?
      moment(covidResult.collectDate, 'MM/DD/YYYY HH:mm').format('MMMM DD, YYYY') : null;
    covidResult.resultDate = (covidResult.resultDate) ?
      moment(covidResult.resultDate, 'MM/DD/YYYY HH:mm').format('MMMM DD, YYYY') : null;
    this.covidResult = covidResult;
    this.currentView = 'result';
    if (this.covidResult && this.covidResult.result && this.covidResult.result === 'NOT DETECTED') {
      this.markResultAsRead();
      this.analytics.customEvent('view-covid-result', 'SV', 'Successfully viewed COVID-19 test result');
    } else {
      this.analytics.customEvent('view-covid-result', 'NA', 'No COVID-19 test result found');
    }
  }

  async markResultAsRead() {
    const authUser = this.authService.getAuthUser();
    const receipt = await this.covidResultService.sendResultReadReceipt(authUser.userId);
    this.adPopupService.updateStatus('covid-result', 'read');
    // console.log('receipt', receipt);
    this.profileService.loadProfile(authUser.userId, 'self');
  }

  scheduleTesting() {
    this.analytics.clickEvent('view-covid-result: schedule-testing', '');
    this.navCtrl.navigateForward('/schedule/' + this.env.covid19Testing.symptomatic.applicationId + '/information');
  }

}

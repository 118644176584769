import { SchLocation } from 'src/app/models/sch-location';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { CampaignAvailability, CampaignVaccineAvailabilityResult } from './../../models/campaign-availability';
import { Campaign } from './../../models/campaign';
import { GuestCovidVaccineResendLinkPage } from './../guest-covid-vaccine-resend-link/guest-covid-vaccine-resend-link.page';
import { StorageService } from './../../services/_core/storage/storage.service';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { Guest } from 'src/app/models/guest';
import { MessagingService } from './../../services/messaging/messaging.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { SelectOption } from 'src/app/models/_core/select-option';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { EmployeesService } from 'src/app/services/employees/employees.service';
import { GuestsService } from 'src/app/services/guests/guests.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { QuestionsService } from 'src/app/services/questions/questions.service';
import { UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AppValue } from 'src/app/models/app-value';

@Component({
  selector: 'app-guest-covid-vaccine-register',
  templateUrl: './guest-covid-vaccine-register.page.html',
  styleUrls: ['./guest-covid-vaccine-register.page.scss'],
})
export class GuestCovidVaccineRegisterPage implements OnInit {
  env = environment;
  currentView:
    'welcome' | 'invalid-link' | 'help' | 'register' | 'loading' | 'email-resent' | 'suspended' | 'no-vaccine' | 'error' = 'loading';
  campaignId: string = null;
  dobFormatted = null;
  form1: FormGroup = this.formBuilder.group({
    dob: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/)]],
    email: ['', [Validators.required, Validators.pattern(/^.+@.+\..+$/)]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    month: ['Month', Validators.required],
    day: ['Day', Validators.required],
    year: ['Year', Validators.required],
    gender: ['', Validators.required],
    mobilePhone: ['', [Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]],
    homePhone: ['', [Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]],
    address1: ['', Validators.required],
    address2: [''],
    city: ['', Validators.required],
    state: ['MA', Validators.required],
    zip: ['', [Validators.required, Validators.minLength(5)]],
  });
  submitAttempted = false;
  showErrorMessage = false;
  showMobileOrHomePhoneError = false;
  invalidDate = false;
  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Email is invalid.' }
    ],
    firstName: [{ type: 'required', message: 'First Name is required.' }],
    lastName: [{ type: 'required', message: 'Last Name is required.' }],
    dob: [
      { type: 'required', message: 'A valid Date of Birth is required.' },
      { type: 'pattern', message: 'Date of Birth is invalid. Use MM/DD/YYYY.' }
    ],
    gender: [{ type: 'required', message: 'Gender is required.' }],
    mobilePhone: [
      { type: 'validatePhoneNumbers', message: 'Either Mobile or Home Phone number is required.' },
      { type: 'pattern', message: 'Please use the format of 000-000-0000' }
    ],
    homePhone: [
      { type: 'validatePhoneNumbers', message: 'Either Mobile or Home Phone number is required.' },
      { type: 'pattern', message: 'Please use the format of 000-000-0000' }
    ],
    address1: [{ type: 'required', message: 'Address is required.' }],
    city: [{ type: 'required', message: 'City is required.' }],
    state: [{ type: 'required', message: 'State is required.' }],
    zip: [
      { type: 'required', message: 'Zip Code is required.' },
      { type: 'minlength', message: 'Zip Code must be 5 characters (00000).' },
    ],

  };
  groupOptions: SelectOption[] = [];
  groupCityOptions: SelectOption[] = [];
  genderOptions: SelectOption[] = this.helpers.genderOptions;
  states: SelectOption[] = this.helpers.stateOptions;
  months = this.helpers.months;
  days = [];
  years = [];
  welcomeText = null;
  campaignText = null;
  medConditionsText = null;
  certainWorkersText = null;
  noVaccineText = null;
  helpText = null;
  helpPhone = null;
  hasAgreed = false;
  fieldSubs: Subscription[] = [];
  guest: Guest = {};
  email = null;
  authUser = null;
  profile = null;
  suspendInfo = null;
  isSupportMode = false;
  isProcessing = false;
  campaign: Campaign;
  vaccineAvailResult: CampaignVaccineAvailabilityResult;
  todayDate = '';
  isSpecialCampaign = false;

  constructor(
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    private messagingService: MessagingService,
    private schedulerService: SchedulerService,
    private guestsService: GuestsService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private profileService: ProfileService,
    private analytics: BhAnalyticsService,
    private storageService: StorageService,
    private modalCtrl: ModalController,
    private helpers: HelperUtilitiesService
  ) {
    this.populateDaysYears();
  }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    document.title = 'Baystate Health COVID-19 Vaccination Scheduling';
    this.todayDate = moment().format('dddd, MMMM D');
    this.route.queryParamMap.subscribe(async params => {
      try {
        if (params && params.has('r') && params.get('r')) {
          this.campaignId = params.get('r').toLowerCase();
          // Check for support mode
          // this.checkForSupportMode();
          this.isSupportMode = await this.schedulerService.isUsingSupportMode();
          // Check for suspended state
          await this.checkForSuspendStatus();
          if (this.suspendInfo && this.suspendInfo.value === 'Y') {
            this.analytics.clickEvent('GuestReg: Suspended', this.campaignId);
            this.currentView = 'suspended';
          } else {
            // Check for valid campaign
            const validCampaign = await this.checkCampaignId();
            if (validCampaign) {
              await this.loadMessaging();
              // Check for available vaccine
              // Setting default age to 0
              this.vaccineAvailResult =
                await this.schedulerService.getCampaignVaccineAvailability(this.campaign, false, '', 0, false, false, null);
              if (this.vaccineAvailResult && this.vaccineAvailResult.hasAvailability) {
                try {
                  // await this.loadGroupValues();
                  this.validateAccess();
                  this.subscribeToGuestFields();
                  this.checkForSpecialCampaign();
                } catch (err) {
                  console.error('Error occurred', err);
                }
              } else {
                this.analytics.clickEvent('GuestReg: No vaccine', this.campaignId);
                this.currentView = 'no-vaccine';
              }
            } else {
              this.analytics.clickEvent('GuestReg: Invalid link', this.campaignId);
              this.currentView = 'invalid-link';
            }
          }

        } else {
          // No params or campign ID (r) param found
          this.analytics.clickEvent('GuestReg: Invalid link', this.campaignId);
          this.currentView = 'invalid-link';
        }
      } catch (err) {
        console.error('Error occurred', err);
        this.currentView = 'error';
      }
    },
      err => {
        this.analytics.clickEvent('GuestReg: Invalid link', this.campaignId);
        this.currentView = 'invalid-link';
      });
  }

  ionViewDidLeave() {
    this.fieldSubs.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  populateDaysYears() {
    this.days = [];
    this.years = [];

    // Populate days
    for (let i = 1; i < 32; i++) {
      this.days.push(i);
    }

    // Populate years
    const today = moment();
    const year = Number(today.format('YYYY'));
    for (let y = year; y > 1900; y--) {
      this.years.push(y);
    }
  }

  checkForSpecialCampaign() {
    // If Special Campaign, use Special Campaign Priority Group
    if (this.campaign.camSeq !== this.env.covid19Vaccine.guestCamSeq) {
      this.isSpecialCampaign = true;
    }
  }

  // async checkForSupportMode(): Promise<boolean> {
  //   const supportMode = await this.storageService.getData('KioskMode');
  //   // console.log('KioskMode', supportMode);
  //   this.isSupportMode = (supportMode && supportMode.enabled === true) ? true : false;
  //   return Promise.resolve(true);
  // }

  async checkForSuspendStatus(): Promise<boolean> {
    // Check for suspend flag
    const suspendRes = await this.schedulerService.getAppValues(this.env.covid19Vaccine.applicationId, 'SUSPENDREG').toPromise();
    // console.log('suspendInfo', suspendRes);
    if (suspendRes && suspendRes.appvalues.length > 0) {
      this.suspendInfo = suspendRes.appvalues[0];
    }
    return Promise.resolve(true);
  }

  async checkCampaignId(): Promise<boolean> {
    try {
      if (this.isSupportMode && this.campaignId === 'supportmode') {
        return Promise.resolve(true);
      } else {
        const camRes = await this.schedulerService.getCampaignById(this.campaignId, true, true, true, false).toPromise();
        if (
          (
            camRes &&
            camRes.campaigns &&
            camRes.campaigns.filter(c => c.active === 1).length > 0
          )
        ) {
          // valid campaign
          try {
            this.campaign = camRes.campaigns.filter(c => c.active === 1)[0];
            this.analytics.customEvent('hit', 'guest-registration', this.campaignId);
          } catch (err) {
            console.error('Unable to register hit');
          }
          return Promise.resolve(true);
        } else {
          // invalid campaign
          return Promise.resolve(false);
        }
      }
    } catch (err) {
      if (err.error && err.error.message && err.error.message.toLowerCase().indexOf('not found') > -1) {
        return Promise.resolve(false);
      } else {
        console.error('Error occurred during campaign validation', err);
        this.currentView = 'error';
      }
    }
  }

  async loadMessaging(): Promise<boolean> {
    this.welcomeText = await this.getMessaging('WWGUESTREGWELCOME');
    this.helpText = await this.getMessaging('WWHELPEXT');
    this.helpPhone = await this.getMessaging('WWHELPPHONEEXT');
    this.medConditionsText = await this.getMessaging('WWMEDCONDITIONS');
    this.certainWorkersText = await this.getMessaging('WWCERTAINWORKERS');
    this.noVaccineText = await this.getMessaging('WWGUESTREGNOVACC');
    this.campaignText = await this.getCampaignInstructions();
    return Promise.resolve(true);
  }

  async getMessaging(messageId): Promise<string> {
    try {
      const appRes = await this.messagingService.getByIdAndLanguage(
        this.env.covid19Vaccine.applicationId,
        messageId
      ).toPromise();
      // console.log('appRes for getMessaging()', appRes);
      const msgText = (appRes.msg) ? appRes.msg.text : null;
      return Promise.resolve(msgText);
    } catch (err) {
      console.error('Error occurred while loading messaging', err);
      this.currentView = 'error';
    }
  }

  async getCampaignInstructions() {
    try {
      const appRes = await this.messagingService.getByIdAndSubIdAndLanguage(
        this.env.covid19Vaccine.applicationId,
        'WWCAMPAIGNINST', this.campaign.camSeq
      ).toPromise();
      // console.log('appRes for getMessaging()', appRes);
      const msgText = (appRes.msg) ? appRes.msg.text : null;
      return Promise.resolve(msgText);
    } catch (err) {
      console.error('Error occurred while loading messaging', err);
      this.currentView = 'error';
    }
  }

  async subscribeToGuestFields() {
    this.fieldSubs.push(
      this.form1.controls.firstName.valueChanges.subscribe(val => this.guest.firstName = val),
      this.form1.controls.lastName.valueChanges.subscribe(val => this.guest.lastName = val),
      // Add date logic
      this.form1.controls.month.valueChanges.subscribe(val => this.validateBirthDate()),
      this.form1.controls.day.valueChanges.subscribe(val => this.validateBirthDate()),
      this.form1.controls.year.valueChanges.subscribe(val => this.validateBirthDate()),
      this.form1.controls.dob.valueChanges.subscribe(val => this.guest.dob = val),
      this.form1.controls.email.valueChanges.subscribe(val => this.guest.email = val),
      this.form1.controls.gender.valueChanges.subscribe(val => this.guest.gender = val),
      this.form1.controls.mobilePhone.valueChanges.subscribe(
        val => {
          this.guest.phone = val;
          this.guest.mobilePhone = val;
          this.showMobileOrHomePhoneError = !this.validatePhoneNumbers();
        }),
      this.form1.controls.homePhone.valueChanges.subscribe(
        val => {
          this.guest.homePhone = val;
          this.showMobileOrHomePhoneError = !this.validatePhoneNumbers();
        }
      ),
      this.form1.controls.address1.valueChanges.subscribe(val => this.guest.address1 = val),
      this.form1.controls.address2.valueChanges.subscribe(val => this.guest.address2 = val),
      this.form1.controls.city.valueChanges.subscribe(val => this.guest.city = val),
      this.form1.controls.state.valueChanges.subscribe(val => this.guest.state = val),
      this.form1.controls.zip.valueChanges.subscribe(val => this.guest.zip = val),
    );
  }

  async validateEmailAndBirthdate(email, dob): Promise<boolean> {
    try {
      this.email = email;
      // console.log('Validating email', email);
      if (this.form1.controls.email.valid) {
        const emailRes = await this.guestsService.validateEmailAndBirthdate(email, dob).toPromise();
        // console.log('Email validation response', emailRes);
        const emailExists = (emailRes.inUse === 1);
        if (emailExists) {
          this.presentEmailInUseAlert();
          return Promise.resolve(false);
        } else {
          return Promise.resolve(true);
        }
      } else {
        return Promise.resolve(false);
      }
    } catch (err) {
      if (err.error.message.indexOf('Validation Failed:') > -1) {
        this.notificationsService.showAlert('Email Format Error',
          'The format of the email you entered is not valid. Please check that it was entered correctly.');
      } else {
        console.error('Error occurred while validating email/dob', err);
        this.currentView = 'error';
      }
    }
  }

  async presentEmailInUseAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Already registered',
      message: 'Our records indicate someone was previously registered with this email and birthdate.' +
        ' You cannot register again using this information but we can send this email a link to schedule or manage appointments.',
      cssClass: 'wide-alert reverse-buttons',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Go back',
          cssClass: 'font-color-primary',
          handler: () => {
          }
        },
        {
          text: 'Resend appointment link',
          cssClass: 'font-color-primary',
          handler: async () => {
            try {
              const emailRes = await this.guestsService.resendAccessKeyEmail(this.guest.email, this.dobFormatted).toPromise();
              this.currentView = 'email-resent';
            } catch (err) {
              this.currentView = 'email-resent';
            }
          },
        },
      ]
    });
    await alert.present();
  }

  goToLogin() {
    this.navCtrl.navigateBack('/login');
  }

  backToWelcome() {
    this.analytics.clickEvent('GuestReg: Back to Welcome', this.campaignId);
    this.currentView = 'welcome';
    document.querySelector('#content-wrapper').scrollTo(0, 0);
  }

  async startRegistration() {
    this.notificationsService.startLoading();
    // Setting default age to 0
    this.vaccineAvailResult =
      await this.schedulerService.getCampaignVaccineAvailability(this.campaign, false, '', 0, false, false, null);
    if (this.vaccineAvailResult && this.vaccineAvailResult.hasAvailability) {
      try {
        // Register campaign seat
        const seatRes = await this.schedulerService.registerCampaignSeat(this.campaign.camSeq, 'PENDING').toPromise();
        if (seatRes) {
          const seatSeq = seatRes.seatSeq;
          this.schedulerService.seatSeq = seatSeq;
        }
        this.analytics.clickEvent('GuestReg: Start registration', this.campaignId);
        this.currentView = 'register';
        document.querySelector('#content-wrapper').scrollTo(0, 0);
        this.notificationsService.stopLoading();
      } catch (err) {
        this.notificationsService.stopLoading();
        console.error('Error occurred', err);
      }
    } else {
      this.notificationsService.stopLoading();
      this.analytics.clickEvent('GuestReg: No vaccine', this.campaignId);
      this.currentView = 'no-vaccine';
    }
  }

  async validateAccess() {
    if (!this.campaignId) {
      this.analytics.clickEvent('GuestReg: Invalid link (NoCampaign)', this.campaignId);
      this.currentView = 'invalid-link';
    } else {
      this.guest.regSource = 'R';
      this.guest.testAsProd = 1;
      this.guest.embId = 'EMBRESETGK';
      this.guest.state = 'MA';
      this.guest.campaign = this.campaignId;
      this.guest.camSeq = this.campaign.camSeq;
      this.currentView = 'welcome';
      this.analytics.clickEvent('GuestReg: Verification success', this.campaignId);
    }
  }

  async validateBirthDate(): Promise<boolean> {
    const month = this.form1.controls.month.value;
    const day = this.form1.controls.day.value;
    const year = this.form1.controls.year.value;
    const dobMoment = moment(year + '-' + month + '-' + day, 'YYYY-M-D', true);
    const today = moment();
    this.dobFormatted = dobMoment.format('MM/DD/YYYY');
    this.invalidDate = (this.dobFormatted === 'Invalid date');
    if (!this.invalidDate) {
      const yearsOld = today.diff(dobMoment, 'year');
      if (yearsOld >= this.env.covid19Vaccine.minRegAge) {
        if (!this.validateAudience()) {
          this.showAudienceIneligibleAlert();
          return Promise.resolve(false);
        } else {
          const validEmailandBirthdate = await this.validateEmailAndBirthdate(this.guest.email, this.dobFormatted);
          if (validEmailandBirthdate) {
            this.form1.controls.dob.setValue(this.dobFormatted);
            this.guest.dob = this.dobFormatted;
            return Promise.resolve(true);
          } else {
            return Promise.resolve(false);
          }
        }
      } else {
        this.notificationsService.showAlert('This vaccine isn\'t available to you',
          'This vaccine is only available for individuals ' + this.env.covid19Vaccine.minRegAge + ' years or older.');
        this.form1.controls.month.setValue('Month');
        this.form1.controls.day.setValue('Day');
        this.form1.controls.year.setValue('Year');
        this.form1.controls.dob.setValue('');
        this.guest.dob = null;
        return Promise.resolve(false);
      }
    }
    return Promise.resolve((this.dobFormatted !== 'Invalid date'));
  }

  validateAudience() {
    // 2021.01.27 = For first responders, and patients 55+
    const month = this.form1.controls.month.value;
    const day = this.form1.controls.day.value;
    const year = this.form1.controls.year.value;
    const dobMoment = moment(year + '-' + month + '-' + day, 'YYYY-M-D', true);
    const today = moment();
    const yearsOld = today.diff(dobMoment, 'year');
    if (
      (this.guest.groupCode === 'PATNT' && yearsOld >= 55) ||
      this.guest.groupCode !== 'PATNT'
    ) {
      return true;
    } else {
      return false;
    }
  }

  showAudienceIneligibleAlert() {
    this.notificationsService.showAlert('You are not eligible yet',
      'At this time, individuals aged 55 or older, individuals with 2 certain health conditions, certain workers' +
      ' K-12 education workers, first responders, healthcare workers, congregate care workers, and ' +
      ' low income/senior housing workers are allowed to register. Please check back later. <br><br>' +
      'For more information on vaccine eligibility, please see: ' +
      '<a href="https://www.mass.gov/info-details/massachusetts-covid-19-vaccination-phases" target="_blank" ' +
      'class="font-color-primary">Massachusetts\' COVID-19 vaccination phases</a>');
  }

  validatePhoneNumbers() {
    const mobilePhone = this.form1.controls.mobilePhone.value;
    const homePhone = this.form1.controls.homePhone.value;
    return mobilePhone !== '' || homePhone !== '';
  }

  async register() {
    this.isProcessing = true;
    this.submitAttempted = true;
    const validBirthDate = await this.validateBirthDate();
    // Check form
    if (validBirthDate) {
      if (this.form1.valid && this.validatePhoneNumbers()) {
        if (this.validateAudience()) {
          this.notificationsService.startLoading();
          this.guest.supportreg = (this.isSupportMode) ? 1 : 0;
          // Assign Special Campaign
          if (this.isSpecialCampaign) {
            this.guest.groupCode = this.env.covid19Vaccine.specialGuestGroupId;
            this.guest.group = this.campaign.description;
          } else {
            this.guest.groupCode = this.env.covid19Vaccine.genGuestGroupId;
            this.guest.group = 'General Public (or Proxy)';
          }
          this.isProcessing = false;
          try {
            const regRes = await this.guestsService.register(this.guest).toPromise();
            if (regRes.x_status === 'S') {
              // Trigger account creation using guest login
              const response = await this.authService.loginGuest(
                this.guest.email,
                regRes.genKey,
                this.guest.dob,
                this.env.covid19Vaccine.applicationId
              ).toPromise();
              if (response && response.error && response.error.message && response.error.message.indexOf('Invalid') > -1) {
                // Invalid genKey or birthdate detected
                this.notificationsService.stopLoading();
                this.notificationsService.showAlert('Error', 'An unexpected error occurred while registering. Please try again.');
              } else {
                // Valid user detected
                this.authUser = this.authService.getAuthUser();
                if (this.authUser && this.authUser.userId) {
                  this.analytics.loginEvent(this.authUser.userId);
                  await this.profileService.loadProfile(this.authUser.userId, 'guest', 'Preparing profile');
                  this.notificationsService.stopLoading();
                  this.profile = this.profileService.getProfile();
                  if (this.profile) {
                    this.notificationsService.stopLoading();
                    // Launch scheduler
                    this.analytics.clickEvent('GuestReg: register user', 'Valid user; opening scheduler');
                    this.openScheduler();
                  }
                } else {
                  this.notificationsService.stopLoading();
                  this.notificationsService.showAlert('Error', 'An unexpected error occurred while registering. Please try again.');
                  this.isProcessing = false;
                }
              }
            } else {
              this.notificationsService.stopLoading();
              this.notificationsService.showAlert('Error', 'An unexpected error occurred while registering. Please try again.');
              this.isProcessing = false;
            }
          } catch (err) {
            this.notificationsService.stopLoading();
            // this.notificationsService.showAlert('Error', 'An unexpected error occurred while registering. Please try again.');
            console.error('Error occurred while registering', err);
            this.currentView = 'error';
            this.isProcessing = false;
          }
        } else {
          this.showAudienceIneligibleAlert();
          this.isProcessing = false;
        }
      } else {
        if (!this.form1.valid) {
          this.notificationsService.showAlert('Please complete required fields',
            'There are one or more empty required fields detected. Please complete each red-marked field.');
        } else if (!this.validatePhoneNumbers()) {
          this.notificationsService.showAlert('Phone number required',
            'Enter a mobile or home phone number to continue. Only one is required.');
        }
        this.isProcessing = false;
      }
    } else {
      this.notificationsService.showAlert('Please complete required fields',
        'There are one or more empty required fields detected. Please complete each red-marked field.');
      this.isProcessing = false;
    }
  }

  openScheduler() {
    const schApplicationId = this.env.covid19Vaccine.applicationId;
    this.schedulerService.scheduleCovidVaccineType = 'any';
    this.navCtrl.navigateForward(`/guest/schedule/${schApplicationId}/information`);
  }

  reload() {
    window.location.reload();
  }

}


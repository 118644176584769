import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ManageMessagingPopupPage } from './manage-messaging-popup.page';
import { ManageMessagingPopupPageRoutingModule } from './manage-messaging-popup-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ManageMessagingPopupPageRoutingModule
  ],
  declarations: [ManageMessagingPopupPage]
})
export class ManageMessagingPopupPageModule {}

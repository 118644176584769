import { BhAdPopupComponentModule } from './../../components/bh-ad-popup/bh-ad-popup.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CovidVaccineAdPage } from './covid-vaccine-ad.page';
import { CovidVaccineAdPageRoutingModule } from './covid-vaccine-ad-routing.module';

@NgModule({
  imports: [
    BhAdPopupComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    CovidVaccineAdPageRoutingModule
  ],
  declarations: [CovidVaccineAdPage]
})
export class CovidVaccineAdPageModule {}

import { Platform } from '@ionic/angular';
import { User } from './../../models/user';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserServiceResponse } from 'src/app/models/user-service-response';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  env = environment;
  isAndroid = false;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private platform: Platform,
  ) {
    if (this.platform.is('android')) {
      this.isAndroid = true;
    } else {
      this.isAndroid = false;
    }
  }

  getAll(): Observable<any> {
    const url = `${this.env.apiUrl}/users?formId=${this.env.covid19Screening.formId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Users/getAll');
          }
          return res;
        })
      );
  }

  getAdminsOnly(): Observable<any> {
    const url = `${this.env.apiUrl}/users?formId=${this.env.covid19Screening.formId}&adminOnly=1`;
    return this.http.get(url)
      .pipe(
        tap((res: UserServiceResponse) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Users/getAdminsOnly');
          }
          return res;
        })
      );
  }

  getById(id): Observable<any> {
    // console.log('getById id', id);
    const url = `${this.env.apiUrl}/users/${id}?formId=${this.env.covid19Screening.formId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Users/getById');
          }
          return res;
        })
      );
  }

  getUserStatus(id): Observable<any> {
    // console.log('getById id', id);
    const url = `${this.env.apiUrl}/users/${id}/status?formId=${this.env.covid19Screening.formId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Users/getUserStatus');
          }
          return res;
        })
      );
  }

  updateCOVIDPOS(user: User) {
    const url = `${this.env.apiUrl}/users/current/covidpos`;
    const body = {userId: user.userId, cpInterviewStartDateTime: user.cpInterviewStartDateTime};
    return this.http.post(url, body)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'updateCOVIDPOS: users/post');
          }
          return res.x_status;
        })
      );
  }

  reloadUser(id, formId) {
    // console.log('getById id', id);
    const url = `${this.env.apiUrl}/users/${id}?formId=${formId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching User/getById');
          }
          return res;
        })
      );
  }

  reloadUserStatus(id, formId) {
    // console.log('getById id', id);
    const url = `${this.env.apiUrl}/users/${id}/status?formId=${formId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching User/getById');
          }
          return res;
        })
      );
  }


  create(user: User) {
    const url = `${this.env.apiUrl}/users`;
    return this.http.post(url, user)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Users/create');
          }
          return res.x_status;
        })
      );
  }

  update(user: User) {
    const url = `${this.env.apiUrl}/users/${user.userId}?formId=${this.env.covid19Screening.formId}`;
    user.formId = this.env.covid19Screening.formId;
    return this.http.put(url, user)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Users/update');
          }
          return res.x_status;
        })
      );
  }


  delete(user: User) {
    const url = `${this.env.apiUrl}/users/${user.userId}`;
    return this.http.delete(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Users/delete');
          }
          return res.x_status;
        })
      );
  }

  /***
   * Get user's OHM Vaccine data
   */
   getOhmVaccineHistory(userId = null): Observable<any> {
    // Prepare request
    const userIdParam = userId ? `?userId=${userId}`: '';
    const url = environment.apiUrl + `/users/current/ohm-vaccines${userIdParam}`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(error => of(error)
      )
    );
  }

  /***
   * Impersonate User
   */
  impersonateUser(userId): Observable<any> {
    // Prepare request
    const url = environment.apiUrl + `/users/current?impersonationUserId=${userId}&formId=${this.env.covid19Screening.formId}`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }

  getAssignments(userId, schedDate) {
    const url = environment.apiUrl + `/users/assignments?schedDate=${schedDate}&activeOnly=1&userId=${userId}`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }

}

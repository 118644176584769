import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reminders-ad',
  templateUrl: './reminders-ad.page.html',
  styleUrls: ['./reminders-ad.page.scss'],
})
export class RemindersAdPage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
    this.analytics.clickEvent('Reminders-ad: Viewed:', '');
  }

  async setReminders() {
    this.dismiss('read');
  }

  dismiss(returnValue) {
    this.analytics.clickEvent('Reminders-ad: Action:', returnValue);
    this.modalCtrl.dismiss(returnValue);
  }

}

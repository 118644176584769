import { from } from 'rxjs';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { BhAnalyticsService } from './../../services/_core/bhanalytics/bhanalytics.service';
import { StorageService } from './../../services/_core/storage/storage.service';
import { SchLocation } from 'src/app/models/sch-location';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { SchTimeSlot, SchTimeSlotGroup } from './../../models/sch-timeslot';
import { SchAppointment } from './../../models/sch-appointment';
import { Component, Output, EventEmitter, OnChanges, Input, SimpleChanges } from '@angular/core';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import * as moment from 'moment';

@Component({
  selector: 'bh-timechips',
  templateUrl: './bh-timechips.component.html',
  styleUrls: ['./bh-timechips.component.scss'],
})
export class BhTimechipsComponent implements OnChanges {
  @Input() selectedDay;
  @Input() visit: SchAppointment;
  @Input() location: SchLocation;
  @Input() vaccine: SchVaccine;
  @Input() noAvailabilityMessage = '';
  @Input() camSeq: number = null;
  @Output() setTime = new EventEmitter();
  @Output() isLoading = new EventEmitter();
  // showSpinner = true;
  selectedDateTime = null;
  groups: SchTimeSlotGroup[] = [];
  timeslots: SchTimeSlot[] = [];
  visibleTimeslots: SchTimeSlot[] = [];
  isWalkIn = false;
  isSupportMode = false;
  showWalkInTimeslot = false;
  today = moment();
  dayHasAnyAvailability = false;
  currentView: 'loading' | 'select-timeslots' | 'no-timeslots' = 'loading';
  latestTimeSlot: SchTimeSlot;

  constructor(
    private schedulerService: SchedulerService,
    private notifications: NotificationsService,
    private storageService: StorageService,
    private analytics: BhAnalyticsService
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log('TimeChipsComp: Received visit', this.visit);
    if (('selectedDay' in changes || 'location' in changes) && this.location && this.location.locSeq) {
      this.loadTimeSlots();
      this.isSupportMode = await this.schedulerService.isUsingSupportMode();
      const today = moment();
      if (this.isSupportMode && today.format('MM/DD/YYYY') === this.selectedDay.format('MM/DD/YYYY')) {
        this.showWalkInTimeslot = true;
      }
    }
  }

  // async checkForSupportMode(): Promise<boolean> {
  //   const supportMode = await this.storageService.getData('KioskMode');
  //   // console.log('KioskMode', supportMode);
  //   this.isSupportMode = (supportMode && supportMode.enabled === true) ? true : false;
  //   // IF today, show walk-in timeslot
  //   const today = moment();
  //   if (this.isSupportMode && today.format('MM/DD/YYYY') === this.selectedDay.format('MM/DD/YYYY')) {
  //     this.showWalkInTimeslot = true;
  //   }
  //   return Promise.resolve(true);
  // }

  async loadTimeSlots() {
    this.currentView = 'loading';
    this.isLoading.emit(true);
    // this.showSpinner = true;
    this.timeslots = [];
    this.groups = [];
    this.selectedDateTime = null;
    const todayDate = this.selectedDay.format('MM/DD/YYYY');
    const limitSlot = this.vaccine.lookAhead;
    for (let i = 0; i < 3; i++) {
      try {
        const data = await this.schedulerService.getTimeSlotAvailability(
          this.location.locSeq, todayDate, todayDate, null, null, this.camSeq, this.vaccine.cvaSeq, limitSlot, 1).toPromise();
        this.timeslots = (data.slots) ? data.slots.filter(s => s.availabilityDate === todayDate) : [];
        this.groupTimeSlots(this.timeslots);
        break;
      } catch (err) {
        console.error('Unable to get data, trying again', err);
      }
    }

    // Check if day availability limit met
    this.dayHasAnyAvailability = this.checkDayAvailability();
    if (this.dayHasAnyAvailability || this.showWalkInTimeslot) {
      this.currentView = 'select-timeslots';
      const availableTimeSlots = this.timeslots.filter(t => t.availabilitySlots > 0);
      this.latestTimeSlot = availableTimeSlots[availableTimeSlots.length - 1];
      if (!this.visit.reschedule) {
        if (availableTimeSlots.length > 0) {
          this.visit.timeslot = this.latestTimeSlot;
          this.visit.tsSeq = this.latestTimeSlot.tsSeq;
          const latestDateMoment = moment(this.latestTimeSlot.availabilityDate + ' ' + this.latestTimeSlot.startTime, 'M/D/YYYY HH:mm');
          this.visit.camSeq = this.schedulerService.appointment.campaign.camSeq;
          this.visit.scheduleDate = latestDateMoment.format('MM/DD/YYYY hh:mm A');
          this.visit.scheduleDateFormatted = latestDateMoment.format('MMM D, YYYY h:mm A');
          this.visit.adminStatus = 'PENDING';
          this.visit.valid = true;
          this.schedulerService.lockTimeslot(this.visit);
        } else {
          console.log('Unable to pre-lock time slot. No available timeslots', availableTimeSlots);
        }
      }
    } else {
      this.currentView = 'no-timeslots';
    }
    this.isLoading.emit(false);
  }

  groupTimeSlots(timeslots: SchTimeSlot[]) {
    const now = moment();
    timeslots.forEach(ts => ts.show = null);
    timeslots.forEach(ts => {
      if (ts.show === null) {
        const timeslot = this.checkForDuplicateTimeslots(timeslots, ts);
        // console.log('Checked timeslot', timeslots, timeslot);
        const timeslotMoment = moment(this.selectedDay.format('MM/DD/YYYY') + ' ' + timeslot.startTime, 'MM/DD/YYYY HH:mm', true);
        timeslot.startTimeFormatted = timeslotMoment.format('h:mm a');
        // Check that timestamp doesn't occur before current time
        if (now < timeslotMoment) {
          let addNewGroup = true;
          const startTime = timeslot.startTime;
          const hour = startTime.split(':')[0];
          const hourLabel = hour + ':00';
          const groupName = moment('01/01/1900 ' + startTime, 'MM/DD/YYYY HH:mm').format('h:mm a');

          // Check for existing group match
          this.groups.forEach(grp => {
            const grpHour = grp.hour.split(':')[0];
            if (grpHour === hour) {
              grp.timeSlots.push(timeslot);
              grp.availabilitySlots += timeslot.availabilitySlots;
              addNewGroup = false;
              if (grp.hasAvailability === false && timeslot.availabilitySlots > 0) {
                grp.hasAvailability = true;
                this.dayHasAnyAvailability = true;
              }
            }
            // console.log('Updated timeslot group', grp);
          });

          // Add new group
          if (addNewGroup) {
            const newGroup: SchTimeSlotGroup = {
              name: groupName,
              hour: hourLabel,
              timeSlots: [timeslot],
              availabilitySlots: timeslot.availabilitySlots,
              hasAvailability: (timeslot.availabilitySlots > 0)
            };

            if (newGroup.hasAvailability) {
              this.dayHasAnyAvailability = true;
            }
            this.groups.push(newGroup);
          }

        }

      }
    });

  }

  checkForDuplicateTimeslots(timeslots: SchTimeSlot[], validatingTimeslot: SchTimeSlot): SchTimeSlot {
    // Check for any duplicate timeslots
    const duplicateTimeslots = (timeslots.length > 0) ? timeslots.filter(t => t.startTime === validatingTimeslot.startTime) : null;
    if (duplicateTimeslots.length > 0) {
      // Hide all duplicate timeslots (show = false)
      duplicateTimeslots.forEach(t => t.show = false);
      // Check for any campaign timeslots with availability; order by most availability
      const campaignTimeslots = duplicateTimeslots
        .filter(t => t.camSeq > 0 && t.availabilitySlots > 0)
        .sort((a, b) => {
          if (a.availabilitySlots > b.availabilitySlots) {
            return -1;
          }
          if (a.availabilitySlots < b.availabilitySlots) {
            return 1;
          }
          return 0;
        });
      if (campaignTimeslots.length > 0) {
        // Check for campaign timeslot with any availability
        const availableCampaignTimeslots = campaignTimeslots.filter(t => t.availabilitySlots > 0);
        if (availableCampaignTimeslots.length > 0) {
          // Return first timeslot with most availability
          availableCampaignTimeslots[0].show = true;
          return availableCampaignTimeslots[0];
        } else {
          // Duplicate campaign timeslots with no availability; return first
          campaignTimeslots[0].show = true;
          return campaignTimeslots[0];
        }
      } else {
        // Duplicate non-campaign or no-availability timeslots; return any with availability
        duplicateTimeslots[0].show = true;
        return duplicateTimeslots[0];
      }
    } else {
      validatingTimeslot.show = true;
      return validatingTimeslot;
    }
  }

  checkDayAvailability() {
    let dayAvailability = 0;
    this.timeslots.forEach((t: SchTimeSlot) => {
      const now = moment('01/01/1900 ' + moment().format('HH:mm'), 'MM/DD/YYYY HH:mm', true);
      const timeslotMoment = moment('01/01/1900 ' + t.startTime, 'MM/DD/YYYY HH:mm', true);
      // console.log('Checking day', t.availabilityDate,
      //   this.selectedDay.format('MM/DD/YYYY'), t.availabilityDate === this.selectedDay.format('MM/DD/YYYY'));
      if (t.availabilityDate === this.selectedDay.format('MM/DD/YYYY')) {
        if (t.availabilityDate === this.today.format('MM/DD/YYYY') && now.format('HH:mm') <= timeslotMoment.format('HH:mm')) {
          // Today - only look forward
          dayAvailability += t.availabilitySlots;
        } else if (t.availabilityDate !== this.today.format('MM/DD/YYYY')) {
          // Future day
          dayAvailability += t.availabilitySlots;
        }
      }
    });
    // this.timeslots = [];
    return dayAvailability > 0;
  }

  selectTime(timeslot: SchTimeSlot) {
    if (timeslot.availabilitySlots > 0) {
      this.isWalkIn = false;
      const selectedDate = this.selectedDay.format('MM/DD/YYYY');
      const selectedMoment = moment(selectedDate + ' ' + timeslot.startTime, 'MM/DD/YYYY HH:mm', true);
      this.selectedDateTime = selectedMoment.format('MM/DD/YYYY HH:mm');
      // console.log('Test timeslot value', timeslot);
      this.setTime.emit(timeslot);
    } else {
      this.notifications.showToast('This timeslot is not available.');
    }
  }

  selectWalkIn() {
    this.selectedDateTime = null;
    this.isWalkIn = true;
    const walkinTimeslot = this.latestTimeSlot;
    // console.log('Test walk-in value', walkinTimeslot);
    this.setTime.emit(walkinTimeslot);
  }

}

import { BhUserBadgeComponentModule } from '../bh-user-badge/bh-user-badge.component.module';
import { BhAppLogoComponentModule } from '../bh-app-logo/bh-app-logo.component.module';
import { BhLogoComponentModule } from '../bh-logo/bh-logo.component.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BhAppHeaderWebComponent } from './bh-app-header-web.component';

@NgModule({
  declarations: [
    BhAppHeaderWebComponent
  ],
  imports: [
    BhAppLogoComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    BhLogoComponentModule,
    BhUserBadgeComponentModule,
  ],
  exports: [
    BhAppHeaderWebComponent
  ]
})
export class BhAppHeaderWebComponentModule { }

import { BhEmptyMessageComponentModule } from './../../components/_core/bh-empty-message/bh-empty-message.component.module';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { BhAppFooterComponentModule } from 'src/app/components/_core/bh-app-footer/bh-app-footer.component.module';
import { BhVisualBannerComponentModule } from './../../components/bh-visual-banner/bh-visual-banner.component.module';
import { BhPasswordProtectionComponentModule } from './../../components/bh-password-protection/bh-password-protection.component.module';
import { BhAppHeaderMobileComponentModule } from './../../components/_core/bh-app-header-mobile/bh-app-header-mobile.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CovidTestResultPage } from './covid-test-result.page';
import { BhHeaderLargeComponentModule } from 'src/app/components/bh-header-large/bh-header-large.component.module';
import { CovidTestResultPageRoutingModule } from './covid-test-result-routing.module';

@NgModule({
  imports: [
    BhAppHeaderMobileComponentModule,
    BhHeaderLargeComponentModule,
    BhAppFooterComponentModule,
    BhPasswordProtectionComponentModule,
    BhVisualBannerComponentModule,
    BhSpinnerComponentModule,
    BhEmptyMessageComponentModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CovidTestResultPageRoutingModule
  ],
  declarations: [CovidTestResultPage]
})
export class CovidTestResultPageModule {}

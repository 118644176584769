import { TestingProfile } from './../../models/testing-profile';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CovidResult } from 'src/app/models/covid-result';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class CovidResultService {
  env = environment;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private modalCtrl: ModalController
  ) { }

  /**
   * Returns user's COVID-19 result
   *
   * @param userId User's employee ID
   */
  getResult(userId): Promise<CovidResult> {
    const url = `${this.env.apiUrl}/results/${userId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getResult():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching COVID-19 result for employee');
          }
          return res;
        })
      ).toPromise();
  }

  /**
   * Returns user's COVID-19 Testing Profile (includes result)
   *
   * @param userId User's employee ID
   */
  getTestingProfile(userId): Promise<TestingProfile> {
    const url = `${this.env.apiUrl}/users/${userId}/testing/profile`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getResult():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching COVID-19 testing profile for employee');
          }
          return res;
        })
      ).toPromise();
  }

  /**
   * Returns user's COVID-19 result
   *
   * @param userId User's employee ID
   */
  sendResultReadReceipt(userId): Promise<any> {
    const url = `${this.env.apiUrl}/results/${userId}`;
    const body = {
      formId: this.env.covid19Screening.formId
    };
    return this.http.patch(url, body)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Sending COVID-19 result read receipt');
          }
          return res;
        })
      ).toPromise();
  }

}

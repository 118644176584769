import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssignmentTextAdPageRoutingModule } from './assignment-text-ad-routing.module';

import { AssignmentTextAdPage } from './assignment-text-ad.page';
import { BhAdPopupComponentModule } from 'src/app/components/bh-ad-popup/bh-ad-popup.component.module';
import { BhValidationErrorComponentModule } from 'src/app/components/_core/bh-validation-error/bh-validation-error.component.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { IonicInputMaskModule } from '@thiagoprz/ionic-input-mask';

@NgModule({
  imports: [
    BhAdPopupComponentModule,
    ReactiveFormsModule,
    BhInputComponentModule,
    BhValidationErrorComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    AssignmentTextAdPageRoutingModule,
    IonicInputMaskModule
  ],
  declarations: [AssignmentTextAdPage],
  exports:[AssignmentTextAdPage]
})
export class AssignmentTextAdPageModule {}

import { SchVaccine } from 'src/app/models/sch-vaccine';
import { SchLocation } from 'src/app/models/sch-location';
import { ModalController } from '@ionic/angular';
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { CalendarMonth } from 'src/app/models/calendarWeek';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { SchTimeSlot } from 'src/app/models/sch-timeslot';
import { DatepickerModalPage } from 'src/app/pages/datepicker-modal/datepicker-modal.page';

@Component({
  selector: 'bh-timeslot-picker',
  templateUrl: './bh-timeslot-picker.component.html',
  styleUrls: ['./bh-timeslot-picker.component.scss'],
})
export class BhTimeslotPickerComponent implements OnChanges {
  @Input() setDate = moment().format('M/D/YYYY');
  @Input() visit: SchAppointment;
  @Input() useCalendarModal = true;
  @Input() location: SchLocation;
  @Input() vaccine: SchVaccine;
  @Input() camSeq: number;
  @Output() openCalendarEvent = new EventEmitter();
  @Output() selectTimeSlotEvent = new EventEmitter();
  today = moment();
  tomorrow = moment().add(1, 'day');
  selectedDay = moment(this.setDate, 'M/D/YYYY', true);

  months: CalendarMonth[] = [
    {
      num: 1,
      name: 'January'
    },
    {
      num: 2,
      name: 'February'
    },
    {
      num: 3,
      name: 'March'
    },
    {
      num: 4,
      name: 'April'
    },
    {
      num: 5,
      name: 'May'
    },
    {
      num: 6,
      name: 'June'
    },
    {
      num: 7,
      name: 'July'
    },
    {
      num: 8,
      name: 'August'
    },
    {
      num: 9,
      name: 'September'
    },
    {
      num: 10,
      name: 'October'
    },
    {
      num: 11,
      name: 'November'
    },
    {
      num: 12,
      name: 'December'
    },
  ];
  years = [];
  thisMonth = this.today.get('month');
  thisYear = this.today.get('year');
  selectedMonth = this.thisMonth + 1;
  selectedYear = this.thisYear;
  selectedDateTime = null;
  timeSlots: SchTimeSlot[];
  scheduleDate: string = null;
  selectedTimeSlot: SchTimeSlot = null;
  apptSub: Subscription = null;
  isAvailabilityLoading = true;
  locDescription = '';

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnChanges() {
    this.selectedDay = moment(this.setDate, 'M/D/YYYY', true);
    // console.log('TimeSlotPickerComp: Received visit', this.visit);
  }

  getNextDay() {
    if (!this.isAvailabilityLoading) {
      this.selectedDateTime = null;
      const nextDay = this.selectedDay.add(1, 'day');
      this.selectedDay = moment(nextDay);
      this.scheduleDate = '';
    }
  }

  getPrevDay() {
    if (!this.isAvailabilityLoading) {
      if (this.today.format('M/D/YYYY') !== this.selectedDay.format('M/D/YYYY')) {
        this.selectedDateTime = null;
        const previousDay = this.selectedDay.add(-1, 'day');
        this.selectedDay = moment(previousDay);
        this.scheduleDate = '';
      }
    }
  }

  async openCalendar() {
    if (this.useCalendarModal) {
      const modal = await this.modalCtrl.create({
        component: DatepickerModalPage,
        cssClass: 'action-sheet-modal',
        componentProps: {
          setDate: this.selectedDay.format('M/D/YYYY'),
          location: this.location
        }
      });

      modal.onDidDismiss().then((data: any) => {
        // console.log('data')
        if (data.data && data.data.selectedDate) {
          this.selectedDay = data.data.selectedDate;
        }
      });

      return await modal.present();
    } else {
      this.openCalendarEvent.emit();
    }
  }

  setTime(timeslot: SchTimeSlot) {
    const selectedDate = this.selectedDay.format('MM/DD/YYYY');
    const selectedMoment = moment(selectedDate + ' ' + timeslot.startTime, 'MM/DD/YYYY HH:mm', true);
    this.selectedDateTime = selectedMoment.format('MM/DD/YYYY HH:mm');
    this.selectedTimeSlot = timeslot;
    this.selectTimeSlotEvent.emit(this.selectedTimeSlot);
  }

  onIsLoading(evt) {
    // Do nothing for now
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RemindersAdMonkeypoxPageRoutingModule } from './reminders-ad-monkeypox-routing.module';

import { RemindersAdMonkeypoxPage } from './reminders-ad-monkeypox.page';
import { BhAdPopupComponentModule } from 'src/app/components/bh-ad-popup/bh-ad-popup.component.module';

@NgModule({
  imports: [
    BhAdPopupComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    RemindersAdMonkeypoxPageRoutingModule
  ],
  declarations: [RemindersAdMonkeypoxPage]
})
export class RemindersAdMonkeypoxPageModule {}

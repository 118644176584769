import { VaccineType } from './../../models/vaccine-type';
import { QuestionsService } from 'src/app/services/questions/questions.service';
import { Workflow } from 'src/app/models/workflow';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { SelectOption } from 'src/app/models/_core/select-option';
import { CampaignVaccineAvailabilityResult } from './../../models/campaign-availability';
import { User } from 'src/app/models/user';
import { SchVaccineZip, SchVaccineAvailabilityValidation } from './../../models/sch-vaccine';
import { SchLocation } from 'src/app/models/sch-location';
import { Campaign } from './../../models/campaign';
import { Guest } from 'src/app/models/guest';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { NavController, AlertController } from '@ionic/angular';
import { AppValue } from './../../models/app-value';
import { SchAppointment, SchAppointmentValidation } from './../../models/sch-appointment';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, last } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CampaignAvailability } from 'src/app/models/campaign-availability';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { StorageService } from '../_core/storage/storage.service';
import * as moment from 'moment';
import { NextDoseInfo } from 'src/app/models/next-dose-info';
@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  env = environment;
  apptSubject: BehaviorSubject<SchAppointment> = new BehaviorSubject({});
  visitSubject: BehaviorSubject<SchAppointment> = new BehaviorSubject({});
  relationshipValues: AppValue[] = [];
  timeslotTimer = null;
  timeslotSchApplicationId = null;
  timeslotTimeSubject: BehaviorSubject<number> = new BehaviorSubject(null);
  workflow: Workflow[] = [];
  guardianConsentText = null;
  consentText = null;
  skipConsent = false;
  empCampaign = null;

  // Global Working Appointment Object
  visit: SchAppointment = null; // Working visit for changes, etc.
  appointment: SchAppointment = null;
  seatSeq: number = null;
  noVaccineText = '';
  scheduleFitTestingSeqs: number[] = [];
  scheduleCovidVaccineType: VaccineType = null;
  scheduleCovidSymptomaticStatus = 0;
  scheduleCovidVaccineBoosterSeqs: number[] = [];
  scheduleCovidTestingSeqs: number[] = [];
  scheduleCovidTestingExemptSeqs: number[] = [];
  covidVaccineFirstSeriesSeqs: number[] = [];
  covidVaccineBoosterSeqs: number[] = [];
  covidVaccineBoosterMaps: string[] = [];

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private authService: AuthService,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private storageService: StorageService,
  ) {
  }

  /**
   * Set or update New Appointment variable
   *
   * @param appt Appointmennt object to update
   */
  setAppointment(appt: SchAppointment) {
    this.apptSubject.next(appt);
  }

  /**
   * Get New Appointment variable
   */
  getAppointment(): SchAppointment {
    return this.apptSubject.getValue();
  }

  /**
   * Determines if the device is in support mode
   *
   * @returns If true, device is in support mode
   */
  async isUsingSupportMode(): Promise<boolean> {
    const supportMode = await this.storageService.getData('KioskMode');
    const result = (supportMode && supportMode.enabled === true) ? true : false;
    return Promise.resolve(result);
  }

  /**
   * Load all locations
   *
   * @returns Observable Response Object
   */
  getLocationsAll(): Observable<any> {
    const url = `${this.env.apiUrl}/locations`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getLocations():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Locations');
          }
          return res;
        })
      );
  }

  /**
   * Get application specific locations
   *
   * @param applicationName Application Name (schApplication)
   * @returns Observable Response Object
   */
  getLocations(applicationName): Observable<any> {
    const url = `${this.env.apiUrl}/locations?application=${applicationName}&openOnly=1&activeOnly=1`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getLocations():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Locations');
          }
          return res;
        })
      );
  }

  /**
   * Get Location by SEQ
   *
   * @param locationSeq Location SEQ
   * @returns Observable Response Object
   */
  getLocationById(locationSeq): Observable<any> {
    const url = `${this.env.apiUrl}/locations/${locationSeq}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getLocationById():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Location by Id');
          }
          return res;
        })
      );
  }

  /**
   * Get Appointments by User ID
   *
   * @param userId User's ID
   * @param schApplicationId SCH Application Name
   * @param activeOnly If true, returns only active records
   * @returns Observable Response Object
   */
  getAppointmentsByUserIdAndSchApp(userId, schApplicationId, activeOnly = true): Observable<any> {
    const activeOnlyParam = activeOnly ? '&activeOnly=1' : '';
    const url =
      `${this.env.apiUrl}/appointments?formId=${this.env.covid19Screening.formId}&application=${schApplicationId}` +
      `&empUserId=${userId}${activeOnlyParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getAppointments():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Appointments');
          }
          return res;
        })
      );
  }

  /**
   * Get Appointments by User ID
   *
   * @param userId User's ID
   * @param schApplicationId SCH Application Name
   * @param activeOnly If true, returns only active records
   * @returns Observable Response Object
   */
  getAppointmentsByUserId(userId, activeOnly = true): Observable<any> {
    const activeOnlyParam = activeOnly ? '&activeOnly=1' : '';
    const url =
      `${this.env.apiUrl}/appointments?formId=${this.env.covid19Screening.formId}&empUserId=${userId}${activeOnlyParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getAppointments():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching Appointments');
          }
          return res;
        })
      );
  }


  /**
   * Creates an Appointment record
   *
   * @param appointment Appointment Object
   * @returns Observable Response Object
   */
  createAppointment(appointment): Observable<any> {
    const url = `${this.env.apiUrl}/appointments`;
    return this.http.post(url, appointment)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Creating: Appointment');
          }
          return res.x_status;
        })
      );
  }

  /**
   * Updates an Appointment record
   *
   * @param appointmentSeq Appointment SEQ
   * @param appointment Appointment Object
   * @returns Observable Response Object
   */
  updateAppointment(appointment): Observable<any> {
    const url = `${this.env.apiUrl}/appointments/${appointment.aptSeq}`;
    return this.http.put(url, appointment)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Appointment');
          }
          return res.x_status;
        })
      );
  }

  /**
   * Start timeslot timer for 5 minutes (300000 ms)
   */
  startTimeslotTimer(schApplicationId) {
    this.clearTimeslotTimer();
    this.timeslotTimer = setTimeout(async () => {
      this.timeslotSchApplicationId = schApplicationId;
    }, 30000);
  }

  /**
   * Clears timeslot timer
   */
  clearTimeslotTimer() {
    if (this.timeslotTimer) {
      clearTimeout(this.timeslotTimer);
      this.timeslotTimer = null;
    }
  }

  /**
   * Get time slots and their availability
   *
   * @param locationId Location SEQ
   * @param startDate Start Date
   * @param endDate End Date
   * @param vacSeq Vaccine SEQ
   * @param vacDose Vaccine Dose Number (e.g. 1, 2)
   * @param camSeq Campaign SEQ
   * @param cvaSeq Campaign Vaccine Association SEQ
   * @param activeOnly Returns active records only 1 = get, 0 = false
   * @returns Observable Response Object
   */
  getTimeSlotAvailability(locationId, startDate, endDate, vacSeq, vacDose, camSeq, cvaSeq, limitSlot, activeOnly): Observable<any> {
    const startDateParam = limitSlot ? 'Today' : startDate;
    const endDateParam = (!limitSlot && endDate) ? `&endDate=${endDate}` : '';
    const limitSlotParam = limitSlot ? `&limitSlot=${limitSlot}` : '';
    const url = `${this.env.apiUrl}/locations/${locationId}/availability?activeOnly=${activeOnly}&` +
      `startDate=${startDateParam}${endDateParam}&vacDose=${vacDose}&vacSeq=${vacSeq}&cvaSeq=${cvaSeq}&camSeq=${camSeq}` +
      limitSlotParam;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getTimeSlotAvailability():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching timeslots');
          }
          return res;
        })
      );
  }

  /**
   * Determines if a campaign has any availability time slots
   *
   * @param camSeq Campaign Sequence
   * @param isSupportMode Determines if you're in support mode
   * @returns Boolean result: true = has availability, false = no available timeslots
   */
  async hasCampaignAvailability(camSeq: number, isSupportMode: boolean = false): Promise<boolean> {
    try {
      const availRes = await this.getCampaignAvailability(camSeq).toPromise();
      const availability: CampaignAvailability[] = availRes.availability;
      if ((availability && availability.filter(a => a.hasAvailability > 0).length > 0) || isSupportMode) {
        // has availability
        return Promise.resolve(true);
      } else {
        // has NO vaccine
        return Promise.resolve(false);
      }
    } catch (err) {
      console.error('Error occurred when checking campaign availability', err);
      return Promise.reject(err);
    }
  }

  /**
   * Determines if a campaign has any vaccine
   *
   * @campaign Campaign with vaccines
   * @isRecheck Flag used to identify this call as recheck for vaccines (after 1st check); accounts for taken seat
   * @zip Person's zip code. Use empty string to ignore zip checking
   * @age Person's age in years
   * @isSupportMode Enables support mode overrides
   * @returns Boolean result: true = has vaccines, false = no vaccines
   */
  async hasCampaignVaccines(campaign: Campaign, isRecheck = false, zip = '', age = 0, isSupportMode: boolean = false): Promise<boolean> {
    try {
      // console.log('checkVaccines: camRes, vaccines', this.campaign);
      const camRes = await this.getCampaignBySeq(campaign.camSeq, true, false, true).toPromise();
      const updatedCampaign = camRes.campaign;
      const vaccines = updatedCampaign.vaccines;
      const validVaccines: SchVaccine[] = vaccines.filter(v =>
        v.dose1Available > 0 &&
        this.hasZipCode(zip, v) &&
        v.status === 'ACTIVE' &&
        v.cvaActive === 1 &&
        v.locations.filter(l => l.vlaActive === 1 && l.active === 1).length > 0 &&
        (
          age === 0 ||
          (
            v.minAge <= age &&
            (!v.maxAge || v.maxAge >= age)
          )
        )
      );
      if ((validVaccines && validVaccines.length > 0) || isSupportMode) {
        // has vaccine
        let totalVaccine = 0;
        let openSeats = 0;
        validVaccines.forEach((v: SchVaccine) => { totalVaccine += v.dose1Available; });
        const recheckIncrement = isRecheck ? 1 : 0;
        openSeats = (totalVaccine - updatedCampaign.seatCount + recheckIncrement);
        return Promise.resolve((openSeats > 0));
      } else {
        // has NO vaccine
        return Promise.resolve(false);
      }
    } catch (err) {
      console.error('Error occurred when checking vaccine', err);
      return Promise.reject(err);
    }
  }

  /**
   * Gets Available Vaccines from Campaign or overflow campaigns
   *
   * @campaign Campaign with vaccines
   * @isRecheck Flag used to identify this call as recheck for vaccines (after 1st check); accounts for taken seat
   * @zip Person's zip code. Use empty string to ignore zip checking
   * @age Person's age in years
   * @ignoreOverflowCampaigns Ignore overflow campaigns; stop after first check
   * @returns Boolean result: true = has vaccines, false = no vaccines
   */
  async getCampaignVaccineAvailability(
    campaign: Campaign,
    isRecheck = false,
    zip = '',
    age = 18,
    ignoreOpenSeats,
    ignoreOverflowCampaigns: boolean,
    filterVacSeqs: number[]
  ): Promise<CampaignVaccineAvailabilityResult> {
    try {
      let workingCampaign = campaign;
      let lastCampaign = false;
      let tries = 0;
      let availResult: CampaignVaccineAvailabilityResult = null;
      let isOverflowCampaign = false;
      // Loop through campaigns and valid overflow campaigns
      while (!lastCampaign && tries < 5) {
        tries += 1;
        availResult = await this.calcCampaignVaccineAvailability(
          workingCampaign, isRecheck, zip, age, ignoreOpenSeats, false, filterVacSeqs
        );
        if (availResult.hasAvailability) {
          return Promise.resolve(availResult);
        } else {
          // Check overflow campaign
          if (workingCampaign.overflowCamSeq && workingCampaign.overflowCamSeq !== 0 && !ignoreOverflowCampaigns) {
            const camRes = await this.getCampaignBySeq(workingCampaign.overflowCamSeq).toPromise();
            workingCampaign = camRes.campaign;
            isOverflowCampaign = true;
          } else {
            // Last campaign found (overflowCamSeq === 0 or ignoreOverflowCampaigns set to true)
            lastCampaign = true;
          }
        }
      }
      availResult.isOverflowCampaign = isOverflowCampaign;
      return Promise.resolve(availResult);
    } catch (err) {
      console.error('Error occurred when checking campaign vaccine availability', err);
      return Promise.reject(err);
    }
  }

  /**
   * Calculates Available Vaccines with integrated Availability Checking
   *
   * @campaign Campaign with vaccines
   * @isRecheck Flag used to identify this call as recheck for vaccines (after 1st check); accounts for taken seat
   * @zip Person's zip code. Use empty string to ignore zip checking
   * @age Person's age in years
   * @returns Boolean result: true = has vaccines, false = no vaccines
   */
  async calcCampaignVaccineAvailability(
    campaign: Campaign,
    isRecheck = false,
    zip = '',
    age = 0,
    ignoreOpenSeats,
    isSupportMode: boolean,
    filterVacSeqs: number[]
  ): Promise<CampaignVaccineAvailabilityResult> {
    const availRes = await this.getCampaignAvailability(campaign.camSeq).toPromise();
    const availability: CampaignAvailability[] = availRes.availability;
    let showOnMonitor = false;
    // Build result object
    let vaccineAvailResult: CampaignVaccineAvailabilityResult = {
      campaign,
      availability,
      availableLocations: [],
      availableVaccines: [],
      totalVaccine: null,
      openSeats: null,
      hasAvailability: false
    };
    // Calculate availability
    if ((availability && availability.filter(a => a.hasAvailability > 0).length > 0)) {
      const camRes = await this.getCampaignBySeq(campaign.camSeq, true, false, true).toPromise();
      const updatedCampaign: Campaign = camRes.campaign;
      const vaccines = updatedCampaign.vaccines;
      const availableVaccines: SchVaccine[] = vaccines.filter(v => {
        const hasDosesAvailable = v.dose1Available > 0;
        const permittedZipCode = this.hasZipCode(zip, v);
        const hasActiveStatus = v.status === 'ACTIVE' && v.cvaActive === 1;
        const hasLocationAvailability = v.locations.filter(l => l.vlaActive === 1 && l.active === 1).length > 0;
        const hasCampaignAvailability = availability.filter(a => a.vacSeq === v.vacSeq && a.hasAvailability === 1).length > 0;
        const permittedAge = (age && age > 0) ? (v.minAge <= age && (!v.maxAge || v.maxAge >= age)) : true;
        let covidVaccineTypePermitted = false;
        // Assume true unless boosterVacSeq is populated then check against vacSeq
        let matchesVaccineType = true;
        if (filterVacSeqs && filterVacSeqs.length > 0) {
          matchesVaccineType = filterVacSeqs.filter(s => v.vacSeq === s).length > 0;
        }
        // Check vaccine series type
        if (this.scheduleCovidVaccineType === 'first') {
          covidVaccineTypePermitted = this.checkForCovidFirstSeriesVaccine(v.vacSeq);
        } else if (this.scheduleCovidVaccineType === 'booster') {
          covidVaccineTypePermitted = this.checkForCovidBoosterVaccine(v.vacSeq);
        } else if (this.scheduleCovidVaccineType === 'testing') {
          // covidVaccineTypePermitted = this.checkForCovidBoosterVaccine(v.vacSeq);
          covidVaccineTypePermitted = true;
        } else {
          covidVaccineTypePermitted = true;
        }
        if (hasDosesAvailable) {
          showOnMonitor = hasDosesAvailable;
        }
        const hasAvailability = (
          hasDosesAvailable &&
          permittedZipCode &&
          hasActiveStatus &&
          hasLocationAvailability &&
          hasCampaignAvailability &&
          permittedAge &&
          matchesVaccineType &&
          covidVaccineTypePermitted
        );
        const availValidation: SchVaccineAvailabilityValidation = {
          hasDosesAvailable,
          permittedZipCode,
          hasActiveStatus,
          hasLocationAvailability,
          hasCampaignAvailability,
          permittedAge,
          hasAvailability,
          matchesVaccineType,
          covidVaccineTypePermitted
        };
        // Show your work
        v.availabilityValidation = availValidation;
        return availValidation.hasAvailability;
      });

      // Sum total vaccine
      if ((availableVaccines && availableVaccines.length > 0)) {
        let totalVaccine = 0;
        let openSeats = 0;
        availableVaccines.forEach((v: SchVaccine) => { totalVaccine += v.dose1Available; });
        const recheckIncrement = isRecheck ? 1 : 0;
        // console.log('openSeats: campaign', updatedCampaign);
        // console.log('openSeats: calc', totalVaccine, updatedCampaign.seatCount, recheckIncrement);
        openSeats = (totalVaccine - updatedCampaign.seatCount + recheckIncrement);

        // Get all vaccine locations
        const locations: SchLocation[] = [];
        updatedCampaign.vaccines.forEach((v: SchVaccine) => {
          v.locations.forEach(l => {
            if (locations.filter(loc => loc.locSeq === l.locSeq).length === 0) {
              locations.push(l);
            }
          });
        });

        // Check Location Availability
        const availableLocations: SchLocation[] = [];
        availability.forEach((a: CampaignAvailability) => {
          if (a.hasAvailability > 0) {
            if (availableLocations.filter(l => l.locSeq === a.locSeq).length === 0) {
              const loc = locations.filter(l => l.locSeq === a.locSeq)[0];
              availableLocations.push(loc);
            }
          }
        });

        // Rebuild result object
        vaccineAvailResult = {
          campaign: updatedCampaign,
          availability,
          availableLocations,
          availableVaccines,
          totalVaccine,
          openSeats,
          hasAvailability: (openSeats > 0 || ignoreOpenSeats),
          showOnMonitor
        };

        return Promise.resolve(vaccineAvailResult);
      } else {
        // has NO vaccine availability
        return Promise.resolve(vaccineAvailResult);
      }
    } else {
      // has NO vaccine availability
      return Promise.resolve(vaccineAvailResult);
    }
  }

  /**
   * Filters active available campaign vaccines
   *
   * @campaign Campaign object
   * @returns Available vaccine array
   */
  async filterAvailableCampaignVaccines(campaign: Campaign, zip = '', age = 0, isSupportMode: boolean = false): Promise<SchVaccine[]> {
    try {
      const vaccines = campaign.vaccines;
      const validVaccines: SchVaccine[] = vaccines.filter(v =>
        v.dose1Available > 0 &&
        this.hasZipCode(zip, v) &&
        v.status === 'ACTIVE' &&
        v.cvaActive === 1 &&
        v.locations.filter((l: SchLocation) => l.vlaActive === 1 && l.active === 1).length > 0 &&
        (
          age === 0 ||
          (
            v.minAge <= age &&
            (!v.maxAge || v.maxAge >= age)
          )
        )
      );

      if ((vaccines && validVaccines.length > 0) || isSupportMode) {
        return Promise.resolve(validVaccines);
      } else {
        // has NO vaccine
        return Promise.resolve([]);
      }
    } catch (err) {
      console.error('Error occurred when checking vaccine', err);
      return Promise.reject(err);
    }
  }

  /**
   * Determines if a vaccine has a matching zip code
   *
   * @param zip Zip code to verify
   * @param vaccine Vaccine object
   * @returns Returns true if vaccine allowed for zip code
   */
  hasZipCode(zip: string, vaccine: SchVaccine): boolean {
    if (vaccine && vaccine.zipCodes && vaccine.zipCodes.length > 0 && zip && zip !== '') {
      const validZipCount = vaccine.zipCodes.filter((z: SchVaccineZip) => z.zip === zip && z.active === 1).length;
      if (validZipCount > 0) {
        // Matching zip codes found
        return true;
      } else if (validZipCount === 0) {
        // No matching zip codes
        return false;
      }
    } else {
      // Zip codes not defined; open to all
      return true;
    }
  }

  /**
   * Get all SCH Applications
   *
   * @returns Observable Response Object
   */
  getSchApplications(): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/applications`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getSchApplications():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all sch-application');
          }
          return res;
        })
      );
  }

  /**
   * Get SCH Application by ID
   *
   * @param schApplication Application ID
   * @returns Observable Response Object
   */
  getSchApplicationById(schApplication: string): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/applications/${schApplication}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching sch-application by Id');
          }
          return res;
        })
      );
  }

  /**
   * Get Vaccines by SEQ
   *
   * @param vacSeq Vaccine SEQ
   * @returns Observable Response Object
   */
  getVaccinesById(vacSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/vaccines/${vacSeq}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getVaccinesById():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching vaccines by Id');
          }
          return res;
        })
      );
  }

  /**
   * Get Vacines by Vaccine Type
   *
   * @param type Vaccine Type ID
   * @returns Observable Response Object
   */
  getVaccinesByType(type: string): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/vaccines?type=${type}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getVaccinesByType():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching vaccines by type');
          }
          return res;
        })
      );
  }

  /**
   * Get Vaccines by Shot Type
   *
   * @param shotType Shot Type ID
   * @param campaignSeq Campaign SEQ
   * @returns Observable Response Object
   */
  getVaccinesByShotType(shotType: string, campaignSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/vaccines?shottype=${shotType}&camSeq=${campaignSeq}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getVaccinesByShotType():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching vaccines by shottype');
          }
          return res;
        })
      );
  }

  /**
   * Get All Vaccines
   *
   * @returns Observable Response Object
   */
  getVaccinesAll(): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/vaccines`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getVaccinesByShotType():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all vaccines');
          }
          return res;
        })
      );
  }

  /**
   * Prepare visits for vaccine
   */
  async prepareVaccineVisits(
    schApplicationId: string,
    visits: SchAppointment[],
    vaccine: SchVaccine,
    usingAdminPriv = false,
    isBooster = false
  ): Promise<boolean> {
    try {
      // const vaccine: SchVaccine = this.appointment.vaccine;
      if (vaccine) {
        const doseCount = vaccine.doseCount;
        const vacSeq = vaccine.vacSeq;

        if (!isBooster) {
          if (visits.length < this.appointment.vaccine.doseCount) {
            // Create missing appointment placeholders
            for (let i = 0; i < doseCount; i++) {
              const doseVisitExists =
                (visits.filter((v: SchAppointment) =>
                  v.vacSeq === vacSeq &&
                  v.vacDose === (i + 1)
                ).length > 0);
              if (!doseVisitExists) {
                const visit: SchAppointment = {
                  application: schApplicationId,
                  aptSeq: null,
                  visitIndex: i,
                  vacSeq: this.appointment.vaccine.vacSeq,
                  vacDose: i + 1,
                  tsSeq: null,
                  visitLabel: this.getFriendlyDoseNumber(i + 1) + ' Dose',
                  adminStatus: 'PENDING',
                  scheduleDate: null,
                  scheduleDateFormatted: null,
                  locSeq: null,
                  location: null,
                  valid: false,
                  reschedule: usingAdminPriv,
                  isPassed: false,
                  camSeq: this.appointment.campaign.camSeq,
                  cvaSeq: this.appointment.vaccine.cvaSeq
                };
                visits.push(visit);
              }
            }
          }
        } else {
          // Look for any vaccine booster
          const doseVisitExists =
            (visits.filter((v: SchAppointment) =>
              this.checkForCovidBoosterVaccine(v.vacSeq) &&
              v.vacDose === 1
            ).length > 0);

          if (!doseVisitExists) {
            const visit: SchAppointment = {
              application: schApplicationId,
              aptSeq: null,
              visitIndex: 0,
              vacSeq: vaccine.vacSeq,
              vacDose: 1,
              tsSeq: null,
              visitLabel: null,
              adminStatus: 'PENDING',
              scheduleDate: null,
              scheduleDateFormatted: null,
              locSeq: null,
              location: null,
              valid: false,
              reschedule: usingAdminPriv,
              isPassed: false,
              camSeq: this.appointment.campaign.camSeq,
              cvaSeq: vaccine.cvaSeq
            };
            visits.push(visit);
          }

        }

        // Update list order
        visits.sort((a, b) => a.vacDose - b.vacDose);

        // Seed existing visits
        if (visits.filter((v: SchAppointment) => v.valid).length > 0) {
          let validVisit = null;
          visits.forEach(async (v, i) => {
            if (v.valid) {
              v.reschedule = true;
              v.visitLabel = await this.getFriendlyLabel(schApplicationId, vaccine, v.vacDose, null);
              v.cvaSeq = vaccine.cvaSeq;
              const location = this.appointment.vaccine.locations.filter((l: SchLocation) => l.locSeq === v.locSeq)[0];
              v.location = location;
              const schedDateMoment = moment(v.scheduleDate, 'MM/DD/YYYY hh:mm A');
              const formattedSchedDate = schedDateMoment.format('MMM D, YYYY') + ' at ' + schedDateMoment.format('h:mm a');
              v.scheduleDateFormatted = formattedSchedDate;
              validVisit = v;

              if (i > 0 && vaccine) {
                const prevVisit = visits[i - 1];
                v.startDate =
                  moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
                if (this.appointment.reschedule) {
                  // Display full window for reschedule
                  v.endDate =
                    moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalEnd, 'day').format('MM/DD/YYYY');
                } else {
                  // Display single day for initial scheduling
                  v.endDate =
                    moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
                }
              }
            } else {
              // Use previous valid visit to prepare next visit
              // console.log('---Preparing next visit', v, i, (i - 1));
            }

            // Prepare next visit if one exists
            this.prepareNextVisit(visits, v, i); // was: i-1
          });
        }

        // Update visit labels
        await this.setVisitLabels(visits, vaccine);

        if (!isBooster) {
          // Validate appointment intervals
          this.validateAppointmentIntervals(visits);
        }

      } else {
        // No vaccine found, clear visits
        visits = [];
      }

    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }


  async prepareAppointments(schApplicationId, visits): Promise<boolean> {
    try {
      if (visits.filter((v: SchAppointment) => v.valid).length > 0) {
        const friendlyNamesRes = await this.getAppValues(schApplicationId, 'VACCFRIENDLYNAME', true).toPromise();
        const friendlyNameList = friendlyNamesRes.appvalues;
        let validVisit = null;
        let i = 0;
        for (const v of visits) {
          if (v.valid) {
            v.reschedule = true;
            const campaignRes = await this.getCampaignBySeq(v.camSeq, true).toPromise();
            const campaign: Campaign = campaignRes.campaign;
            const vaccine = campaign.vaccines.filter(vac => vac.vacSeq === v.vacSeq)[0];
            v.visitLabel = await this.getFriendlyLabel(schApplicationId, vaccine, v.vacDose, friendlyNameList);
            v.cvaSeq = vaccine.cvaSeq;
            const location = vaccine.locations.filter((l: SchLocation) => l.locSeq === v.locSeq)[0];
            v.location = location;
            const schedDateMoment = moment(v.scheduleDate, 'MM/DD/YYYY hh:mm A');
            const formattedSchedDate = schedDateMoment.format('MMM D, YYYY') + ' at ' + schedDateMoment.format('h:mm a');
            v.scheduleDateFormatted = formattedSchedDate;
            validVisit = v;

            if (i > 0 && vaccine) {
              const prevVisit = visits[i - 1];
              v.startDate =
                moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
              if (this.appointment.reschedule) {
                // Display full window for reschedule
                v.endDate =
                  moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalEnd, 'day').format('MM/DD/YYYY');
              } else {
                // Display single day for initial scheduling
                v.endDate =
                  moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
              }
            }
          }
          i += 1;
        };
      }

      return Promise.resolve(true);

    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }

    // Update visit labels
    // await this.setVisitLabels(visits, vaccine);

  }

  async loadCovidVaccineTypes(): Promise<boolean> {
    try {
      const vaccRes = await this.getVaccinesAll().toPromise();
      const vaccines = (vaccRes && vaccRes.vaccines) ? vaccRes.vaccines : [];

      // Load first series COVID vaccine sequences
      const firstSeriesVaccines = vaccines.filter(v => v.type === 'FIRST' && v.shotType === 'COVID');
      this.covidVaccineFirstSeriesSeqs = [];
      firstSeriesVaccines.forEach((v: SchVaccine) => {
        this.covidVaccineFirstSeriesSeqs.push(Number(v.vacSeq));
      });

      // Load booster COVID vaccine sequences
      const boosterSeriesVaccines = vaccines.filter(v => v.type === 'BOOSTER' && v.shotType === 'COVID');
      this.covidVaccineBoosterSeqs = [];
      boosterSeriesVaccines.forEach((v: SchVaccine) => {
        this.covidVaccineBoosterSeqs.push(Number(v.vacSeq));
      });

      // Load booster COVID vaccine map sequences
      const boosterMapRes = await this.getAppValues('WWCOVIDVAC', 'COVIDBOOSTERMAP', true).toPromise();
      this.covidVaccineBoosterMaps = [];
      boosterMapRes.appvalues.forEach((v: AppValue) => {
        this.covidVaccineBoosterMaps.push(v.value);
      });

      return Promise.resolve(true);
    } catch (err) {
      console.error('Error: loadCovidVaccineTypes', err);
      return Promise.reject(err);
    }
  }

  /**
   * Check for first vaccine series visit
   */
  // TODO: Refactor
  checkForCovidFirstSeriesVaccine(vacSeq: number): boolean {
    let result = false;
    if (vacSeq && this.covidVaccineFirstSeriesSeqs.filter(fs => fs === vacSeq).length > 0) {
      result = true;
    }
    return result;
  }

  /**
   * Check for booster vaccine visit
   */
  checkForCovidBoosterVaccine(vacSeq: number): boolean {
    let result = false;
    if (vacSeq && this.covidVaccineBoosterSeqs.filter(fs => fs === vacSeq).length > 0) {
      result = true;
    }
    return result;
  }

  // checkForSisterAppointments(vacSeq: number): boolean {
  //   let result = false;
  //   if (vacSeq && this..filter(fs => fs === vacSeq).length > 0) {
  //     result = true;
  //   }
  //   return result;
  // }

  /**
   * Get COVID vaccine type
   *
   * @param vacSeq Vaccine Sequence
   */
  // TODO: Refactor
  getCovidVaccineType(vacSeq: number): VaccineType {
    if (this.checkForCovidFirstSeriesVaccine(vacSeq)) {
      return 'first';
    } else if (this.checkForCovidBoosterVaccine(vacSeq)) {
      return 'booster';
    } else {
      return null;
    }
  }

  /**
   * Get mapped COVID vaccine booster vacSeq
   *
   * @param vacSeq Vaccine Sequence
   */
  async getCovidBoosterVaccines(vacSeq: number): Promise<SchVaccine[]> {
    const boosterVacSeqs: number[] = [];
    let boosterVaccines: SchVaccine[] = [];
    this.covidVaccineBoosterMaps.forEach((m) => {
      const mapValues = m.split('|');
      if (mapValues && mapValues.length === 2 && Number(mapValues[0]) === vacSeq) {
        boosterVacSeqs.push(Number(mapValues[1]));
      }
    });
    if (boosterVacSeqs.length > 0) {
      const vaccRes = await this.getVaccinesAll().toPromise();
      const vaccines: SchVaccine[] = (vaccRes && vaccRes.vaccines && vaccRes.vaccines.length > 0) ? vaccRes.vaccines : [];
      boosterVacSeqs.forEach(s => {
        const vaccsToAdd = vaccines.filter(v => s === v.vacSeq && v.status === 'ACTIVE');
        boosterVaccines = boosterVaccines.concat(vaccsToAdd);
      });
    }
    return Promise.resolve(boosterVaccines);
  }


  /**
   * Prepare Next Visit
   *
   * @param visit Valid visit
   */
  prepareNextVisit(visits: SchAppointment[], visit: SchAppointment, i: number) {
    if (visit) {
      const nextVisit: SchAppointment =
        (visits && visits.length > 1) ? visits[i + 1] : null;
      if (nextVisit) {
        this.calcSchedulingStartEndDates(visit, nextVisit, this.appointment.reschedule);
      }
    }
  }

  /**
   * Calculates Start and End Dates for Scheduling
   *
   * @param prevVisit Prior visit to calc start/end date from
   * @param nextVisit Next visit to apply start/end date to
   */
  calcSchedulingStartEndDates(prevVisit, nextVisit, isRescheduling = false) {
    if (prevVisit.valid) {
      const vaccine = this.appointment.vaccine;
      nextVisit.locSeq = prevVisit.locSeq;
      nextVisit.location = prevVisit.location;
      nextVisit.camSeq = prevVisit.camSeq;
      nextVisit.reschedule = isRescheduling;
      nextVisit.startDate = moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
      if (isRescheduling) {
        // Display full window for reschedule
        nextVisit.endDate = moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalEnd, 'day').format('MM/DD/YYYY');
      } else {
        // Display single day for initial scheduling
        nextVisit.endDate = moment(prevVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
      }
    } else {
      console.log('calcSchedulingStartEndDates: prevVisit INVALID', prevVisit);
    }
  }

  /**
   * Get Next Dose Date Range
   *
   * @param previousVisit Prior Visit
   * @param vaccine Vaccine
   * @param useRescheduleWindow Use expanded reschedule window
   */
  getNextDoseDateRange(previousVisit: SchAppointment, vaccine: SchVaccine, useRescheduleWindow = false): NextDoseInfo {
    const nextDoseInfo: NextDoseInfo = {};
    if (previousVisit) {
      nextDoseInfo.locSeq = previousVisit.locSeq;
      nextDoseInfo.camSeq = previousVisit.camSeq;
      nextDoseInfo.location = previousVisit.location;
      nextDoseInfo.startDate = moment(previousVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
      if (useRescheduleWindow) {
        // Use expanded reschedule window
        nextDoseInfo.endDate = moment(previousVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalEnd, 'day').format('MM/DD/YYYY');
      } else {
        // Display single day for initial scheduling
        nextDoseInfo.endDate = moment(previousVisit.scheduleDate, 'M/D/YYYY').add(vaccine.doseIntervalStart, 'day').format('MM/DD/YYYY');
      }
    }
    return nextDoseInfo;
  }


  /**
   * Validate all appointment intervals and update validation property
   */
  validateAppointmentIntervals(visits: SchAppointment[]) {
    // Loop through appts and check threshold
    // console.log('validateAppointmentIntervals: Validating appointments');
    visits.forEach((v2, i) => {
      if (v2.vacDose > 1) {
        const v1 = this.appointment.visits[i - 1];
        const vaccine = this.appointment.vaccine;
        if (v1 && v2 && vaccine) {
          if (this.isAppointmentActiveStatus(v1.adminStatus) && v1.scheduleDate && v2.scheduleDate) {
            const visit1Date = moment(v1.scheduleDate, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY');
            const visit2Date = moment(v2.scheduleDate, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY');
            v2.validation = this.validateVisitDates(visit1Date, visit2Date, vaccine);
          } else {
            const validation: SchAppointmentValidation = {
              validDate1: false,
              validDate2: false,
              visit1DateMoment: null,
              visit2DateMoment: null,
              daysBetweenDoses: null,
              message: null,
              messageType: 'danger',
              valid: false
            };
            if (v1.adminStatus === 'CANCELED' || v1.adminStatus === 'NOSHOW') {
              validation.message = this.getFriendlyDoseNumber(v1.vacDose) +
                ' Dose appointment is marked cancelled or no-show. Please reschedule that appointment or cancel this one.';
            } else if (v1.adminStatus === 'PENDING' || !v1.scheduleDate) {
              validation.message = this.getFriendlyDoseNumber(v1.vacDose) + ' Dose appointment is not set.';
            }

            v2.validation = validation;
          }
        }
      }
    });
  }


  /**
   * Validate a pair of dates
   *
   * @param visit1Date Dose Visit 1 date
   * @param visit2Date Dose Visit 2 date
   * @param vaccine Vaccine
   * @returns Validation Result object
   */
  validateVisitDates(visit1Date, visit2Date, vaccine: SchVaccine): SchAppointmentValidation {
    // console.log('Comparing', visit1Date, visit2Date);
    const validation: SchAppointmentValidation = {
      validDate1: false,
      validDate2: false,
      visit1DateMoment: null,
      visit2DateMoment: null,
      daysBetweenDoses: null,
      message: null,
      messageType: null,
      valid: false
    };

    // Check first date
    if (visit1Date && visit1Date.length === 10) {
      validation.visit1DateMoment = moment(visit1Date, 'MM/DD/YYYY', true);
      validation.validDate1 = true;
    }

    // Check second date
    if (visit2Date && visit2Date.length === 10) {
      validation.visit2DateMoment = moment(visit2Date, 'MM/DD/YYYY', true);
      validation.validDate2 = true;
    }

    // Compare dates and check validity
    if (validation.validDate1 && validation.validDate2) {
      let dose2StartMoment = moment(validation.visit1DateMoment);
      dose2StartMoment = dose2StartMoment.add(vaccine.doseIntervalStart, 'day');
      let dose2EndMoment = moment(validation.visit1DateMoment);
      dose2EndMoment = dose2EndMoment.add(vaccine.doseIntervalEnd, 'day');
      const validInterval = (validation.visit2DateMoment.format('YYYY-MM-DD') >= dose2StartMoment.format('YYYY-MM-DD') &&
        validation.visit2DateMoment.format('YYYY-MM-DD') <= dose2EndMoment.format('YYYY-MM-DD'));
      validation.daysBetweenDoses = validation.visit2DateMoment.diff(validation.visit1DateMoment, 'day');
      validation.message = (validInterval) ?
        vaccine.name + ' follow-up appointment date is valid.'
        // '<br>' + vaccine.name + ' 2nd dose is ' +
        // validation.daysBetweenDoses + ' day(s) after 1st dose. ' +
        // '<br>Valid 2nd dose window: (' + vaccine.doseIntervalStart + ' - ' + vaccine.doseIntervalEnd + ' days)' :
        // '<strong>' + vaccine.name + ' 2nd dose appointment date is not valid.</strong><br>2nd dose is ' +
        //   validation.daysBetweenDoses + ' day(s) after 1st dose. ' +
        //   (vaccine.doseIntervalStart === vaccine.doseIntervalEnd) ?
        //   vaccine.name + ' requires 2nd dose to be ' + vaccine.doseIntervalStart + ' day(s) after the first dose ' +
        //   ' or specifically on ' + dose2EndMoment.format('MM/DD/YYYY')
        : (vaccine.doseCount > 1) ?
          vaccine.name + ' requires this follow-up appointment to be: ' + vaccine.doseIntervalStart + '-' +
          vaccine.doseIntervalEnd + ' day(s) after the prior appointment. <br>Choose a date between: ' +
          dose2StartMoment.format('MM/DD/YYYY') + ' and ' + dose2EndMoment.format('MM/DD/YYYY')
          : vaccine.name + ' is a single visit appointment. Please cancel one of the appointments.'
        ;
      validation.messageType = (validInterval) ? 'success' : 'danger';
      validation.valid = validInterval;
      // console.log('Validation', validation);
    }

    return validation;
  }

  /**
   * Invalidates all appointments
   */
  invalidateVisits() {
    this.appointment.visits.forEach((v: SchAppointment) => {
      v.valid = false;
    });
  }


  /**
   * Determine if all doses/visits are valid
   *
   * @returns Returns true if doses/visits are valid
   */
  hasAllValidVisits(visits: SchAppointment[]): boolean {
    let hasAllValidDoses = true;
    visits.forEach((v: SchAppointment) => {
      if (!v.valid) {
        hasAllValidDoses = false;
      }
    });
    return hasAllValidDoses;
  }

  /**
   * Determines if there are any valid doses/visits
   *
   * @returns Returns true if there are any valid doses/visits
   */
  hasAnyValidVisits(): boolean {
    let hasAnyValidVisits = false;
    this.appointment.visits.forEach((v: SchAppointment) => {
      if (v.valid) {
        hasAnyValidVisits = true;
      }
    });
    return hasAnyValidVisits;
  }

  /**
   * Lock time slot for a visit
   *
   * @param visit Visit to lock time slot for
   */
  async lockTimeslot(visit: SchAppointment): Promise<SchAppointment> {
    // console.log('lockTimeslot: visit:', visit);
    // Consider removing this line below
    this.appointment.adminStatus = 'PENDING';
    this.appointment.visits.forEach((v: SchAppointment) => {
      if (v.vacSeq === visit.vacSeq && v.vacDose === visit.vacDose && v.visitIndex === visit.visitIndex) {
        v.adminStatus = 'PENDING';
        v.cvaSeq = this.appointment.vaccine.cvaSeq;
      }
    });

    // Save visit
    if (visit && !visit.reschedule) {
      const apptRes = await this.createAppointment(this.appointment).toPromise();
      apptRes.visits.forEach((v: SchAppointment, i) => {
        if (i === 0) {
          this.appointment.aptSeq = v.aptSeq;
        }
        this.appointment.visits[i].aptSeq = v.aptSeq;
        visit.aptSeq = v.aptSeq;
      });
    }

    return Promise.resolve(visit);
  }

  /**
   * Clear visits after visit index
   *
   * @param visitIndex Visit Array Index (e.g. 0, 1)
   */
  clearSubsequentVisits(visitIndex) {
    const nextVisitIndex = (visitIndex + 1);
    // console.log('Next vaccine dose to clear: ' + nextVisitIndex, this.appointment.visits[nextVisitIndex]);
    if (this.appointment.visits[nextVisitIndex]) {
      for (let i = (nextVisitIndex); i < (this.appointment.visits.length); i++) {
        this.appointment.visits[i].valid = false;
        this.appointment.visits[i].location = null;
        this.appointment.visits[i].scheduleDate = null;
        this.appointment.visits[i].scheduleDateFormatted = null;
      }
    }
  }

  /**
   * Filter out any CANCELED or NOSHOW appointments from the appointment visits object
   */
  removeInactiveAppointments(): Promise<boolean> {
    this.appointment.visits = (this.appointment.visits) ?
      Object.assign([], this.appointment.visits.filter(v => v.adminStatus !== 'CANCELED' && v.adminStatus !== 'NOSHOW')) :
      [];
    return Promise.resolve(true);
  }

  /**
   * Determines if visit is active (not canceled, pending, or no-show)
   *
   * @param adminStatus Visit's admin status
   * @returns True if visit is scheduled
   */
  isVisitActive(adminStatus: string): boolean {
    return adminStatus !== 'PENDING' && adminStatus !== 'CANCELED' && adminStatus !== 'NOSHOW';
  }

  /**
   * Determines if visit is upcoming (scheduled or confirmed)
   *
   * @param adminStatus Visit's admin status
   * @returns True if visit is upcoming
   */
  isVisitUpcoming(adminStatus: string): boolean {
    return adminStatus === 'SCHEDULED' || adminStatus === 'CONFIRMED';
  }

  /**
   * Determines if visit was attended (complete, arrived, or elsewhere)
   *
   * @param adminStatus Visit's admin status
   * @returns True if visit was attended
   */
  isVisitAttended(adminStatus: string): boolean {
    return adminStatus === 'COMPLETE' || adminStatus === 'ARRIVED' || adminStatus === 'ELSEWHERE';
  }

  /**
   * Get friendly sequence suffix
   *
   * @param doseNumber Dose number
   * @returns Friendly dose number suffix
   */
  getFriendlyDoseNumber(doseNumber: number): string {
    const doseNumberString = doseNumber.toString();
    const lastNumber = Number(doseNumberString.substr(doseNumberString.length - 1, 1));

    switch (lastNumber) {
      case 1:
        return doseNumberString + 'st';

      case 2:
        return doseNumberString + 'nd';

      case 3:
        return doseNumberString + 'rd';

      default:
        return doseNumberString + 'th';
    }
  }

  /**
   * Set visit labels for the appointment visits
   *
   * @useDoseLabels Assigns dose label without the vaccine name
   */
  async setVisitLabels(visits: SchAppointment[], vaccine: SchVaccine): Promise<boolean> {
    if (visits && vaccine && visits.length > 0) {
      try {
        const friendlyNamesRes = await this.getAppValues(visits[0].application, 'VACCFRIENDLYNAME', true).toPromise();
        const friendlyNameList: AppValue[] = friendlyNamesRes.appvalues;
        visits.forEach(async (v: SchAppointment) => {
          // const friendlyName = friendlyNameList.filter(n => n.value === vaccine.vacSeq.toString())[0];
          const vaccineName = await this.getFriendlyLabel(v.application, vaccine, v.vacDose, friendlyNameList);
          v.visitLabel = vaccineName;
        });
        return Promise.resolve(true);
      } catch (err) {
        console.error('setVisitLabels error: ', err);
        return Promise.reject(err);
      }
    }
  }

  async getFriendlyLabel(
    schApplicationId: string,
    vaccine: SchVaccine,
    vacDose: number,
    friendlyNameList: AppValue[] = null
  ): Promise<string> {
    try {
      if (!friendlyNameList) {
        const friendlyNamesRes = await this.getAppValues(schApplicationId, 'VACCFRIENDLYNAME', true).toPromise();
        friendlyNameList = friendlyNamesRes.appvalues;
      }
      const friendlyName = friendlyNameList.filter(n => n.value === vaccine.vacSeq.toString())[0];
      const vaccineName = (friendlyName) ?
        friendlyName.description.replace('::dose::', this.getFriendlyDoseNumber(vacDose)) : vaccine.name;
      return Promise.resolve(vaccineName);
    } catch (err) {
      console.error('setFriendlyLabel error: ', err);
      return Promise.reject(err);
    }
  }

  checkForConsentStep(workflows) {
    if (this.consentText) {
      const updatedWorkflow = workflows.splice(2, 0, { title: 'Consent', content: '', state: 'past' });
      workflows = updatedWorkflow;
    }
  }

  getApplicationObjectType(appType: string, capitalize = false): string {
    switch (appType) {
      case 'V':
        return capitalize ? 'Vaccine' : 'vaccine';
      default:
        return capitalize ? 'Appointment' : 'appointment';
    }
  }

  /**
   * Gets Confirmation Email Template
   *
   * @param isGuest Determines if target receipient is a guest
   * @returns Email Template ID
   */
  getConfirmationEmailTemplate(vaccine, isGuest): string {
    if (vaccine !== undefined && vaccine) {
      if (isGuest) {
        return (vaccine.doseCount > 1) ? 'APPTRECEIPTGUEST' : 'APPTRECEIPTGUESTSING';
      } else {
        return (vaccine.doseCount > 1) ? 'APPTRECEIPT' : 'APPTRECEIPTSING';
      }
    } else {
      return null;
    }
  }

  /**
   * Gets Reschedule Confirmation Email Template
   *
   * @param isGuest Determines if target receipient is a guest
   * @returns Email Template ID
   */
  getReschedConfEmailTemplate(vaccine, isGuest): string {
    if (vaccine !== undefined && vaccine) {
      if (isGuest) {
        return (vaccine.doseCount > 1) ? 'RESCHEDRECEIPTGUEST' : 'RESCHEDRCPTGUESTSING';
      } else {
        return (vaccine.doseCount > 1) ? 'RESCHEDRECEIPT' : 'RESCHEDRECEIPTSING';
      }
    } else {
      return null;
    }
  }

  /**
   * Gets Cancellation Confirmation Email Template
   *
   * @param isGuest Determines if target receipient is a guest
   * @returns Email Template ID
   */
  getCancelConfEmailTemplate(vaccine, isGuest): string {
    if (isGuest) {
      return (vaccine.doseCount > 1) ? 'CANCELRECEIPTGUEST' : 'CANCELRCPTGUESTSING';
    } else {
      return (vaccine.doseCount > 1) ? 'CANCELRECEIPT' : 'CANCELRECEIPTSING';
    }
  }

  /**
   * Converts appointments to new vaccine
   *
   * @param oldVaccine Old Vaccine
   * @param newVaccine Newly selected vaccine
   * @param isBooster Defines if these are booster appointments
   */
  // async convertVaccineAppointments(
  //   schApplicationId: string,
  //   visits: SchAppointment[],
  //   oldVaccine: SchVaccine,
  //   newVaccine: SchVaccine,
  //   isBooster: boolean
  // ) {
  //   if (oldVaccine) {
  //     this.appointment.vaccine = newVaccine;
  //     if (isBooster) {
  //       oldVaccine = await this.getCovidBoosterVaccine(oldVaccine.vacSeq);
  //       newVaccine = await this.getCovidBoosterVaccine(newVaccine.vacSeq);
  //     }
  //     if (oldVaccine.doseCount < newVaccine.doseCount) {
  //       // Convert single to multi
  //       visits.forEach(v => {
  //         v.vacSeq = newVaccine.vacSeq;
  //       });
  //     } else if (oldVaccine.doseCount > newVaccine.doseCount) {
  //       // Convert multi to single
  //       visits.forEach(v => {
  //         // v.vacSeq = newVaccine.vacSeq;
  //         if (v.vacDose > newVaccine.doseCount) {
  //           v.hide = false;
  //           if (v.adminStatus === 'SCHEDULED') {
  //             v.adminStatus = 'CANCELED';
  //             v.adminNote += '<br>Converted multi-dose vaccine to single-dose: ' + oldVaccine.name + ' => ' + newVaccine.name;
  //           }
  //           if (!v.aptSeq) {
  //             v.hide = true;
  //           }
  //         }
  //       });
  //       const validVisits = visits.filter(v => !v.hide);
  //       visits = validVisits;
  //       // this.prepareVaccineVisits();
  //     } else {
  //       // Equal dose count conversion
  //       visits.forEach(v => {
  //         v.vacSeq = newVaccine.vacSeq;
  //       });

  //     }
  //   }
  //   this.prepareVaccineVisits(schApplicationId, visits, false, isBooster);
  // }

  /**
   * Checks if appointment status is active (is SCHEDULED, ARRIVED, CONFIRMED, COMPLETE)
   *
   * @param adminStatus Appointment status
   * @returns Returns true if appointment has an active status
   */
  isAppointmentActiveStatus(adminStatus): boolean {
    return (
      adminStatus === 'SCHEDULED' || adminStatus === 'ARRIVED' || adminStatus === 'CONFIRMED' ||
      adminStatus === 'COMPLETE' || adminStatus === 'ELSEWHERE'
    );
  }

  /**
   * Returns Select Options for role in minor's registration
   *
   * @returns Select Options for Minor Registration Role
   */
  getMinorRegistrationRoleOptions(isAdmin = false): SelectOption[] {
    const subjectText = isAdmin ? 'Person is' : 'I am';
    const subjectPronoun = isAdmin ? 'they\'re' : 'I\'m';
    return [
      { label: subjectText + ' the parent or guardian of the minor ' + subjectPronoun + ' registering', value: 'GUARDIAN' },
      { label: subjectText + ' an emancipated minor', value: 'MATUREMINOR' },
    ];
  }

  /**
   * Get consent records by App, Consent Type, and User ID
   *
   * @param schApplication SCH Application ID
   * @param consentType Consent Type
   * @param userId User ID
   * @returns Observable
   */
  getConsentsByAppConsentTypeAndUserId(schApplication: string, consentType: string, userId: string): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/consents?application=${schApplication}&consentType=${consentType}&userId=${userId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getConsentsByTypeAndUserId():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching consents by consentType and userId');
          }
          return res;
        })
      );
  }

  getConsentsByAppTypeAndUserId(schApplication: string, userId: string): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/consents?application=${schApplication}&userId=${userId}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getConsentsByTypeAndUserId():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching consents by consentType and userId');
          }
          return res;
        })
      );
  }

  saveConsent(consent: any): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/consents`;
    return this.http.post(url, consent)
      .pipe(
        tap((res: any) => {
          // console.log('saveConsent():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Saving consent');
          }
          return res;
        })
      );
  }


  getRelationshipValues(): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/applications/WWCOVIDVAC/values?type=ECRELATION`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getRelationshipValues():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all relationshipValues');
          }
          return res;
        })
      );
  }

  getDeclinationReasons(): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/applications/WWCOVIDVAC/values?type=DECLREASON`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getRelationshipValues():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getDeclinationReasons');
          }
          return res;
        })
      );
  }

  getCampaigns(includeVaccines = false, activeOnly = true): Observable<any> {
    const activeOnlyParam = (activeOnly) ? '&activeOnly=1' : '&activeOnly=0';
    const includeVacParam = includeVaccines ? '&includeVac=1' : '';
    const url = `${this.env.apiUrl}/schedules/campaigns?includeSeat=1${activeOnlyParam}${includeVacParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getCampaigns');
          }
          return res;
        })
      );
  }

  getCampaignById(campaignId: string, includeVaccines = false, activeOnly = true, includeSeat = false, addSeat = false): Observable<any> {
    const activeOnlyParam = (activeOnly) ? '&activeOnly=1' : '&activeOnly=0';
    const includeVacParam = includeVaccines ? '&includeVac=1' : '';
    const includeSeatParam = includeSeat ? '&includeSeat=1' : '';
    const addSeatParam = addSeat ? '&addSeat=1' : '';
    const url = `${this.env.apiUrl}/schedules/campaigns` +
      `?camId=${campaignId}${activeOnlyParam}${includeVacParam}${includeSeatParam}${addSeatParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getCampaignById');
          }
          return res;
        })
      );
  }

  getCampaignBySeq(camSeq: number, includeVaccines = false, activeOnly = true, includeSeat = false, addSeat = false): Observable<any> {
    const activeOnlyParam = (activeOnly) ? '&activeOnly=1' : '&activeOnly=0';
    const includeVacParam = includeVaccines ? '&includeVac=1' : '';
    const includeSeatParam = includeSeat ? '&includeSeat=1' : '';
    const addSeatParam = addSeat ? '&addSeat=1' : '';
    // console.log('getCampaignBySeq: addSeat', addSeat, addSeatParam);
    const url = `${this.env.apiUrl}/schedules/campaigns` +
      `?camSeq=${camSeq}${activeOnlyParam}${includeVacParam}${includeSeatParam}${addSeatParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getCampaignBySeq');
          }
          return res;
        })
      );
  }

  getCampaignAvailability(camSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/campaigns/${camSeq}/availability`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getCampaignAvailability');
          }
          return res;
        })
      );
  }

  registerCampaignSeat(camSeq: number, seatStatus: string = 'PENDING'): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/campaigns/${camSeq}/seats`;
    const body = { seatStatus };
    return this.http.post(url, body)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all registerCampaignSeat');
          }
          return res;
        })
      );
  }

  updateCampaignSeat(camSeq: number, seatSeq: number, seatStatus: string): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/campaigns/${camSeq}/seats/${seatSeq}`;
    const body = { seatSeq, seatStatus };
    return this.http.patch(url, body)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all updateCampaignSeat');
          }
          return res;
        })
      );
  }

  getAppValues(schApplicationId, valueType, activeOnly = false): Observable<any> {
    const activeOnlyParam = activeOnly ? '&activeOnly=1' : '';
    const url = `${this.env.apiUrl}/schedules/applications/${schApplicationId}/values?type=${valueType}${activeOnlyParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getRelationshipValues():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getAppValues');
          }
          return res;
        })
      );
  }

  getAppValueBySeq(schApplicationId, avSeq): Observable<any> {
    const url = `${this.env.apiUrl}/schedules/applications/${schApplicationId}/values/${avSeq}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getRelationshipValues():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching getAppValueBySeq');
          }
          return res;
        })
      );
  }

  updateAppValue(schApplicationId, appValue: AppValue): Observable<any> {
    const avSeq = appValue.avSeq;
    const url = `${this.env.apiUrl}/schedules/applications/${schApplicationId}/values/${avSeq}`;
    return this.http.put(url, appValue)
      .pipe(
        tap((res: any) => {
          // console.log('getRelationshipValues():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching updateAppValue');
          }
          return res;
        })
      );
  }


  getPriorityGroups(activeOnly = false): Observable<any> {
    const activeParam = activeOnly ? '&activeOnly=1' : '';
    const url = `${this.env.apiUrl}/groups?type=GROUP${activeParam}`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getRelationshipValues():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getPriorityGroups');
          }
          return res;
        })
      );
  }

  getPriorityGroup(groupId): Observable<any> {
    const url = `${this.env.apiUrl}/groups/${groupId}?type=GROUP`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getRelationshipValues():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching all getPriorityGroups');
          }
          return res;
        })
      );
  }

  getWaitList(): Observable<any> {
    const url = `${this.env.apiUrl}/waitlists`;
    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          // console.log('getSchApplications():', res);
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Fetching On-demand waitlist');
          }
          return res;
        })
      );
  }

  updateWaitListStatus(guest: Guest): Observable<any> {
    const url = `${this.env.apiUrl}/waitlists/users/${guest.nbuSeq}`;
    return this.http.put(url, guest)
      .pipe(
        tap((res: any) => {
          // Check for error messages
          if (res.x_status === 'E') {
            this.notifications.handleError(res, 'Updating: Users/update');
          }
          return res.x_status;
        })
      );
  }

  /**
   * Gets Vaccine Type Name derived from OHM Manufacturer
   *
   * @param ohmManufacturer Manufacturer
   * @returns Vaccine type name
   */
  getCovidVaccineTypeFromOhmManufacturer(ohmManufacturer: string): string {
    if (ohmManufacturer.toLowerCase().indexOf('moderna') > -1) {
      return 'Moderna';
    } else if (ohmManufacturer.toLowerCase().indexOf('pfizer') > -1) {
      return 'Pfizer';
    } else if (ohmManufacturer.toLowerCase().indexOf('janssen') > -1) {
      return 'Janssen';
    } else {
      return 'Unknown';
    }
  }

  /**
   * Gets First Series Vaccine Sequence derived from OHM Manufacturer
   *
   * @param ohmManufacturer Manufacturer
   * @returns Vaccine type name
   */
  async getCovidVaccineFromOhmManufacturer(ohmManufacturer: string): Promise<SchVaccine> {
    try {
      const vaccineType = this.getCovidVaccineTypeFromOhmManufacturer(ohmManufacturer);
      const appValuesRes = await this.getAppValues(this.env.covid19Vaccine.applicationId, 'OHMFIRSTVACCSEQMAP', true).toPromise();
      const ohmFirstVacSeqMapList: AppValue[] = appValuesRes.appvalues;
      const ohmMirstVacSeqMap = (vaccineType) ? ohmFirstVacSeqMapList.filter(m => m.value === vaccineType.toLowerCase())[0] : null;
      const firstVacSeq = (ohmMirstVacSeqMap && ohmMirstVacSeqMap.description) ? Number(ohmMirstVacSeqMap.description) : null;
      const vaccineRes = await this.getVaccinesById(firstVacSeq).toPromise();
      const firstSeriesVaccine = vaccineRes && vaccineRes.vaccine ? vaccineRes.vaccine : null;
      return Promise.resolve(firstSeriesVaccine);
    } catch (err) {
      console.error('getCovidVaccineSeqFromOhmManufacturer', err);
      return Promise.reject(err);
    }
  }

  /**
   * Gets Booster Vaccine Sequence derived from OHM Manufacturer
   *
   * @param ohmManufacturer Manufacturer
   * @returns Vaccine type name
   */
  async getCovidBoosterSeqsFromOhmManufacturer(ohmManufacturer: string): Promise<number[]> {
    try {
      const firstSeriesVaccine = await this.getCovidVaccineFromOhmManufacturer(ohmManufacturer);
      const boosterVaccines = (firstSeriesVaccine) ? await this.getCovidBoosterVaccines(firstSeriesVaccine.vacSeq) : [];
      const boosterVaccSeqs: number[] = [];
      boosterVaccines.forEach(v => { boosterVaccSeqs.push(v.vacSeq); });
      return Promise.resolve(boosterVaccSeqs);
    } catch (err) {
      console.error('getCovidBoosterSeqFromOhmManufacturer', err);
      return Promise.reject(err);
    }
  }

  /**
   * Gets Booster Vaccine Day Interval
   *
   * @param vacSeq Vaccine Sequence
   * @returns Eligible days period after first series
   */
  async getCovidBoosterDayInterval(vacSeq: number): Promise<number> {
    try {
      const appValuesRes = await this.getAppValues(this.env.covid19Vaccine.applicationId, 'VACCBOOSTERDAYINT', true).toPromise();
      const covidBoosterDayIntervalsList: AppValue[] = appValuesRes.appvalues;
      const dayIntervalAppValue = covidBoosterDayIntervalsList.filter(m => m.value === vacSeq.toString())[0];
      const covidBoosterDayInterval = (dayIntervalAppValue && dayIntervalAppValue.description &&
        dayIntervalAppValue.description.toUpperCase() !== 'NULL') ?
        Number(dayIntervalAppValue.description) : null;
      return Promise.resolve(covidBoosterDayInterval);
    } catch (err) {
      console.error('getCovidBoosterDayInterval', err);
      return Promise.reject(err);
    }
  }

  /**
   * Gets OHM Dose Number
   *
   * @param dose Vaccine Sequence
   * @returns dose number integer
   */
  getOhmVaccineDoseNumber(ohmDose: string): number {
    switch (ohmDose.toLowerCase()) {
      case 'dose 1':
        return 1;

      case 'dose 2':
        return 2;

      case 'dose 3':
        return 3;
    }
  }

}

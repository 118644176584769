import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BhSearchBarComponent } from './bh-search-bar.component';

@NgModule({
  declarations: [
    BhSearchBarComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    BhSearchBarComponent
  ]
})
export class BhSearchBarComponentModule { }
